import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { signInUser } from "../Utility/Auth";
import {
  useSaleByDateQuery,
  useDeleteTempSaleMutation,
} from "../../services/saleApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import LoadingModal from "../Common/Modal/LoadingModal";
import ExportNav from "../Common/Nav/ExportNav";
import { salesRoutes } from "../../data";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";
const Sales = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [warehouse, setWarehouse] = useState("allWh");
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleByDateQuery({
      startDate,
      endDate,
      warehouse,
    });

  const auth = signInUser();

  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);

  const [deleteSale] = useDeleteTempSaleMutation();

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  useEffect(() => {
    setSales(data);
    let total = 0;
    let totalReceive = 0;
    let changeAmount = 0;
    if (data?.length) {
      data.map((sale) => {
        total = total + sale.grossSale;
        totalReceive = totalReceive + sale.totalReceived;
        changeAmount = changeAmount + sale.changeAmount;
      });
    }
    setTotalSales(total);
    setTotalReceived(totalReceive);
    setChangeAmount(changeAmount);

    // isSuccess === true ? setLoader(false) : setLoader(true);
  }, [isSuccess, data, isLoading]);

  // console.log("Sales Data",sales);
  // console.log("dates", startDate, endDate);

  const handelDeleteSale = async (id) => {
    const confirm = window.confirm("Are you Sure? Delete this Sale?");
    const data = {
      _id: id,
      updateUser: auth.id,
      status: "delete",
    };
    if (confirm) {
      console.log(deleteSale(data));
    }
  };

  const handelSearchInvoice = (e) => {
    const searchString = e.target.value;
    // console.log(searchString);
    const regex = /start(.*?)end/;

    // let sales = [];
    // sales = sales.filter(sale => sale.invoiceId )
  };

  // console.log("sales", sales);

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
    // console.log("handle data:", e);

    // console.log('')
    // refetch();
  };

 


  return (
    <div>
      <LoadingModal
        title={"Please Wait"}
        onShow={loader}
        handleClose={handleLoaderClose}
      ></LoadingModal>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-SALE</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="All Sales"></Header>
            <div className="row  mt-3">
              <div className="col-md-5">
                {/* Sort date range */}
                <div
                  className="date-picker d-flex mb-2 align-items-center"
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    style={{ width: "15%" }} // Adjust the width as per your design needs
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control me-2"
                    style={{ width: "15%" }} // Ensure consistent width
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <input
                    type="text"
                    className="form-control me-2"
                    onChange={(e) => handelSearchInvoice(e)}
                    placeholder="Search invoice ID"
                    style={{ width: "20%" }} // Adjust the width as needed
                  />

                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} //Consistent width for custom components
                    />
                  )}
                </div>
              </div>
              <div className="col-md-7">
                <ExportNav Routes={salesRoutes} title="Sales" data={sales} />
              </div>
            </div>

            {/* <Link to="/category-sales" className="btn btn-dark float-end my-2  mx-2">Category Sales </Link> */}

            <Table hover bordered striped className="mt-3">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">Invoice ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Biller</th>
                  <th scope="col">Warehouse</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Phone</th>
                  <th scope="col" className="text-center">
                    Items
                  </th>
                  <th scope="col" className="text-center">
                    Gross Total
                  </th>
                  <th scope="col" className="text-center">
                    Paid
                  </th>
                  <th scope="col" className="text-center">
                    Change
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(sales)} */}
                {sales?.length > 0 ? (
                  sales.map((sale) => (
                    <tr key={sale._id}>
                      {/* {console.log(sale)} */}
                      <th scope="row">{sale.invoiceId}</th>
                      <td>
                        {sale.createdAt &&
                          format(
                            new Date(sale.createdAt),
                            "MM-dd-yyyy hh:mm a"
                          )}
                      </td>
                      <td>{sale?.billerName}</td>
                      <td>{sale?.warehouseName}</td>
                      <td>{sale?.customerName}</td>
                      <td>{sale?.customerPhone}</td>
                      <td className="text-center">{sale?.totalItem}</td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(sale?.grossSale)}
                      </td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(sale?.totalReceived)}
                      </td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(sale?.changeAmount)}
                      </td>
                      <td>
                        {/* <span
                          className={sales?.billType === "due" && `text-danger`}
                        > */}
                        {sale.due ? (
                          <span className="text-danger">Bill Due</span>
                        ) : (
                          <span
                            className={
                              sale?.billType === "due" && `text-danger`
                            }
                          >
                            {sale?.status}
                          </span>
                        )}
                        {/* </span> */}
                      </td>
                      {/* 
                                                <td>{sale.price}</td> */}
                      <td>
                        <Link to={`/print/${sale._id}`} target="_blank">
                          <Icons.EyeOutline
                            className="icon-eye"
                            size={22}
                          ></Icons.EyeOutline>
                        </Link>
                        {/* <Link to={`/sale/update/${sale._id}`}>
                          <Icons.PencilAltOutline
                            className="icon-edit"
                            size={22}
                          ></Icons.PencilAltOutline>
                        </Link> */}
                        {auth?.type === "admin" && (
                          <Icons.TrashOutline
                            className="icon-trash"
                            onClick={() => handelDeleteSale(sale._id)}
                            size={22}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  // <tr>
                  //   <td colSpan={4}>Loading...</td>
                  // </tr>
                  <tr colSpan={9}>No Sales Found</tr>
                )}
                {
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(totalSales).toFixed(2)
                        )}
                      </b>
                    </td>
                    <td className="text-center">
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(totalReceived).toFixed(2)
                        )}
                      </b>
                    </td>
                    <td className="text-center">
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(changeAmount).toFixed(2)
                        )}
                      </b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </Table>

            {}
          </div>
        </div>
      </div>
      {/* <ExportSales
        show={show}
        handleClose={handleClose}
        sales={sales}
      ></ExportSales> */}
      {/* <ExportArticleSales
        show={showA}
        handleClose={handleAClose}
        sales={sales}
      ></ExportArticleSales> */}
    </div>
  );
};

export default Sales;
