import React from "react";
import Logo from "../../../logo.png";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";

const AllCategoriesSalePrintHeader = ({ endDate, startDate }) => {
  const now = format(new Date(), "MM-dd-yyyy hh:mm:ss");
  console.log(now);
  return (
    <>
      <div className="row pt-3 pb-3 mb-5 border-bottom">
        <div className="col-7">
          <img src={Logo} height="40" alt="not Image" />
        </div>

        <div className="col-5">
          <p className="p-0 m-0 text-end">
            <b>Hotline: </b> 01332553955
          </p>
          <p className="p-0 m-0 text-end">
            <small>H#6, R#27, Sector 7, Uttara, Dhaka - 1230</small>{" "}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 print-header-text">
          <h3>All Categories Sale</h3>
        </div>
        <div className="col-6 print-header-text">
          <p>From: {startDate}</p>
          <p>To :{endDate}</p>
        </div>
        <div className="col-6 text-end">
          <p>Print Time : {now && now}</p>
        </div>
      </div>
    </>
  );
};

export default AllCategoriesSalePrintHeader;
