import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Table } from "react-bootstrap";
import SelectSupplier from "../Common/CustomSelect/SelectSupplier";
import { useSaleExportByDateAndSupplierQuery } from "../../services/saleApi";
import SupplierWiseSaleReportModal from "../Common/Modal/SupplierWiseSaleReportModal";

const SupplierProductSale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [sales, setSales] = useState([]);
  const [exportCSV, setExportCSV] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState({});
  const [supplierName, setSupplierName] = useState([]);

  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleExportByDateAndSupplierQuery({
      startDate,
      endDate,
      supplier,
    });

  console.log(data);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    setSales(data);
    console.log(data);
  }, [isSuccess, isFetching]);

  useEffect(() => {
    let saleData = [];
    sales?.map((sale) => {
      saleData = [
        ...saleData,
        {
          article_code: sale.article_code,
          name: sale.name,
          tp: sale.tp,
          mrp: sale.mrp,
          totalQuantity: sale.totalQuantity,
        },
      ];
    });
    setExportCSV(saleData);
    console.log("dates", saleData);
  }, [sales]);

  const headers = [
    { label: "article_code", key: "article_code" },
    { label: "name", key: "name" },
    { label: "tp", key: "tp" },
    { label: "mrp", key: "mrp" },
    { label: "totalQuantity", key: "totalQuantity" },
  ];
  const handleVendorChange = (e) => {
    console.log(e);
    setSupplier(e.option);
    setSupplierName(e.code);
    setSupplierInfo(e);
  };
  const handleSupplierSaleReport = () => {
    setOnShow(true);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-SALE-EXPORT-SUPPLIER-WISE</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Export Sales Supplier Wise"></Header>
            <div className="row">
              <div className="col-md-12">
                {/* Sort date range */}
                <div className="row">
                  <div className="col-md-3">
                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                      <DatePicker
                        selected={new Date(startDate)}
                        className="form-control me-2"
                        onChange={(date) =>
                          setStartDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                      <DatePicker
                        selected={new Date(endDate)}
                        className="form-control"
                        onChange={(date) =>
                          setEndDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Button
                      className="btn btn-dark float-end my-2 mr-2"
                      onClick={() => handleSupplierSaleReport()}
                    >
                      Supplier Wise Sales Report
                    </Button>
                  </div>

                  <div className="col-md-3">
                    <CSVLink
                      className="btn btn-dark float-end my-2 mr-2"
                      data={exportCSV}
                      asyncOnClick={true}
                      headers={headers}
                      filename={`SupplierWiseSale ${startDate}to${endDate}-code:${supplierName} `}
                    >
                      {exportCSV?.length === 0
                        ? "Loading csv..."
                        : "Export Supplier Wise Sales "}
                    </CSVLink>
                  </div>
                </div>
                <div className="col-md-6 mt-2 mb-2">
                  {/* <input className="form-control me-2"></input> */}
                  <SelectSupplier
                    supplier_code={setSupplier}
                    handleOnchange={(e) => handleVendorChange(e)}
                  ></SelectSupplier>
                </div>
              </div>
              <div className="col-md-12">
                <Table hover className="mt-4">
                  <thead>
                    <tr>
                      {/* <th scope="col">#</th> */}
                      <th scope="col">article_code</th>
                      <th scope="col">name</th>
                      <th scope="col">tp</th>
                      <th scope="col">mrp</th>
                      <th scope="col">totalQuantity</th>

                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(sales)} */}
                    {sales ? (
                      sales?.length > 0 ? (
                        sales.map((sale) => (
                          <tr key={sale._id}>
                            {/* <th >{i++}</th> */}

                            <td>{sale.article_code}</td>
                            <td>{sale.name}</td>
                            <td>{sale.tp}</td>
                            <td>{sale.mrp}</td>
                            <td>{sale.totalQuantity.toFixed(2)}</td>
                            {/* 
                                                <td>{sale.price}</td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No product found</td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>Please Select a Supplier...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupplierWiseSaleReportModal
        onShow={onShow}
        handleClose={handleClose}
        data={data}
        supplierInfo={supplierInfo}
        startDate={startDate}
        endDate={endDate}
      ></SupplierWiseSaleReportModal>
    </div>
  );
};

export default SupplierProductSale;
