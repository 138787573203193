import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import DatePicker from "react-datepicker";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Table } from "react-bootstrap";
import * as Icons from "heroicons-react";

import {
  useSaleCategoryByDateQuery,
  useSaleFootfallQuery,
  useSaleTotalQuery,
} from "../../services/saleApi";
import AllCategoriesModal from "../Common/Modal/AllCategoriesModal";
import LoadingModal from "../Common/Modal/LoadingModal";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

const AllCategorySale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [articleSale, setArticleSale] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const [totalSales, setTotalSales] = useState(0);
  const [warehouse, setWarehouse] = useState('allWh');
  const { data, isLoading, isFetching, isSuccess, refetch } =
    useSaleCategoryByDateQuery({
      startDate,
      endDate,
      warehouse
    });

    console.log(startDate, endDate,warehouse);
  const {
    data: footfall,
    error: ferror,
    isLoading: fisloading,
    isFetching: fIsfetching,
    isSuccess: fIsSuccess,
    refetch: fRefetch,
  } = useSaleFootfallQuery({
    startDate,
    endDate,
  });
  const {
    data: total,
    error: terror,
    isLoading: tIsloading,
    isFetching: tIsfetching,
    isSuccess: tIsSuccess,
    refetch: tRefetch,
  } = useSaleTotalQuery({
    startDate,
    endDate,
  });

  const auth = signInUser();


  useEffect(() => {
    refetch();
    fRefetch();
    tRefetch();
  }, [startDate, endDate,warehouse]);

 

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [isLoading, isFetching]);
  useEffect(() => {
    let articleSales = [];

    let total = 0;

    if (data?.length > 0) {
      data?.map(
        (sale) =>
          (articleSales = [
            ...articleSales,
            {
              code: sale?._id?.code,
              name: sale?._id?.name,
              totalQuantity: sale?.totalQuantity,
              totalValue: sale?.totalValue,
              totalMrp: sale?.totalMrp,
              totalTP: sale?.totalTP,
            },
          ])
      );
    }
    console.log("product", articleSales);
    setArticleSale(articleSales);
  }, [isSuccess, isFetching]);

  const articleHeaders = [
    { label: "Code", key: "code" },
    { label: "Name", key: "name" },
    { label: "totalQuantity", key: "totalQuantity" },
    { label: "totalMrp", key: "totalMrp" },
    // { label: "totalValue", key: "totalValue" },
  ];

  // let exportData = [];
  useEffect(() => {
    if (articleSale.length > 0) {
      let total = 0;
      let expD = articleSale;
      setExportData(expD);
      articleSale.map((sale) => {
        total = total + sale.totalMrp;
      });
      setTotalSales(total);
    }
  }, [articleSale]);
  const handleAllCategoryPrint = () => {
    setShow(true);
  };
  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
  };
 
  return (
    <div>
      <LoadingModal
        title={"Please Wait"}
        onShow={loader}
        handleClose={handleLoaderClose}
      ></LoadingModal>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-All-Categories</title>
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Categories Sales"></Header>
            <div className="row">
              <div className="col-md-12">
                {/* Sort date range */}
                <div className="row">
                  <div className="col-md-6  d-flex mt-3 mb-2 align-items-center justify-content-between">
                    <div className="date-picker">
                      <b>Start Date:</b>
                      <DatePicker
                        selected={new Date(startDate)}
                        className="form-control"
                        onChange={(date) =>
                          setStartDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                    <div className="date-picker">
                      <b>End Date:</b>
                      <DatePicker
                        selected={new Date(endDate)}
                        className="form-control"
                        onChange={(date) =>
                          setEndDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                    <div>
                  {auth?.type === "admin" && (
                    <>
                    <b>Warehouse:</b>
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                    </>
                  )}
                  </div>
                  </div>
                  <div className="col-md-6 d-flex mt-3 mb-3 justify-content-end">
                    <Button
                      className="btn btn-dark me-2"
                      onClick={() => handleAllCategoryPrint()}
                    >
                      <Icons.PrinterOutline size="18" /> Print Report
                    </Button>

                    <CSVLink
                      filename={`All Category Sales Report- ${startDate} to ${endDate}.csv`}
                      className="btn btn-dark mr-2"
                      data={exportData}
                      asyncOnClick={true}
                      headers={articleHeaders}
                    >
                      {exportData?.length === 0
                        ? "Loading csv..."
                        : "Export All Category Sales Report"}
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>

            {/* <Link to="/category-sales" className="btn btn-dark float-end my-2  mx-2">Category Sales </Link> */}
            <Table hover bordered striped className="mt-4">
              <thead>
                <tr>
                  <th scope="col">Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Total Qty</th>
                  <th scope="col">Total </th>
                </tr>
              </thead>
              <tbody>
                {exportData ? (
                  exportData.map((cat) => (
                    <tr key={cat.code}>
                      {/* <th >{i++}</th> */}
                      <th scope="row">{cat.code}</th>
                      <td>{cat.name}</td>
                      <td>
                        {new Intl.NumberFormat().format(
                          parseFloat(cat?.totalQuantity)
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat().format(
                          parseFloat(cat?.totalMrp)
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>Loading...</td>
                  </tr>
                )}
                {
                  <tr>
                    <td></td>
                    <td>
                      <b>Total</b>
                    </td>
                    <td></td>
                    <td>
                      <b>
                        {new Intl.NumberFormat().format(parseFloat(totalSales))}
                      </b>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>

            {}
          </div>
        </div>
      </div>
      <AllCategoriesModal
        show={show}
        handleClose={handleClose}
        cat={data}
        startDate={startDate}
        endDate={endDate}
        footfall={footfall}
        total={total}
      ></AllCategoriesModal>
    </div>
  );
};

export default AllCategorySale;
