import React, { Fragment, useEffect, useState } from "react";
import { useUnitsQuery } from "../../../services/unitApi";

import Select from "react-select";

const SelectUnit = ({ handleOnchange, unit }) => {
  const { data, error, isLoading, isFetching, isSuccess } = useUnitsQuery();
  let [unitData, setUnitData] = useState([]);

  useEffect(() => {
    let unitList = [{ option: "no-Unit", label: "Select Unit" }];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((unit) => {
          unitList = [
            ...unitList,
            {
              option: unit.name,
              label: `${unit.name} - ${unit.symbol}`,
            },
          ];
        });
        setUnitData(unitList);
      }
    }
  }, [data]);

  // console.log(unitData);
  // console.log(unit);
  // console.log(unitData.filter((sel) => sel?.option?.toString() === supplier_code?.toString()))
  // console.log(unitData.indexOf(mc));
  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={unitData[0]}
        value={
          unit && unitData[unitData.map((obj) => obj.option).indexOf(unit)]
        }
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        onChange={(e) => handleOnchange(e)}
        // name={name}
        options={unitData}
      />

      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      ></div>
    </Fragment>
  );
};

export default SelectUnit;
