import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
import { useForm } from "react-hook-form";
import * as Icons from "heroicons-react";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import usePurchase from "../Hooks/usePurchase";
import { signInUser } from "../Utility/Auth";
import axios from "axios";
import SelectSupplier from "../Common/CustomSelect/SelectSupplier";
import WareHouseDWPurchase from "../Common/CustomSelect/WareHouseDWPurchase";

import PurchaseCart from "./parts/PurchaseCart";
import SupplierProduct from "./parts/SupplierProduct";
import { useNavigate } from "react-router-dom";
import { useAddPurchaseMutation } from "../../services/purchasApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProducts,
  selectSupplier,
  selectUser,
  selectWareHouse,
  selectDiscount,
  selectCalculation,
  selectShipping,
  selectNote,
  selectStatus,
  resetPurchase,
} from "../../features/purchaseSlice";
import CsvPurchaseProduct from "../Common/CsvImporter/CsvPurchaseProduct";
import LoadingModal from "../Common/Modal/LoadingModal";
import { total } from "../Utility/PurchaseCalculations";
import { useSupplierPurchaseQuery } from "../../services/supplierApi";
import { useWarehouseQuery } from "../../services/warehouseApi";

const PurchaseCreate = () => {
  const purchaseData = useSelector((state) => state.purchaseReducer);
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [addPurchase] = useAddPurchaseMutation();
  const [supplierId, setSupplierId] = useState("");

  const [csvData, setCsvData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [wh, setWh] = useState("");
  const { purchase, setPurchase } = usePurchase();
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [whName, setWhName] = useState("TCM");

  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);
  const auth = signInUser();

  // const { data, isSuccess, refetch, isFetching } = useSupplierQuery(supplierId);
  const { data, isSuccess, refetch } = useSupplierPurchaseQuery(supplierId);

  const { data: warehouseData, refetch: whRefetch } = useWarehouseQuery(
    auth?.warehouse
  );
  useEffect(() => {
    if (auth?.warehouse) {
      refetch();
    }
  }, [auth?.warehouse, refetch]);

  useEffect(() => {
    if (warehouseData) {
      setWhName(warehouseData?.name);
      refetch();
    }
  }, [warehouseData, refetch]);
  // console.log("warehouse", warehouseData);

  // console.log('purchese', purchase)

  useEffect(() => {
    // console.log("Supplier", data);
    setOnShow(false);
    if (data) {
      setSupplierProducts(data?.products);
      dispatch(selectSupplier(data?._id));
      dispatch(selectProducts([]));
      setPurchase(data);
    }
  }, [isSuccess, data]);

  // console.log("supplierId", supplierId);

  useEffect(() => {
    // setOnShow(true);
    refetch();
  }, [supplierId]);

  useEffect(() => {
    // ORDER SELECTED PRODUCT
    // console.log(purchaseData?.products);
    let orderdList = purchaseData?.products
      .slice()
      .sort((a, b) => a.order - b.order);
    // const { totalTax, total1 } = total(purchaseData?.products);

    // // SELECT CAL TOTAL
    // SELECT USER
    const { totalTax, total1 } = total(purchaseData?.products);

    // SELECT CAL TOTAL
    dispatch(
      selectCalculation({
        total: total1,
        tax: totalTax,
      })
    );
    // setCalcTotal(totalcal);
    setProductList(orderdList);
    // dispatch(
    //   selectCalculation(productList)
    // );
  }, [purchaseData?.products]);

  useEffect(() => {
    if (supplierProducts?.length > 0) {
      setFilteredProduct(supplierProducts);
    } else {
      setFilteredProduct([]);
    }
  }, [supplierProducts, purchaseData]);

  const handleImportButton = async () => {
    let importProducts = [];
    console.log("csv", csvData);
    setOnShow(true);
    if (csvData?.length > 0) {
      csvData.map(async (pro) => {
        // console.log(pro);
        if (pro?.article_code) {
          try {
            const details = await axios(
              `${BASE_URL}product/pro-details/${pro.article_code}`
            );
            // console.log(details);
            if (details.status === 200) {
              const data = details.data;
              importProducts = [
                ...importProducts,
                {
                  id: data?._id,
                  article_code: data?.article_code,
                  priceId: data?.priceList[0]?._id,
                  tp: data?.priceList[0]?.tp > 0 ? data?.priceList[0]?.tp : 0,
                  name: data?.name,
                  qty: pro?.qty,
                  tax: 0,
                  discount: 0,
                  order: pro?.order,
                },
              ];
              setOnShow(false);
            }
          } catch (err) {
            notify(err, "error");
          } finally {
            // console.log(importProducts);
            dispatch(selectProducts(importProducts));
          }
        }
      });
    } else {
      setOnShow(false);
      notify("There is no products to import", "error");
    }
    // console.log(importProducts);
  };
  const localStorageAddFromCart = (items) => {
    // console.log(purchaseData);
    // console.log("items", items);
    // console.log("items price", items.id.priceList)
    const priceList = items?.id?.priceList;
    const activePriceList = priceList?.filter((pr) => pr?.status === "active");
    // console.log("activePriceList", activePriceList);
    // console.log("purchase data", purchaseData.id)
    let list = [];
    if (purchaseData.products.length > 0) {
      const matched = purchaseData.products.find(
        (pro) => pro?.id === items?.id?._id
      );
      const rest = purchaseData?.products?.filter(
        (pro) => pro?.id !== items?.id?._id
      );
      // console.log("matched", matched);
      // console.log("rest", rest);
      if (matched) {
        // console.log("matched");
        list = [...purchaseData?.products];
        notify("Product is already added", "error");
      } else {
        // console.log("not matched");
        notify("Product is added", "success");
        console.log("activePriceList", activePriceList);
        const maxDate = activePriceList?.reduce((max, item) => {
          const date = new Date(item?.updatedAt);
          return date > max ? date : max;
        }, new Date("1900-01-01T00:00:00.000Z"));
        // console.log("maxDate", maxDate?.toISOString());
        const maxinUpdatedAt = maxDate?.toISOString();
        const latestPrice = activePriceList?.find(
          (price) => price?.updatedAt === maxinUpdatedAt
        );
        // console.log("latestPrice", latestPrice);
        list = [
          ...rest,
          {
            id: items?.id._id,
            article_code: items?.id?.article_code,
            priceId: latestPrice?._id,
            qty: 1,
            tp: latestPrice?.tp,
            tax: 0,
            discount: 0,
            unit: items?.unit,
            name: items?.name,
            order: purchaseData?.products?.length + 1,
          },
        ];
      }
    } else {
      // console.log(items.id);
      // console.log("activePriceList", activePriceList);
      const maxDate = activePriceList?.reduce((max, item) => {
        const date = new Date(item.updatedAt);
        return date > max ? date : max;
      }, new Date("1900-01-01T00:00:00.000Z"));
      // console.log("maxDate", maxDate?.toISOString());
      // const maxinUpdatedAt = maxDate.toISOString()
      const latestPrice = activePriceList?.find(
        (price) => price.updatedAt === maxDate?.toISOString()
      );
      // console.log("latestPrice", latestPrice);

      list = [
        {
          id: items?.id?._id,
          article_code: items?.id?.article_code,
          priceId: latestPrice?._id,
          qty: 1,
          tp: latestPrice?.tp,
          tax: 0,
          discount: 0,
          name: items?.name,
          order: 1,
        },
      ];
    }
    dispatch(selectProducts(list));
  };
  const handleSearch = (q) => {
    // console.log(q);
    if (q.length > 0) {
      const re = new RegExp(q, "i");
      const filtered = supplierProducts.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.match(re)
        )
      );

      setFilteredProduct(filtered);
      // console.log("Original:", supplierProducts);
      // console.log("filter:", filtered);
    } else {
      setFilteredProduct(supplierProducts);
    }
  };
  const handleBillSubmit = async () => {
    // console.log(purchaseData);
    setOnShow(true);
    try {
      const confirm = window.confirm("Are you Sure? Create this Purchase?");
      if (confirm) {
        const response = await addPurchase(purchaseData);
        if (response) {
          console.log(response);
          if (response?.error) {
            apiUniqueErrHandle(response);
            // console.log("Purchase create error");
          } else {
            // console.log(response?.data?.message);
            // console.log(purchaseData);
            notify("Purchase create is successful", "success");
            dispatch(resetPurchase());
            setPurchase({});
            navigate("/purchase");
          }
        }
      } else {
        notify("Cancel create purchase", "error");
        // return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOnShow(false);
      // dispatch(resetPurchase());
    }
  };
  const emptyCart = () => {
    if (window.confirm("Refresh Cart! Start New Purchase?") === true) {
      dispatch(selectProducts([]));
    } else {
      // console.log("Refresh Operation Cancelled by POSER");
    }
  };
  const handleOnchangeWareHouse = (e) => {
    setWh(e.option);
    dispatch(selectWareHouse(e?.option));
  };
  const handleVendorChange = (value) => {
    setSupplierId(value?.option);
    setOnShow(true);
  };
  const removeFromCart = (code) => {
    const rest = purchaseData?.products?.filter(
      (pro) => pro.article_code !== code
    );
    notify("Product is removed", "error");
    // console.log(code);
    dispatch(selectProducts(rest));
  };
  const addQuantities = (id) => {
    if (purchaseData?.products) {
      let list = [];
      const item = purchaseData?.products?.find(
        (item) => item?.article_code === id
      );
      const restItem = purchaseData?.products?.filter(
        (item) => item?.article_code !== id
      );

      if (item) {
        list = [...restItem, { ...item, qty: parseFloat(item.qty) + 1 }];
      } else {
        list = [...restItem];
      }
      dispatch(selectProducts(list));
    }
  };
  const removeQuantities = (id) => {
    if (purchaseData.products) {
      let list = [];
      const item = purchaseData.products.find(
        (item) => item.article_code === id
      );
      const restItem = purchaseData?.products?.filter(
        (item) => item.article_code !== id
      );

      if (item) {
        list = [...restItem, { ...item, qty: parseFloat(item.qty) - 1 }];
      } else {
        list = [...restItem];
      }
      dispatch(selectProducts(list));
    }
  };
  const handleCustomQty = (e, id) => {
    let list = [];
    const customQty = e.target.value !== "" ? e.target.value : 0;
    // console.log(customQty, id);
    // const cartItems = getStoredCart();
    const item = purchaseData?.products?.find(
      (item) => item?.article_code === id
    );
    if (item) {
      let restItem = purchaseData?.products?.filter(
        (item) => item?.article_code !== id
      );
      if (customQty >= 0) {
        // item.qty = customQty;
        list = [...restItem, { ...item, qty: customQty }];
        dispatch(selectProducts(list));
      }
    }
  };
  const handleCustomTp = (e, id) => {
    let list = [];
    const tp = e.target.value !== "" ? e.target.value : 0;
    // console.log("TP", tp, id);
    const item = purchaseData?.products?.find(
      (item) => item?.article_code === id
    );
    if (item) {
      let restItem = purchaseData?.products?.filter(
        (item) => item?.article_code !== id
      );
      if (tp >= 0) {
        // item.qty = customQty;
        list = [...restItem, { ...item, tp: tp }];
        dispatch(selectProducts(list));
      }
    }
  };
  return (
    <div>
      <div className="container-fluid ">
        <div className="row mb-5 pb-5">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mt-2">
            <Header title="Create New Purchase"></Header>

            <Form className="pt-3" action="javascript:void(0);">
              <div className="row">
                <div className="col-6">
                  {/* <input type="hidden" {...register("_id")} /> */}
                  <Form.Group className="" controlId="warehouse">
                    <Form.Label>Warehouse</Form.Label>
                    {auth?.type !== "admin" ? (
                      <><br/>{whName}</>
                    ) : (
                      <WareHouseDWPurchase
                        handleOnChange={handleOnchangeWareHouse}
                        wh={wh}
                      />
                    )}
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="">
                    <Form.Label>Supplier</Form.Label>
                    <SelectSupplier
                      supplier_code={purchase?.supplier_code}
                      handleOnchange={handleVendorChange}
                    ></SelectSupplier>
                  </Form.Group>
                </div>
                <div className="col-4 mb-3">
                  <Form.Group className="">
                    <Form.Label>Purchase Status</Form.Label>
                    <Form.Select
                      readonly
                      onChange={(e) => dispatch(selectStatus(e.target.value))}
                    >
                      <option seleted value="Pending">
                        Pending
                      </option>
                      {/* <option value="Ordered">Ordered</option>
                      <option value="Received">Received</option>
                      <option value="Canceled">Canceled</option> */}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-8 mb-3">
                  <CsvPurchaseProduct
                    title="Supplier Product"
                    setCsvData={setCsvData}
                    handleImportButton={handleImportButton}
                  />
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-5">
                      <div className="card">
                        <div className="card-header">
                          Supplier Products List
                          <div className="">
                            <input
                              className="form-control form-control-md"
                              type="text"
                              placeholder="Search Product"
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                        </div>
                        <Table
                          className=""
                          style={{
                            overflowY: "scroll",
                            height: "250px",
                            display: "block",
                          }}
                        >
                          <thead>
                            <tr>
                              {/* <th>#</th> */}
                              <th>Code</th>
                              <th>Name</th>
                              <th>Stock</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredProduct?.length > 0 && (
                              <SupplierProduct
                                productList={filteredProduct}
                                localStorageAddFromCart={
                                  localStorageAddFromCart
                                }
                              />
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="card">
                        <div className="card-header">
                          Purchase Products List
                        </div>
                        <Table className="" style={{ minHeight: "285px" }}>
                          <thead>
                            <tr>
                              <th>#</th>
                              {/* <th>Code</th> */}
                              <th>Name</th>
                              {/* <th>Tax</th> */}
                              <th>Quantity</th>
                              <th>TP</th>
                              {/* <th>Tax</th> */}
                              <th>Total</th>
                            </tr>
                          </thead>

                          <tbody>
                            <PurchaseCart
                              purchaseCarts={productList}
                              removeFromCart={removeFromCart}
                              addQuantities={addQuantities}
                              removeQuantities={removeQuantities}
                              handleCustomQty={handleCustomQty}
                              handleCustomTp={handleCustomTp}
                            ></PurchaseCart>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row"> */}
                <div className="col-md-4 mt-3">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Tax</Form.Label>
                    <Form.Select id="tax">
                      <option value="0" selected>
                        0
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-4 mt-3">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                      type="number"
                      className="discount"
                      placeholder="Discount"
                      onChange={(e) => dispatch(selectDiscount(e.target.value))}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4 mt-3">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Shipping Cost</Form.Label>
                    <Form.Control
                      onChange={(e) => dispatch(selectShipping(e.target.value))}
                      type="number"
                      className="shipping"
                      placeholder="Shipping Cost"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12 mb-2">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Note</Form.Label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Note"
                      onChange={(e) => dispatch(selectNote(e.target.value))}
                    />
                  </Form.Group>
                </div>
              </div>
              {/* </div> */}

              <Table className="bordered striped mt-3">
                <thead>
                  <tr>
                    <th>Items: {purchaseData?.totalItem}</th>
                    <th>Total Tax: {purchaseData.tax?.toFixed(2)}</th>
                    <th>Order Total: {purchaseData.total?.toFixed(2)}</th>
                    <th>Order Discount: {purchaseData.discount}</th>
                    <th>Shipping Cost: {purchaseData.shipping_cost}</th>
                    <th>
                      Grand Total:{" "}
                      {parseInt(purchaseData.tax) +
                        parseInt(purchaseData.total) +
                        Number(purchaseData?.shipping_cost) -
                        Number(purchaseData?.discount)}
                    </th>
                  </tr>
                </thead>
              </Table>

              <Button
                variant="dark"
                onClick={handleBillSubmit}
                className="float-end my-2"
                type="button"
              >
                <Icons.SaveOutline size={20} /> Submit
              </Button>
              <Button
                variant="dark"
                className="float-end my-2 mx-2"
                type="button"
                onClick={emptyCart}
              >
                Reset Cart
              </Button>
            </Form>
          </div>
        </div>
        <LoadingModal
          onShow={onShow}
          title="Please Wait.."
          handleClose={handleClose}
        />
        <Toaster position="bottom-right" />
      </div>
    </div>
  );
};

export default PurchaseCreate;
