import React from "react";
const totalCalculation = (products) => {
    // console.log(products)
    let totalTP = 0;
    let totalMRP = 0;
    let totalItemOty = 0;
    products?.length > 0 &&
        products?.map((item) => {
            totalItemOty = totalItemOty + item.totalQuantity;
            totalTP = parseFloat(totalTP) + parseFloat(parseFloat(item.totalQuantity) * parseFloat(item.tp));
            totalMRP = parseFloat(totalMRP) + parseFloat(parseFloat(item.totalQuantity) * parseFloat(item.mrp));
        });

    // console.log(totalTax);
    const profit = parseFloat(totalMRP) - parseFloat(totalTP)
    return [totalTP, totalMRP, totalItemOty, profit];
}
export { totalCalculation };