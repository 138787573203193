import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import "./Supplier.css";
import * as Icons from "heroicons-react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import SearchProduct from "../Common/CustomSelect/SearchProduct";
import { useNavigate } from "react-router-dom";
import {
  supplierProducts,
  DataAddSuppliers,
} from "../../features/supplierSlice";
import { useDispatch, useSelector } from "react-redux";

import { useAddSupplierMutation } from "../../services/supplierApi";

import { apiUniqueErrHandle } from "../Utility/Utility";
import { notify } from "../Utility/Notify";
import SupplierProductModal from "../Common/Modal/SupplierProductModal";
import LoadingModal from "../Common/Modal/LoadingModal";

const CreateSupplier = () => {
  let i = 1;
  const [pData, setPData] = useState([]);
  const [AddSupplier] = useAddSupplierMutation();
  const { register, handleSubmit, reset } = useForm({});
  const supplierProductsRef = useRef(null);

  const [loader, setLoader] = useState(false)
  const handleLoaderClose = () => setLoader(false);

  const getCartData = JSON.parse(localStorage.getItem("supplier_cart"));
  // console.log(csvData);
  console.log(getCartData);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setPData(getCartData);
    console.log(pData);
    dispatch(supplierProducts(pData));
    console.log(supplierProductsRef.current);
  }, []);

  console.log(pData);

  // ADD TO LIST
  const addToList = async (data) => {
    // console.log(data);
    if (data) {
      console.log("add to list:", data);
      await supplierProductsRef.current.blur();
      const getData = JSON.parse(localStorage.getItem("supplier_cart"));
      let listData = getData ? getData : [];
      console.log("old:", listData);

      let newProduct = {};
      // unique check
      const idExist = listData.find((pro) => pro.id === data._id);
      if (idExist) {
        notify("Product Already in List", "error");
        // console.log()
      } else {
        newProduct = {
          id: data._id,
          name: data.name,
          article_code: data.article_code,
          ean: data.ean,
          unit: data.unit,
          order: listData.length + 1,
        };
        listData = [...listData, newProduct];
      }

      console.log("upcoming:", newProduct);
      console.log("Updated:", listData);
      setPData(listData);
      reset({
        products: listData,
      });

      // console.log(newProduct)
      localStorage.setItem("supplier_cart", JSON.stringify(listData));
      supplierProductsRef.current.focus();
    } else {
      return false;
    }
  };
  const onSubmit = async (data) => {
    try {
      dispatch(DataAddSuppliers(data));
      setLoader(true)
      const response = await AddSupplier(data);
      if (response) {
        console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            email: "",
            code: "",
            company: "",
            products: "",
            address: "",
            type: "",
            phone: "",
            status: "",
          });
          console.log(data);
          console.log(response?.data?.message);
          localStorage.removeItem("supplier_cart");
          return navigate("/supplier");
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  };
  const handleReset = () => {
    reset({
      name: "",
      email: "",
      code: "",
      company: "",
      type: "",
      phone: "",
      status: "",
    });
  };

  const removeFromCart = (id) => {
    const getData = JSON.parse(localStorage.getItem("supplier_cart"));
    const restData = getData.filter((pro) => pro.id !== id);
    localStorage.setItem("supplier_cart", JSON.stringify(restData));
    setPData(restData);
    console.log(restData);
  };

  // const jamil = useSelector((state) => state.supplier);
  // console.log("jamil", jamil);
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title={"Add Supplier"}></Header>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className=" mt-1">
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="form-group col-4">
                            {/* <label htmlFor="inputSupplierCompany">Supplier Company</label> */}
                            <input
                              {...register("company", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputSupplierCompany"
                              placeholder="Supplier Company"
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                          <div className="form-group col-4  mb-3">
                            {/* <label htmlFor="inputSupplier">Supplier Name</label> */}
                            <input
                              {...register("name", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputSupplier"
                              placeholder="Supplier Name"
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                          <div className="form-group col-4  mb-3">
                            {/* <label htmlFor="inputEmail">Supplier Email</label> */}
                            <input
                              {...register("email", { required: true })}
                              type="email"
                              className="form-control"
                              id="inputEmail"
                              placeholder="Supplier Email"
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                          <div className="form-group col-4  mb-3">
                            {/* <label htmlFor="inputMC">Supplier Phone</label> */}
                            <input
                              {...register("phone")}
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="Supplier Phone"
                            />
                          </div>
                          <div className="form-group col-4  mb-3">
                            {/* <label htmlFor="status">Status</label> */}
                            <input
                              {...register("code")}
                              type="text"
                              className="form-control"
                              id="code"
                              placeholder="Supplier code"
                            />
                          </div>
                          <div className="form-group col-4  mb-3">
                            {/* <label htmlFor="status">Status</label> */}
                            <select
                              {...register("status")}
                              className="form-select"
                              id="status"
                            >
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                            </select>
                          </div>
                          <div className="form-group col-12  mb-3">
                            {/* <label htmlFor="address">Address</label> */}
                            <textarea
                              {...register("address")}
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            />
                          </div>
                          {/* </div> */}
                          <div className="col-12 mb-3">
                            <p className="mb-1">
                              <b>Supplier's Products</b>
                            </p>
                            <SearchProduct
                              className="searchProduct"
                              // getValue={getValue}
                              addToList={addToList}
                              supplierProductsRef={supplierProductsRef}
                            ></SearchProduct>
                            {/* <ProductSearch
                            addToCart={addToCart}
                            searchResult={searchResult}
                            handleProductOnChange={handleProductOnChange}
                          /> */}
                          </div>
                          {/* <div className="col-2 mb-3 mt-4">
                            <button
                              className="btn btn-dark btn-block "
                              onClick={importProduct}
                            >
                              <Icons.Plus> </Icons.Plus>
                              Import Products
                            </button>
                          </div> */}

                          <div className="col-12 mb-3">
                            <div className="card mt-3 p-2">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>EAN</th>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Unit</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pData ? (
                                    pData
                                      .sort((a, b) => b.order - a.order)
                                      .map((item) => (
                                        <tr key={item.order}>
                                          <th>{i++}</th>
                                          <td>{item.ean}</td>
                                          <td>{item.name}</td>
                                          <td>{item.article_code}</td>
                                          <td>{item.unit}</td>
                                          <td>
                                            <Icons.X
                                              size={20}
                                              onClick={() =>
                                                removeFromCart(item.id)
                                              }
                                            ></Icons.X>
                                          </td>
                                        </tr>
                                      ))
                                  ) : (
                                    <tr>
                                      <td colSpan={6}>
                                        <p className="text-center m-2">
                                          Please select product
                                        </p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-dark float-end "
                        >
                          <Icons.Plus> </Icons.Plus>
                          Supplier
                        </button>
                        {/* <button
                          type="reset"
                          onClick={handleCancle}
                          className="mx-2 btn btn-outline-dark float-end"
                        >
                          Cancle
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default CreateSupplier;
