import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { useRtvSummaryQuery } from "../../services/rtvApi";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { RtvRoutes } from "../../data";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

function RTVSummary() {
  // Date
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [exportData, setExportData] = useState([]);
  const [warehouse, setWarehouse] = useState("allWh");
  const auth = signInUser();


  const [loader, setLoader] = useState(true);

  const { data, isSuccess, refetch } = useRtvSummaryQuery({
    startDate,
    endDate,
    warehouse
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse]);

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);

  useEffect(() => {
    setLoader(false);
    let newData = [];
    if (data?.products?.length > 0) {
      setExportData([]);

      data?.products?.map((pro) => {
        newData = [
          ...newData,
          {
            ...pro,
            // mrp: pro.mrp / pro.rtvQty,
            tp: pro.tp / pro.rtvQty,
            // vat: pro.vat / pro.rtvQty,
          },
        ];
        // console.log(pro.mrp, pro.mrp / pro.rtvQty);
      });
      setExportData(newData);
    }
  }, [isSuccess, data]);

  // console.log(data, exportData);

  const handleLoaderClose = () => setLoader(false);
  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
  };
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Rtv Summary by Article"></Header>
            <div className="row mt-3">
              <div className="col-md-7">
                {/* Sort date range */}
                <div className="date-picker d-flex mb-2 align-items-center gap-2">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  {/* <span width="10px"></span> */}
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  {/* warehouse */}
                  <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                  {/* Search */}
                  {/* <input
                    className="form-control"
                    name="search"
                    type="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />*/}
                </div>
              </div>
              <div className="col-md-5">
                <span className="float-end">
                  <ExportNav
                    Routes={RtvRoutes}
                    data={exportData}
                    title="RTV Summary"
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="">Aricle Code</th>
                  <th>Name</th>
                  {/* <th className="text-end">Avg MRP</th> */}
                  <th className="text-end">Avg TP</th>
                  <th className="ps-3">QTY</th>
                  <th className="text-center">Unit</th>
                  <th className="text-center">RTV</th>
                  <th className="text-end">Stock Value</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.length > 0 ? (
                  data?.products?.map((rtv) => (
                    <tr key={rtv._id}>
                      {/* <td>{rtv.rtvNo}</td> */}
                      <td className="">{rtv.article_code}</td>
                      <td>{rtv.name}</td>
                      {/* <td className="text-end">
                        {(rtv.mrp / rtv.rtvQty).toFixed(2)}
                      </td> */}
                      <td className="text-end">
                        {(rtv.tp / rtv.rtvQty).toFixed(2)}
                      </td>
                      <td className="ps-3">
                        <b>{parseFloat(rtv.qty)} </b>
                      </td>
                      <td className="text-center">{rtv.unit}</td>
                      <td className="text-center">{rtv.rtvQty}</td>
                      <td className="text-end">
                        <b>{new Intl.NumberFormat().format(rtv.stockValue)} </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={7}>No Product Found</tr>
                )}
                <tr>
                  <td className="text-end" colSpan={6}>
                    <b>TOTAL STOCK VALUE:</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {typeof data?.stockValue === "number"
                        ? new Intl.NumberFormat().format(data?.stockValue)
                        : 0}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* pagination */}
            {/* <ProductDataTable></ProductDataTable> */}
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default RTVSummary;
