import { useWarehouseQuery } from "../../services/warehouseApi";
import { notify } from "./Notify";
const productById = async (id) => {
  await fetch(`${process.env.REACT_APP_API_URL}productbycode/${id}`)
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
};
//warehouseById 


const categoryByCode = (id) => {
  const product = productById(id);
  return product.category;
};
const mcByCode = (id) => {
  const product = productById(id);
  return product.master_category;
};

const apiUniqueErrHandle = (response) => {
  if (response.error.data.error.code === 11000) {
    for (const key in response.error.data.error.keyValue) {
      console.log(
        "Insert Error!",
        `# ==>${key}: ${response.error.data.error.keyValue[key]} is not unique`
      );
      let option = response.error.data.error.keyValue;

      return { status: false, option };
    }
  } else {
    return { status: true };
  }
};

export { categoryByCode, mcByCode, apiUniqueErrHandle };
