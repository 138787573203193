import React, { useEffect, useState } from "react";
import { Toast, Card, Table } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import * as Icons from "heroicons-react";
import LineChart from "./Chart/LineChart";
import PieChart from "./Chart/PieChart";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import {
  useSaleFootfallQuery,
  useSalesPointSpentQuery,
  useSalesWeeklyQuery,
  useSaleTotalQuery,
} from "../../../services/saleApi";
import {
  useTodayGrnsQuery,
  useWeeklyGrnsQuery,
} from "../../../services/grnApi";
import { useTodayDamagesQuery } from "../../../services/damageApi";
import subDays from "date-fns/subDays";
import AdminDashboard from "./AdminDashboard";
import AccountsDashboard from "./AccountsDashboard";
import SupervisorDashboard from "./SupervisorDashboard";
import EcomDashboard from "./EcomDashboard";
import POSDashboard from "./POSDashboard";

const Dashboard = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      {(loggedInUser.type === "admin" ||
        loggedInUser.type === "manager" ||
        loggedInUser.type === "owner") && <AdminDashboard />}

      {loggedInUser.type === "POS" && <POSDashboard />}
      {loggedInUser.type === "accounts" && <AccountsDashboard />}
      {loggedInUser.type === "ecom" && <EcomDashboard />}
      {loggedInUser.type === "supervisor" && <SupervisorDashboard />}
    </>
  );
};

export default Dashboard;
