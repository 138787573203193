import React from "react";
import * as Icons from "heroicons-react";
import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ProgressBar,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  useInventoryExportQuery,
  useInventoryExportNewQuery,
} from "../../../services/inventoryApi";

const ExportInventory = ({ show, handleClose, pageCountQuery }) => {
  const [exportCSV, setExportCSV] = useState([]);
  const [exportArticleCSV, setExportArticleCSV] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [current, setCurrent] = useState(0);
  const [priceHeader, setPriceHeader] = useState([
    { label: "article_code", key: "article_code", status: true },
    { label: "name", key: "name", status: true },
    { label: "priceId", key: "priceId", status: true },
    { label: "openingQty", key: "openingQty", status: true },
    { label: "totalQty", key: "totalQty", status: true },
    { label: "soldQty", key: "soldQty", status: true },
    { label: "damageQty", key: "damageQty", status: true },
    { label: "rtvQty", key: "rtvQty", status: true },
    { label: "tpnQty", key: "tpnQty", status: true },
    { label: "currentQty", key: "currentQty", status: true },
    { label: "tp", key: "tp", status: true },
    { label: "mrp", key: "mrp", status: true },
    { label: "stockValue", key: "stockValue", status: true },
    { label: "warehouse", key: "warehouse", status: true },
    { label: "supplier", key: "supplier", status: true },
    { label: "status", key: "status", status: true },
  ]);
  const [articleHeader, setArticleHeader] = useState([
    { label: "article_code", key: "article_code", status: true },
    { label: "name", key: "name", status: true },
    { label: "currentQty", key: "currentQty", status: true },
    { label: "avgTP", key: "avgTP", status: true },
    { label: "avgMRP", key: "avgMRP", status: true },
    { label: "supplier", key: "supplier", status: true },
    { label: "stockValue", key: "stockValue", status: true },
    { label: "status", key: "status", status: true },
  ]);
  const { data, isSuccess, isFetching, isError } = useInventoryExportNewQuery();
  const articleData = []; // useInventoryExportQuery();

  const [PriceTableHeader, setPriceTableHeader] = useState([]);
  const [ArticleTableHeader, setArticleTableHeader] = useState([]);

  const update_price_table = () => {
    let price = [];
    priceHeader.map((item) => {
      if (item.status === true) {
        price = [...price, item];
      }
    });
    setPriceTableHeader(price);
  };
  const update_article_table = () => {
    let article = [];
    articleHeader.map((item) => {
      if (item.status === true) {
        article = [...article, item];
      }
    });
    setArticleTableHeader(article);
  };
  const handlePriceOutputSelect = (item) => {
    console.log(item);
    const index = priceHeader.findIndex((field) => field.key === item.key);
    priceHeader[index].status = !priceHeader[index].status;
    console.log("priceHeader", priceHeader);
    update_price_table();
  };
  const handleArticleOutputSelect = (item) => {
    console.log(item);
    const index = articleHeader.findIndex((field) => field.key === item.key);
    articleHeader[index].status = !articleHeader[index].status;
    console.log("articleHeader", articleHeader);
    update_article_table();
  };
  console.log(PriceTableHeader);

  // console.log("Export Data", isFetching, isError, isSuccess, data);

  useEffect(() => {
    if (data?.length > 0) {
      setExportCSV(data);
      setDataReady(true);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (articleData?.data?.length > 0) {
      setExportArticleCSV(articleData.data);
      setDataReady(true);
    }
  }, [articleData.isSuccess, articleData.data]);

  console.log("exportArticleCSV", exportArticleCSV);
  console.log("exportCSV", exportCSV);

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Export Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {/* Tab Items */}
        <Tabs
          defaultActiveKey="ArticleExport"
          id="uncontrolled-tab-example"
          className="mb-0 py-0"
        >
          <Tab eventKey="ArticleExport" title="Article Export">
            {dataReady === false ? (
              <div className="d-flex flex-column align-items-center">
                {/* <Spinner animation="grow" variant="warning" size="sm" /> */}
                <p className="text-dark mt-3 mb-3 text-center">
                  {!isFetching ? (
                    <>
                      <Icons.ClockOutline
                        className="icon-trash text-warning"
                        size={80}
                      />
                      <small>
                        Please Wait! when Your Export is Getting ready!
                      </small>
                    </>
                  ) : (
                    <>
                      <Spinner animation="border" variant="danger" />
                      <br />
                      <small>
                        Please We are Loading {pageCountQuery} Products Data!
                      </small>
                    </>
                  )}
                  <br />
                  <ProgressBar className="w-100" animated now={100} />
                </p>
              </div>
            ) : (
              <>
                <div className="d-flex flex-column align-items-start mb-5">
                  <p className="my-3">
                    <b>Select Export Output</b>
                  </p>
                  <div className="row text-start mx-1">
                    {articleHeader?.length > 0 &&
                      articleHeader?.map((item) => {
                        return (
                          <div className="form-check col" key={item.key}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.key}
                              id={item.key}
                              checked={item.status}
                              onChange={() => handleArticleOutputSelect(item)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.key}
                            >
                              {item.label}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <Icons.CheckCircleOutline
                    className="icon-trash text-success"
                    size={100}
                  />
                  <p className="text-dark my-3">Your Export is ready!</p>
                  <CSVLink
                    className="btn btn-dark"
                    data={exportArticleCSV}
                    asyncOnClick={true}
                    headers={ArticleTableHeader}
                    filename={`Export Inventory Data - [${today.toDateString()}].csv`}
                  >
                    <Icons.DownloadOutline
                      className="icon-trash text-warning"
                      size={22}
                    />{" "}
                    Download Article Inventory Data
                  </CSVLink>
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="PriceStockExport" title="Price Stock Export">
            {dataReady === false ? (
              <div className="d-flex flex-column align-items-center">
                {/* <Spinner animation="grow" variant="warning" size="sm" /> */}
                <p className="text-dark mt-3 mb-3 text-center">
                  {!isFetching ? (
                    <>
                      <Icons.ClockOutline
                        className="icon-trash text-warning"
                        size={80}
                      />
                      <small>
                        Please Wait! when Your Export is Getting ready!
                      </small>
                    </>
                  ) : (
                    <>
                      <Spinner animation="border" variant="danger" />
                      <br />
                      <small>
                        Please We are Loading {pageCountQuery} Products Data!
                      </small>
                    </>
                  )}
                  <br />
                  <ProgressBar className="w-100" animated now={100} />
                </p>
              </div>
            ) : (
              <>
                <div className="d-flex flex-column align-items-start mb-5">
                  <p className="my-3">
                    <b>Select Export Output</b>
                  </p>
                  <div className="row text-start mx-1">
                    {priceHeader?.length > 0 &&
                      priceHeader?.map((item) => {
                        return (
                          <div className="form-check col" key={item.key}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.key}
                              id={item.key}
                              checked={item.status}
                              onChange={() => handlePriceOutputSelect(item)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.key}
                            >
                              {item.label}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <Icons.CheckCircleOutline
                    className="icon-trash text-success"
                    size={100}
                  />
                  <p className="text-dark my-3">Your Export is ready!</p>
                  <CSVLink
                    className="btn btn-dark"
                    data={data}
                    asyncOnClick={true}
                    headers={PriceTableHeader}
                    filename={`Export Inventory Data - [${today.toDateString()}].csv`}
                  >
                    <Icons.DownloadOutline
                      className="icon-trash text-warning"
                      size={22}
                    />{" "}
                    Download Price Inventory Data
                  </CSVLink>
                </div>
              </>
            )}
          </Tab>
        </Tabs>
        {/* Tab Item */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="darkOutline" onClick={handleClose}>
          <Icons.X className="icon-trash text-danger" size={22} /> Cancle
        </Button>
      </Modal.Footer>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
    </Modal>
  );
};

export default ExportInventory;
