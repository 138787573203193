import React from "react";
import { startOfToday, endOfToday, format, formatDistance, isWithinInterval, parseISO } from "date-fns";
const toDecimal = (float) => {
  if (float) {
    return float.toFixed(2);
  }
  return float;
};
const totalItem = (qty, price) => {
  const itemTotal = parseFloat(qty) * parseFloat(price);
  return toDecimal(parseFloat(itemTotal));
};
const itemTotal = (qty, price) => {
  // console.log(parseFloat(qty) * parseFloat(price))
  let itemTotalP
  if (price > 0) {

    itemTotalP = parseFloat(qty) * parseFloat(price);
  }
  else {
    itemTotalP = parseFloat(qty) * parseFloat(0);
  }

  return toDecimal(parseFloat(itemTotalP));
};

const itemTax = (tax, qty, price) => {
  // console.log(tax, qty, price)
  const itemTaxTotal = itemTotal(qty, price) * (parseInt(tax) / 100);
  return toDecimal(parseFloat(itemTaxTotal));
};

const itemVat = (vat, qty, price) => {
  // console.log(vat, qty, price)
  const itemVatTotal = itemTotal(qty, price) * (parseFloat(vat) / 100);
  return parseFloat(itemVatTotal);
};
const itemPromo = (qty, promo_type, promo_price, promo_start, promo_end, mrp) => {
  let promoPrice = 0
  if (promo_start && promo_end) {
    const isBetween = isWithinInterval(new Date(), { start: parseISO(promo_start), end: parseISO(promo_end) });
    if (isBetween) {

      if (promo_type) {
        promoPrice = parseFloat(qty) * ((parseFloat(promo_price ? promo_price : 0) / 100) * mrp)
      } else {
        promoPrice = parseFloat(qty) * parseFloat(promo_price ? promo_price : 0)
      }
    }
  }


  return parseFloat(promoPrice)
}
const total = (products) => {
  // console.log(products)
  let totalTax = 0;
  let total1 = 0;
  let totalItemOty = 0;
  let vatAmount = 0;
  let promoPrice = 0;
  products?.length > 0 &&
    products?.map((item) => {
      totalTax =
        parseFloat(totalTax) + parseFloat(itemTax(item.tax, item.qty, item.mrp));

      totalItemOty = parseFloat(totalItemOty) + parseFloat(item.qty);
      total1 = parseFloat(total1) + parseFloat(itemTotal(item.qty, item.mrp));
      vatAmount = parseFloat(vatAmount) + itemVat(item.vat, item.qty, item.mrp);
      promoPrice = promoPrice + itemPromo(item?.qty, item?.promo_type, item?.promo_price, item?.promo_start, item?.promo_end, item?.mrp)
      // console.log(vatAmount);
    });
  return { totalTax, total1, totalItemOty, vatAmount, promoPrice };
};

// const itemVatTotal = (vat, qty, price) => {
//   const itemTotal = parseFloat(qty) * parseInt(price);
//   const itemVat = parseFloat(qty) * parseInt(price) * (parseInt(vat) / 100);
//   const itemVatTotal = toDecimal(parseFloat(itemTotal + itemVat));
//   return itemVatTotal;
// };

// const roundingTotal = (total) => {
//   return total;, roundingTotal
// };
const itemVatTotal = (vat, qty, price) => {
  const itemTotal = parseFloat(qty) * parseFloat(price);
  const itemVat = parseFloat(qty) * parseFloat(price) * (parseFloat(vat) / 100);
  const itemVatTotal = parseFloat(itemTotal + itemVat);
  return itemVatTotal;
};

export { itemVat, itemTotal, itemTax, total, toDecimal, itemVatTotal };
