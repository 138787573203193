import React from 'react';
import SideBar from '../Common/SideBar/SideBar';
import Header from '../Common/Header/Header';

const UpdateAccount = () => {
    return (
        <div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        <Header title="Update Account"></Header>
                        <div className="row mt-3">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateAccount;