import {
  startOfToday,
  endOfToday,
  format,
  formatDistance,
  isWithinInterval,
  parseISO,
} from "date-fns";
// use local storage to manage cart data
const addToDb = (data, price, qty) => {
  const storedCart = getStoredCart();
  let listData = storedCart ? storedCart : [];

  console.log("data:", qty);

  if (data) {
    let newProduct = {};

    const idExist = listData.find((pro) => pro.id === data._id);
    if (idExist) {
      // TODO:: INCREASE QUANTITY
      return true;
    } else {
      let newPrice = {};
      const priceList = data.priceList;
      const activePriceList = priceList.filter(
        (pr) => pr.status === "active" && pr._id === price
      );

      if (activePriceList.length > 0) {
        newPrice = {
          id: activePriceList[0]._id,
          mrp: activePriceList[0].mrp,
          tp: activePriceList[0].tp,
        };
      }

      // console.log("New newPrice", data);

      if (data?.promo_start && data?.promo_end) {
        const promoStart = parseISO(data.promo_start);
        const promoEnd = parseISO(data.promo_end);
        if (!isNaN(promoStart) && !isNaN(promoEnd)) {
          const isBetween = isWithinInterval(new Date(), {
            start: promoStart,
            end: promoEnd,
          });
          if (isBetween) {
            newPrice = {
              ...newPrice,
              promo_price: data?.promo_price,
              promo_type: data?.promo_type,
            };
          }
        }
      }

      newProduct = {
        id: data?._id,
        priceId: newPrice.id,
        name: data.name,
        article_code: data.article_code,
        ean: data.ean,
        stock: data.stock,
        mrp: newPrice?.mrp ? newPrice.mrp : 0,
        qty: qty,
        tp: newPrice?.tp ? newPrice.tp : 0,
        vat: data?.vat ? data?.vat : 0,
        unit: data.unit,
        promo: {
          promo_price: newPrice?.promo_price ? newPrice?.promo_price : 0,
          promo_type: newPrice?.promo_type ? newPrice.promo_type : false,
        },
        supplier:
          activePriceList && activePriceList.length > 0
            ? activePriceList[0]?.supplier
            : 0,
        order: listData.length + 1,
      };
      listData = [...listData, newProduct];
      localStorage.setItem("pos_cart", JSON.stringify(listData));

      // console.log("New Product", newProduct);
      // console.log("New Price", newPrice);

      return true;
    }
  } else {
    return false;
  }
};

const getStoredCart = () => {
  let shoppingCart = [];

  //get the shopping cart from local storage
  const storedCart = localStorage.getItem("pos_cart");
  if (storedCart) {
    shoppingCart = JSON.parse(storedCart);
    shoppingCart = shoppingCart?.sort((a, b) => b.order - a.order);
  }
  // console.log(shoppingCart);
  return shoppingCart;
};

const removeFromDb = (id) => {
  const storedCart = getStoredCart();
  if (storedCart) {
    const newCart = storedCart.filter((p) => p.article_code !== id);
    return localStorage.setItem("pos_cart", JSON.stringify(newCart))
      ? "true"
      : "false";
  }
};

const removeQuantity = (id) => {
  const storedCart = getStoredCart();
  if (storedCart) {
    const selectedItem = storedCart.find((p) => p.article_code === id);
    const restItem = storedCart.filter((p) => p.article_code !== id);

    selectedItem.qty = selectedItem.qty > 0 ? selectedItem.qty - 1 : 0;
    const newCart = [...restItem, selectedItem];

    return localStorage.setItem("pos_cart", JSON.stringify(newCart))
      ? "true"
      : "false";
  }
};

const addQuantity = async (id) => {
  const storedCart = getStoredCart();
  if (storedCart) {
    const selectedItem = storedCart.find((p) => p.article_code === id);
    const restItem = storedCart.filter((p) => p.article_code !== id);
    // console.log(selectedItem, selectedItem.qty);

    // const newQty = parseInt(selectedItem.qty) + 1;
    console.log("selectedItem", selectedItem, "restItem", restItem);
    const newCart = [
      ...restItem,
      {
        ...selectedItem,
        qty: parseFloat(selectedItem.qty) + 1,
      },
    ];

    console.log(newCart, selectedItem.qty);
    return localStorage.setItem("pos_cart", JSON.stringify(newCart))
      ? "true"
      : "false";
  }
};

const customQuantity = (id, value) => {
  const storedCart = getStoredCart();
  if (storedCart) {
    const selectedItem = storedCart.find((p) => p.article_code === id);
    const restItem = storedCart.filter((p) => p.article_code !== id);

    selectedItem.qty = value > 0 ? value : 0;
    const newCart = [...restItem, selectedItem];

    return localStorage.setItem("pos_cart", JSON.stringify(newCart))
      ? "true"
      : "false";
  }
};

const deleteShoppingCart = () => {
  return localStorage.removeItem("pos_cart") ? "true" : "false";
};

export {
  addToDb,
  addQuantity,
  removeQuantity,
  getStoredCart,
  removeFromDb,
  deleteShoppingCart,
  customQuantity,
};
