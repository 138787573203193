import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import "./Brand.css";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import SideBar from "../Common/SideBar/SideBar";
import CsvImporter from "../Common/CsvImporter/CsvImporter";
import {
  useBrandsQuery,
  useBrandQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} from "../../services/brandApi";
import { useNavigate } from "react-router-dom";
import { apiUniqueErrHandle } from "../Utility/Utility";
import LoadingModal from "../Common/Modal/LoadingModal";
import { signInUser } from "../Utility/Auth";

const Brand = () => {
  const [AddBrand] = useAddBrandMutation();
  const { data, isLoading } = useBrandsQuery();
  const { register, handleSubmit, reset } = useForm({});
  const [deleteBrand] = useDeleteBrandMutation();

  let navigate = useNavigate();
  let i = 1;

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  const auth = signInUser();

  const onSubmit = async (data) => {
    setLoader(true);
    console.log(data);
    try {
      const response = await AddBrand(data);
      if (response) {
        console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            photo: "",
            company: "",
            status: "",
          });
          console.log(response?.data?.message);
          return navigate("/brand");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const deleteHandler = async (id) => {
    const confirm = window.confirm("Are you Sure? Delete this Brand?");
    if (confirm) {
      const res = await deleteBrand(id);
      if (res) {
        // TODO::
        // add error hendaler for delete error
        console.log(res);
      } else {
        console.log("Delete Operation Canceled by Brand!");
        return;
      }
    }
  };

  const handleReset = () => {
    reset({
      name: "",
      photo: "",
      company: "",
    });
  };
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Brand"></Header>
            <div className="row">
              <div className="col-md-4 ">
                <div className="sticky-md-top ">
                  <div className="card mt-2">
                    <div className="card-header">
                      <h5 className="card-title">
                        {data?._id ? "Update" : "Add"} Brand
                      </h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputCustomer">Brand Name</label>
                            <input
                              {...register("name", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputCustomer"
                              aria-describedby="emailHelp"
                              placeholder="Unit Name"
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC"> company</label>
                            <input
                              {...register("company")}
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="company"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="MCId">status</label>
                            <select
                              {...register("status")}
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                            </select>
                          </div>
                        </div>
                        <button
                          type="reset"
                          onClick={handleReset}
                          className="btn btn-outline-dark col-4 col-md-4"
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-dark col-8 col-md-8"
                        >
                          {data?._id ? (
                            <>
                              <Icons.SaveOutline></Icons.SaveOutline>
                            </>
                          ) : (
                            <>
                              <Icons.Plus> </Icons.Plus>
                            </>
                          )}
                          Brand
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* <CsvImporter setCsvData={setCsvData} handleImportButton={handleImportButton} title="Customer"></CsvImporter> */}
                </div>
              </div>
              <div className="col-md-8">
                {/* <nav aria-label="Customer Pagination" className="">
                  <ul className="pagination pagination-md">
                    <li className="page-item">
                      <select
                        className="form-control"
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option
                          value="50"
                          selected={size === 25 ? "selected" : ""}
                        >
                          25
                        </option>
                        <option
                          value="50"
                          selected={size === 50 ? "selected" : ""}
                        >
                          50
                        </option>
                        <option
                          value="100"
                          selected={size === 75 ? "selected" : ""}
                        >
                          75
                        </option>
                        <option
                          value="150"
                          selected={size === 150 ? "selected" : ""}
                        >
                          100
                        </option>
                      </select>
                    </li>
                    {[...Array(pageCount).keys()].map((number) => (
                      <li
                        className={
                          page === number ? "page-item disabled" : "page-item"
                        }
                        key={number}
                      >
                        <button
                          onClick={() => setPage(number)}
                          className="page-link"
                          tabIndex={page === number ? "-1" : ""}
                        >
                          {number + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav> */}
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Brand Name</th>
                      <th scope="col">comapny Name</th>
                      <th scope="col">status</th>
                      <th scope="col">ActionBtn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data.map((Brand) => (
                        <tr key={Brand._id}>
                          <th scope="row">{i++}</th>
                          <td>{Brand.name}</td>
                          <td>{Brand.company}</td>
                          <td>{Brand.status}</td>
                          <td>
                            <Link to={`/brand/update/${Brand._id}`}>
                              <Icons.PencilAltOutline
                                className="icon-edit"
                                size={20}
                              ></Icons.PencilAltOutline>
                            </Link>
                            {auth?.type === "admin" && (
                              <Icons.TrashOutline
                                className="icon-trash"
                                onClick={() => deleteHandler(Brand._id)}
                                size={20}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>1</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster position="bottom-right" /> */}
    </div>
  );
};

export default Brand;
