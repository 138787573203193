import React, { useEffect, useState } from "react";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import { Table } from "react-bootstrap";
import * as Icons from "heroicons-react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import SelectCustomerLedger from "../Common/CustomSelect/selectCustomerLedger";
import { usePaymentByCustomerQuery } from "../../services/paymentApi";
import { Link } from "react-router-dom";

const Payments = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [customer, setCustomer] = useState({});
  const [payments, setPayments] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [show, setShow] = useState(false);

  console.log(startDate, endDate);

  // const data = []
  const { data, isSuccess, refetch } = usePaymentByCustomerQuery({
    startDate,
    endDate,
    // customerId,
  });
  let i = 1;

  useEffect(() => {
    setCustomerId(customer?._id);
  }, [customer]);

  useEffect(() => {
    data?.length > 0 && setPayments(data);
  }, [isSuccess, data]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate, customerId]);

  const handleClose = () => setShow(false);

  console.log(data);

  const handleAccountsView = async (e) => {
    setShow(true);
    console.log(e);
    // setAccountId(e);
  };
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <Header title="Due Collections"></Header>
              </div>

              <div className="col-md-3 mt-2 mb-2">
                <div className="date-picker d-flex align-items-center">
                  <b>Start:</b>
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-2">
                <div className="date-picker d-flex align-items-center">
                  <b>End:</b>
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>
              </div>

              <div className="col-md-3 mt-2 mb-2">
                {/* <SelectCustomerLedger setSelcetCustomer={setCustomer} /> */}
              </div>
              <div className="col-md-3 mt-2 mb-2"></div>

              <div className="col-md-12 mt-2 mb-2">
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Date</th>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Biller</th>
                      <th scope="col">Authorized</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Amount </th>
                      <th scope="col">Status </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments?.length > 0 ? (
                      payments?.map((payment) => (
                        <tr key={payment?._id}>
                          <th scope="row">{i++}</th>
                          <td>{payment?.customer}</td>
                          <td>{payment?.date}</td>
                          <td>{payment?.transId}</td>
                          <td>{payment?.billerId}</td>
                          <td>{payment?.authorized}</td>
                          <td>
                            {payment?.invoiceIds?.length > 0 &&
                              payment?.invoiceIds?.map((invoice) => {
                                return (
                                  <>
                                    <Link
                                      className="me-2"
                                      to={`/print/${invoice?._id}`}
                                      target="_blank"
                                      key={invoice?._id}
                                    >
                                      <small>{invoice?.invoiceId}</small>
                                    </Link>
                                    ,{" "}
                                  </>
                                );
                              })}
                            {}
                          </td>
                          <td>{payment?.dueAmount} TK</td>
                          <td>{payment?.status}</td>
                        </tr>
                      ))
                    ) : (
                      // <tr>
                      //   <td colSpan={6}>
                      //     {isLoading && <Skeleton count={100} />}
                      //     {/* {isFetching && <h2>...isFetching</h2>}
                      //     {error && <h2>Something went wrong</h2>} */}
                      //   </td>
                      // </tr>
                      <tr colSpan={9}>No Accounts Found</tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AccountView
        show={show}
        handleClose={handleClose}
        // accountId={accountId}
      ></AccountView> */}
    </div>
  );
};

export default Payments;
