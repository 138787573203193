import React, { useEffect, useState } from "react";
import SelectTpDamage from "../Common/CustomSelect/SelectTpDamage";
import * as Icons from "heroicons-react";
import { Textarea } from "react-daisyui";
import { BsCheckSquare, BsSquare } from "react-icons/bs";

const AdjustProducts = ({
  adjustProducts,
  i,
  handleCustomQty,
  removeFromCart,
  handleOnChangePrice,
  handleCustomReason,
  handleDeSelectIsType,
  handleIsType,
}) => {
  const [tempQty, setTempQty] = useState([]);

  useEffect(() => {
    let cartTempQty = [];

    adjustProducts?.map((item) => {
      cartTempQty = [...cartTempQty, { id: item.article_code, qty: item.qty }];
    });
    setTempQty(cartTempQty);
  }, [adjustProducts]);
  // console.log(adjustProducts);

  return (
    <>
      {adjustProducts?.length > 0 ? (
        adjustProducts
          ?.slice()
          ?.sort((a, b) => a.order - b.order)
          // sorting a problem hosse // pleas check
          ?.map((item) => (
            <tr key={item?.order}>
              <th>{i++}</th>
              {/* <td>{item?.ean}</td> */}
              <td>{item?.article_code}</td>
              <td>{item?.name}</td>
              {/* <td>{item?.qty}</td> */}
              <td>
                {Boolean(item?.type) ? (
                  <>
                    <BsCheckSquare
                      onClick={() => handleDeSelectIsType(item?.article_code)}
                    />{" "}
                    In
                  </>
                ) : (
                  <>
                    <BsSquare
                      onClick={() => handleIsType(item?.article_code)}
                    />{" "}
                    Out
                  </>
                )}
              </td>
              <td className="col-md-2">
                <div className="input-group ">
                  {/* quantity */}
                  <input
                    type="text"
                    className="form-control quantity"
                    width="40%"
                    id={item?.article_code}
                    onChange={(e) =>
                      handleCustomQty(e.target.value, item?.article_code)
                    }
                    value={item?.qty}
                    defaultValue={item?.qty}
                  />
                </div>
              </td>
              {/* <td>{item?.tp}</td> */}
              <td>
                <SelectTpDamage
                  sc={item?.id}
                  setVal={item?.tp}
                  handleOnChangeCategory={handleOnChangePrice}
                  // handleCustomMrp={handleCustomMrp}
                ></SelectTpDamage>
              </td>
              <td>{parseFloat(item?.tp * item?.qty).toFixed(2)}</td>
              <td>
                <Textarea
                  type="text"
                  className="form-control quantity"
                  width="30%"
                  rows="1"
                  onChange={(e) =>
                    handleCustomReason(e.target.value, item?.article_code)
                  }
                />
              </td>
              <td>
                <Icons.X
                  size={20}
                  onClick={() => removeFromCart(item?.id)}
                ></Icons.X>
              </td>
            </tr>
          ))
      ) : (
        <tr>
          <td colSpan={6}>
            {/* <p className="text-center m-2">
                            Please select product
                        </p> */}
          </td>
        </tr>
      )}
    </>
  );
};

export default AdjustProducts;
