import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

const Layout = () => {
  return (
    <main className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS Application</title>
      </Helmet>
      <Outlet />
    </main>
  );
};

export default Layout;
