import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { InventoryRoutes } from "../../data";
import { useInventoryExportNewQuery } from "../../services/inventoryApi";

function InventoryDetails() {
  let i = 1;
  const [exportData, setExportData] = useState([]);
  const [PriceTableHeader, setPriceTableHeader] = useState([]);

  const [loader, setLoader] = useState(true);

  const { data, isSuccess, isFetching, isError } = useInventoryExportNewQuery();

  const [priceHeader, setPriceHeader] = useState([
    { label: "article_code", key: "article_code", status: true },
    { label: "name", key: "name", status: true },
    { label: "priceId", key: "priceId", status: true },
    { label: "openingQty", key: "openingQty", status: true },
    { label: "totalQty", key: "totalQty", status: true },
    { label: "soldQty", key: "soldQty", status: true },
    { label: "damageQty", key: "damageQty", status: true },
    { label: "rtvQty", key: "rtvQty", status: true },
    { label: "tpnQty", key: "tpnQty", status: true },
    { label: "currentQty", key: "currentQty", status: true },
    { label: "tp", key: "tp", status: true },
    { label: "mrp", key: "mrp", status: true },
    { label: "stockValue", key: "stockValue", status: true },
    { label: "warehouse", key: "warehouse", status: true },
    { label: "supplier", key: "supplier", status: true },
    { label: "status", key: "status", status: true },
  ]);

  useEffect(() => {
    if (data > 0) {
      setExportData(data);
    }
    setLoader(false);
  }, [isSuccess, data]);

  const update_price_table = () => {
    let price = [];
    priceHeader.map((item) => {
      if (item.status === true) {
        price = [...price, item];
      }
    });
    setPriceTableHeader(price);
  };

  const handlePriceOutputSelect = (item) => {
    console.log(item);
    const index = priceHeader.findIndex((field) => field.key === item.key);
    priceHeader[index].status = !priceHeader[index].status;
    console.log("priceHeader", priceHeader);
    update_price_table();
  };

  const handleLoaderClose = () => setLoader(false);

  // console.log(data?.stockValue);

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Inventory Details Export"></Header>
            <div className="row mt-3 mb-2">
              <div className="col-md-5">
                <b>Select Export Output</b>
              </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    Routes={InventoryRoutes}
                    title="Inventory Details Export"
                    data={data}
                  />
                </span>
              </div>
              <div className="col-md-12">
                <div class="alert alert-primary" role="alert">
                  <div className="row text-start mx-1 alert">
                    {/* Export Selection */}
                    {priceHeader?.length > 0 &&
                      priceHeader?.map((item) => {
                        return (
                          <div className="form-check col-md-2" key={item.key}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.key}
                              id={item.key}
                              checked={item.status}
                              onChange={() => handlePriceOutputSelect(item)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.key}
                            >
                              {item.label}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <Table striped bordered hover className="text-sm">
              <thead>
                <tr>
                  <th>Article Code</th>
                  <th>Name</th>
                  <th>Supplier</th>
                  <th className="text-center">MRP</th>
                  <th className="text-center">TP</th>
                  <th className="text-center">QTY</th>
                  <th className="text-center">Unit</th>
                  <th className="text-end">Stock Value</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((inventory) => (
                    <tr key={i++}>
                      <td>{inventory?.article_code}</td>
                      <td>{inventory?.name}</td>
                      <td>{inventory?.supplier}</td>
                      <td className="text-center">{inventory.mrp} </td>
                      <td className="text-center">
                        {(inventory?.tp).toFixed(2)}
                      </td>
                      <td className="text-center">
                        <b>{parseFloat(inventory?.currentQty)} </b>
                        {/* {`[${inventory?.unit}]`} */}
                      </td>
                      <td className="text-center">{inventory?.unit}</td>
                      <td className="text-end">
                        <b>
                          {new Intl.NumberFormat().format(
                            inventory?.stockValue
                          )}{" "}
                        </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={8}>
                    {data?.length > 0 ? (
                      <>Loading... </>
                    ) : (
                      <>No Product Found</>
                    )}
                  </tr>
                )}
                {/* <tr>
                  <td className="text-end" colSpan={6}>
                    <b>TOTAL STOCK VALUE:</b>
                  </td>
                  <td className="text-end">
                    <b>{new Intl.NumberFormat().format(data?.stockValue)}</b>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <LoadingModal
        title={"Please Wait"}
        onShow={loader}
        handleClose={handleLoaderClose}
      />
      <Toaster position="bottom-right" />
    </div>
  );
}

export default InventoryDetails;
