import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./Components/Pages/Home/Home";
import Login from "./Components/Pages/Login/Login";
import NotFound from "./Components/Pages/NotFound/NotFound";
import Dashboard from "./Components/Pages/DashBoard/Dashboard";
import POS from "./Components/Pages/POS/POS";
import DayBook from "./Components/Pages/DayBook/DayBook";
import Sales from "./Components/Sale/Sales";
import Customer from "./Components/Customer/Customer";
import Ecom from "./Components/Ecom/Pages/Ecom/Ecom";
import CustomerLedger from "./Components/Customer/CustomerLedger";
import DueSales from "./Components/Sale/DueSales";
import ExportArticleSale from "./Components/Sale/ExportArticleSale";
import CategorySale from "./Components/Sale/CategorySale";
import AllCategorySale from "./Components/Sale/AllCategorySale";
import AccountHeads from "./Components/AccountHeads/AccountHeads";
import AddAccountHead from "./Components/AccountHeads/AddAccountHead";
import UpdateAccountHead from "./Components/AccountHeads/UpdateAccountHead";
import Accounts from "./Components/Accounts/Accounts";
import Payments from "./Components/Accounts/payments";
import AddAccount from "./Components/Accounts/AddAccount";
import AddAccountExpense from "./Components/Accounts/AddAccountExpense";
import AddAfterSale from "./Components/Accounts/AddAfterSale";
import UpdateAccount from "./Components/Accounts/UpdateAccount";
import Ledger from "./Components/Ledger/Ledger";
import AccountReports from "./Components/Accounts/AccountsReport/AccountReports";
import Products from "./Components/Product/Products";
import ImportPrice from "./Components/Price/ImportPrice";
import ImportProduct from "./Components/Product/ImportProduct";
import AddProduct from "./Components/Product/AddProduct";
import UpdateProducts from "./Components/Product/UpdateProducts";
import Categories from "./Components/Category/Categories";
import AddCategory from "./Components/Category/AddCategory";
import UpdateCategory from "./Components/Category/UpdateCategory";
import Brand from "./Components/Brand/Brand";
import UpdateBrand from "./Components/Brand/UpdateBrand";
import Unit from "./Components/Unit/Unit";
import UpdateUnit from "./Components/Unit/UpdateUnit";
import Purchase from "./Components/Purchase/Purchase";
import PurchaseCreate from "./Components/Purchase/PurchaseCreate";
import PurchaseUpdate from "./Components/Purchase/PurchaseUpdate";
import GRN from "./Components/GRN/GRN";
import GRNCreate from "./Components/GRN/GRNCreate";
import PrintReceiptById from "./Components/Common/PrintReceipt/PrintReceiptById";
import CreateRtv from "./Components/RTV/CreateRtv";
import Rtv from "./Components/RTV/Rtv";
import TPNDetails from "./Components/TPN/TPNDetails";
import TPNSummary from "./Components/TPN/TPNSummary";
import Damage from "./Components/Damage/Damage";
import DamageCreate from "./Components/Damage/DamageCreate";
import Tpn from "./Components/TPN/Tpn";
import CreateTpn from "./Components/TPN/CreateTpn";
import DamageSummary from "./Components/Damage/DamageSummary";
import DamageDetails from "./Components/Damage/DamageDetails";
import CreateSupplier from "./Components/Supplier/CreateSupplier";
import SupplierLedger from "./Components/Supplier/SupplierLedger";
import UpdateSupplier from "./Components/Supplier/UpdateSupplier";
import Supplier from "./Components/Supplier/Supplier";
import Inventory from "./Components/Inventory/Inventory";
import ProductMovement from "./Components/Inventory/movement/ProductMovement";
import InventorySummary from "./Components/Inventory/InventorySummary";
import InventoryDetails from "./Components/Inventory/InventoryDetails";
import DeleteSale from "./Components/Sale/DeleteSale";
import InventoryExport from "./Components/Inventory/InventorySession/InventoryExport";
import InventoryImport from "./Components/Inventory/InventoryImport copy";
import InventoryAdjust from "./Components/Inventory/InventoryAdjust";
import Adjust from "./Components/Adjust/Adjust";
import CreateAdjust from "./Components/Adjust/CreateAdjust";
import Cogs from "./Components/Cogs/Cogs";
import Exports from "./Components/Exports/Exports";
import Profile from "./Components/Common/Profile/Profile";
import User from "./Components/User/User";
import AddUser from "./Components/User/AddUser";
import UpdateUser from "./Components/User/UpdateUser";
import UserDetails from "./Components/User/UserDetails";
import Warehouse from "./Components/Warehouse/Warehouse";
import SmsPage from "./Components/SMS/SmsPage";
import Settings from "./Components/Settings/Settings";
import UpdateWarehouse from "./Components/Warehouse/UpdateWarehouse";
import RequireAuth from "./Components/Utility/RequireAuth";
import GRNDetails from "./Components/GRN/GRNDetails";
import GRNSummary from "./Components/GRN/GRNSummary";
import GrnArticleWise from "./Components/GRN/GrnArticleWise";
import RTVSummary from "./Components/RTV/RTVSummary";
import RTVDetails from "./Components/RTV/RTVDetails";
import UpdateCustomer from "./Components/Customer/UpdateCustomer";
import EcomSaleProcess from "./Components/Ecom/Pages/EcomUpdate/EcomSaleProcess";
import EcomSaleUpdate from "./Components/Ecom/Pages/EcomUpdate/EcomSaleUpdate";
import EcomSaleDeliver from "./Components/Ecom/Pages/EcomUpdate/EcomSaleDeliver";
import SupplierProductSale from "./Components/Sale/SupplierProductSale";
import InventorySession from "./Components/Inventory/InventorySession/InventorySession";
function App() {
  //@ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Common */}
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />

        {/* REQUIRE AUTH */}
        {/* Dashboard */}
        <Route
          element={
            <RequireAuth
              allowRoles={[
                "POS",
                "admin",
                "manager",
                "supervisor",
                "accounts",
                "ecom",
              ]}
            />
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="inventory-session" element={<InventorySession/>}/>
        </Route>

        {/* DayBook */}
        <Route element={<RequireAuth allowRoles={["admin", "manager"]} />}>
          <Route path="daybook" element={<DayBook />} />
        </Route>

        {/* POS */}
        <Route element={<RequireAuth allowRoles={["POS", "admin"]} />}>
          <Route path="pos" element={<POS />} />
        </Route>

        {/* Print Reciept */}
        <Route
          element={
            <RequireAuth
              allowRoles={[
                "POS",
                "admin",
                "manager",
                "supervisor",
                "accounts",
                "ecom",
              ]}
            />
          }
        >
          <Route path="print/:id" element={<PrintReceiptById />} />
          {/* Profile Setting */}
          <Route path="profile" element={<Profile />} />
        </Route>

        {/* Sales */}
        <Route element={<RequireAuth allowRoles={["admin", "manager"]} />}>
          <Route path="sales" element={<Sales />} />
          <Route path="articleSaleExport" element={<ExportArticleSale />} />
          <Route path="categorySaleExport" element={<CategorySale />} />
          <Route path="allCategorySales" element={<AllCategorySale />} />
          <Route path="delete" element={<DeleteSale />} />
          {/* Customers */}
          <Route path="customer" element={<Customer />} />
          <Route path="customer/update/:id" element={<UpdateCustomer />} />
          {/* Customers-Ledger */}
          <Route path="customer-ledger" element={<CustomerLedger />} />
          {/* Due Bills */}
          <Route path="duesale" element={<DueSales />} />
          {/* Account-Head */}
        </Route>
        {/* Ecom sales */}
        <Route
          element={<RequireAuth allowRoles={["ecom", "admin", "manager"]} />}
        >
          <Route path="ecom" element={<Ecom />} />
          <Route
            path="/ecom/orderinfoprocess/:id"
            element={<EcomSaleProcess />}
          />
          <Route
            path="/ecom/orderinfoupdate/:id"
            element={<EcomSaleUpdate />}
          />

          <Route
            path="/ecom/orderinfodeliver/:id"
            element={<EcomSaleDeliver />}
          />
        </Route>

        {/* Accounts */}
        <Route element={<RequireAuth allowRoles={["admin", "accounts"]} />}>
          <Route path="accountheads" element={<AccountHeads />} />

          <Route path="addAccountHead" element={<AddAccountHead />} />
          <Route
            path="accountheads/update/:id"
            element={<UpdateAccountHead />}
          />
          <Route path="accounts" element={<Accounts />} />
          <Route path="payments" element={<Payments />} />
          <Route path="addAccount" element={<AddAccount />} />
          <Route path="addAccountExpense" element={<AddAccountExpense />} />
          <Route path="addAfterSale" element={<AddAfterSale />} />
          <Route path="account/update/:id" element={<UpdateAccount />} />
          <Route path="ledger" element={<Ledger />} />
          <Route path="acc_reports" element={<AccountReports />} />
        </Route>

        {/* Products */}
        <Route
          element={
            <RequireAuth allowRoles={["admin", "supervisor", "manager"]} />
          }
        >
          <Route path="product" element={<Products />} />
          <Route path="price/import" element={<ImportPrice />} />
          <Route path="product/import" element={<ImportProduct />} />
          <Route path="product/add" element={<AddProduct />} />
          <Route path="product/update/:id" element={<UpdateProducts />} />
          {/* Category */}
          <Route path="category" element={<Categories />} />
          <Route path="category/addCategory" element={<AddCategory />} />
          <Route path="category/update/:id" element={<UpdateCategory />} />
          {/* Brand */}
          <Route path="brand" element={<Brand />} />

          <Route path="brand/update/:id" element={<UpdateBrand />} />
          {/* Unit */}
          <Route path="unit" element={<Unit />} />

          <Route path="unit/update/:id" element={<UpdateUnit />} />
          {/* Purchase */}
          <Route path="purchase" element={<Purchase />} />
          <Route path="purchase-create" element={<PurchaseCreate />} />
          <Route path="purchase-update/:id" element={<PurchaseUpdate />} />
          {/* 
          <Route
          path="update-purchase/:id"
          element={
            
            <UpdatePurchase />
            
          }
          /> */}
          {/* GRN */}
          <Route path="grn" element={<GRN />} />
          <Route path="grn-create" element={<GRNCreate />} />
          <Route path="/grn-details" element={<GRNDetails />} />
          <Route path="/grn-summary" element={<GRNSummary />} />
          <Route path="/grn-article" element={<GrnArticleWise />} />

          {/* RTV */}
          <Route path="rtv" element={<Rtv />} />
          <Route path="rtv-create" element={<CreateRtv />} />
          <Route path="/rtv-summary" element={<RTVSummary />} />
          <Route path="/rtv-details" element={<RTVDetails />} />

          {/* TPN */}
          <Route path="tpn" element={<Tpn />} />
          <Route path="tpn-out" element={<CreateTpn />} />
          <Route path="tpn-details" element={<TPNDetails />} />
          <Route path="tpn-summary" element={<TPNSummary />} />
          {/* Damage */}
          <Route path="damage" element={<Damage />} />
          <Route path="damagecreate" element={<DamageCreate />} />
          <Route path="damage-summary" element={<DamageSummary />} />
          <Route path="damage-details" element={<DamageDetails />} />
          {/* Adjust */}
          <Route path="adjust" element={<Adjust />} />
          <Route path="create-adjust" element={<CreateAdjust />} />
          {/* Supplier */}
          <Route path="supplier" element={<Supplier />} />
          <Route path="create-supplier" element={<CreateSupplier />} />
          <Route path="supplier-ledger/:id" element={<SupplierLedger />} />
          <Route path="update-supplier/:id" element={<UpdateSupplier />} />
          {/* supplierSaleExport */}
          <Route path="/supplierSaleExport" element={<SupplierProductSale />} />
        </Route>

        {/* Inventories */}
        <Route element={<RequireAuth allowRoles={["admin", "manager"]} />}>
          <Route path="inventory" element={<Inventory />} />
          <Route path="inventory-details" element={<InventoryDetails />} />
          <Route path="inventory-summary" element={<InventorySummary />} />
          <Route path="inventory-export" element={<InventoryExport />} />
          <Route path="inventory-import" element={<InventoryImport />} />
          <Route path="inventory-adjust" element={<InventoryAdjust />} />
          {/* COGS */}
          <Route path="cogs" element={<Cogs />} />
          <Route path="product-movement" element={<ProductMovement />} />
          {/* Exports */}
          <Route path="exports" element={<Exports />} />

          {/* Users */}
          <Route path="user" element={<User />} />
          <Route path="user/add" element={<AddUser />} />
          <Route path="user/:id" element={<UserDetails />} />
          <Route path="user/update/:id" element={<UpdateUser />} />
          {/* SMS */}
          <Route path="sms" element={<SmsPage />} />
          {/* Store Setting */}
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route element={<RequireAuth allowRoles={["admin"]} />}>
          {/* Warehouse */}
          <Route path="warehouse" element={<Warehouse />} />
          <Route path="warehouse/update/:id" element={<UpdateWarehouse />} />
        </Route>
        {/* REQUIRE AUTH END */}

        {/* Not Found */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
