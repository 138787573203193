import React, { useState } from "react";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import DatePicker from "react-datepicker";
import { compareAsc, format } from "date-fns";
import SelectAccountHead from "../Common/CustomSelect/SelectAccountHead";
import SelectSubAccountHead from "../Common/CustomSelect/SelectSubAccountHead";
import { useAccountheadSubQuery } from "../../services/accountHeadApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as Icons from "heroicons-react";

const Ledger = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [accountHead, setAccountHead] = useState("");
  let i = 1;
  const data = [];

  const {
    data: achead,
    error,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useAccountheadSubQuery(`${accountHead}`);

  const handleAcHeadChange = (e) => {
    console.log(e);
    setAccountHead(e.option);
    refetch();
  };
  const handleSubAcHeadChange = (e) => {};
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Ledgers"></Header>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 mt-2 mb-2">
                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                      {/* <b>Start:</b> */}
                      <DatePicker
                        selected={new Date(startDate)}
                        className="form-control me-2"
                        onChange={(date) =>
                          setStartDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                      <span width="10px"></span>
                      {/* <b>End:</b> */}
                      <DatePicker
                        selected={new Date(endDate)}
                        className="form-control"
                        onChange={(date) =>
                          setEndDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>

                    {/* <div className="col-md-2 mt-2 mb-2">
                                <SelectSubAccountHead
                                    data={achead}
                                    error={error}
                                    isSuccess={isSuccess}
                                    handleOnchange={handleSubAcHeadChange}

                                ></SelectSubAccountHead>
                                 </div> */}
                  </div>
                  <div className="col-md-2 mt-3 mb-2">
                    <SelectAccountHead
                      handleOnchange={handleAcHeadChange}
                    ></SelectAccountHead>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">AC Code</th>
                      <th scope="col">Account Head</th>
                      {/* <th scope="col">Type</th> */}
                      {/* <th scope="col">Group</th> */}
                      <th scope="col">Amount</th>
                      <th scope="col">Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {isLoading && <h2>...Loading</h2>}
                {isFetching && <h2>...isFetching</h2>}
                {error && <h2>Something went wrong</h2>} */}
                    {data?.length > 0 ? (
                      data?.map((ac) => (
                        <tr key={ac?._id}>
                          <th scope="row">{i++}</th>
                          <td>{ac?.acId}</td>
                          <td>{ac?.supplier?.company}</td>
                          {/* <td>{ac?.paymentType}</td> */}
                          <td>{ac?.amount}</td>
                          <td>
                            <Icons.EyeOutline
                              // onClick={() => handleAccountsView(ac._id)}
                              className="icon-eye me-1"
                              size={20}
                            ></Icons.EyeOutline>
                            <Link to={`/ledger`}>
                              {/* <Icons.PencilAltOutline
                                                        className="icon-edit"
                                                        size={20}
                                                    ></Icons.PencilAltOutline> */}
                            </Link>
                            <Icons.TrashOutline
                              className="icon-trash"
                              size={20}
                              // onClick={() => deleteHandler(ac._id)}
                            ></Icons.TrashOutline>
                          </td>
                        </tr>
                      ))
                    ) : (
                      // <tr>
                      //   <td colSpan={6}>
                      //     {isLoading && <Skeleton count={100} />}
                      //     {/* {isFetching && <h2>...isFetching</h2>}
                      //     {error && <h2>Something went wrong</h2>} */}
                      //   </td>
                      // </tr>
                      <tr colSpan={9}>No Ledger Found</tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ledger;
