export const salesRoutes = [
  {
    label: "Sale",
    icon: "Table",
    url: "/sales",
  },
  {
    label: "Article Sale",
    icon: "Table",
    url: "/articleSaleExport",
  },
  {
    label: "Category Sale",
    icon: "Table",
    url: "/categorySaleExport",
  },
  {
    label: "All Category Sum",
    icon: "Table",
    url: "/allCategorySales",
  },
  // {
  //   label: "Details Sale",
  //   icon: "Table",
  //   url: "/sale-expand",
  // },
];

export const GrnRoutes = [
  {
    label: "Grn",
    icon: "ArrowBackOutline",
    url: "/grn",
  },
  {
    label: "Grn Details",
    icon: "Table",
    url: "/grn-details",
  },
  {
    label: "Grn Summary",
    icon: "Table",
    url: "/grn-summary",
  },
  {
    label: "Grn Article-Wise",
    icon: "Table",
    url: "/grn-article",
  },
];

export const RtvRoutes = [
  {
    label: "RTV",
    icon: "Table",
    url: "/rtv",
  },
  {
    label: "RTV Details",
    icon: "Table",
    url: "/rtv-details",
  },
  {
    label: "RTV Summary",
    icon: "Table",
    url: "/rtv-summary",
  },
];

export const TpnRoutes = [
  {
    label: "TPN",
    icon: "Table",
    url: "/tpn",
  },
  {
    label: "TPN Details",
    icon: "Table",
    url: "/tpn-details",
  },
  {
    label: "TPN Summary",
    icon: "Table",
    url: "/tpn-summary",
  },
];

export const DmgRoutes = [
  {
    label: "Damage",
    icon: "Table",
    url: "/damage",
  },
  {
    label: "Damage Details",
    icon: "Table",
    url: "/damage-details",
  },
  {
    label: "Damage Summary",
    icon: "Table",
    url: "/damage-summary",
  },
];

export const InventoryRoutes = [
  {
    label: "Inventory",
    icon: "Table",
    url: "/inventory",
  },
  {
    label: "Inventory Details",
    icon: "Table",
    url: "/inventory-details",
  },
  {
    label: "Inventory Summary",
    icon: "Table",
    url: "/inventory-summary",
  },
];
export const ProductRoutes = [
  {
    label: "ledger",
    icon: "Table",
    url: "/product/ledger",
  },
];
