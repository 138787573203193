import React, { useEffect, useState } from "react";
import * as Icons from "heroicons-react";
import {
  itemTotal,
  itemTax,
  toDecimal,
} from "../../Utility/PurchaseCalculations";
import { BsArchive, BsCheckSquare, BsSquare } from "react-icons/bs";
import { useInventoryByArticleQuery } from "../../../services/inventoryApi";
import axios from "axios";
import SupplierProductDetail from "./SupplierProductDetail";
// import { createDispatchHook } from 'react-redux';

const SupplierProduct = ({ productList, localStorageAddFromCart }) => {
  // console.log(productList);
  // const { data, error, isSuccess } = useInventoryByArticleQuery()
  // const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  // console.log(productList);
  return (
    <>
      {productList?.length > 0 ? (
        productList?.map((cartItem, index) => (
          // cartItem.quantity=tempQty;
          <SupplierProductDetail
            cartItem={cartItem}
            index={index}
            localStorageAddFromCart={localStorageAddFromCart}
          ></SupplierProductDetail>
        ))
      ) : (
        <tr>
          <th scope="row" colSpan="4">
            <p className="text-center">No Item in Purchase List</p>
          </th>
        </tr>
      )}
    </>
  );
};

export default SupplierProduct;
