import React from "react";
import md5 from "md5";
import { Navigate, useNavigate } from "react-router-dom";

const hash = (str) => {
  const pass = `${str}${process.env.REACT_APP_HASH_SECRET}`;
  return md5(pass);
};

// handleLogOut
const handleLogout = (e) => {
  e.preventDefault();

  localStorage.clear();
  return <Navigate to="/login" replace />;
};

const signInUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  } else {
    return null; // or any default value you want to return when user data is not available
  }
};

export { hash, handleLogout, signInUser };
