import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { CSVLink, CSVDownload } from "react-csv";
// import ExportSales from "..x/Common/Modal/ExportSales";
// import ExportArticleSales from "../Common/Modal/ExportArticleSales";
import useInventory from "../Hooks/useInventory";
import axios from "axios";
import {
  useSaleByDateQuery,
  useDeleteSaleMutation,
  useSaleExportByDateQuery,
  useSaleArticelExportByDateQuery,
} from "../../services/saleApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import BulierSelcet from "../Common/CustomSelect/BillerSelect";
import { Helmet } from "react-helmet";
import ExportNav from "../Common/Nav/ExportNav";
import { salesRoutes } from "../../data";
import LoadingModal from "../Common/Modal/LoadingModal";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

const ExportArticleSale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [q, setQ] = useState("");
  const [warehouse, setWarehouse] = useState("allWh");
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleArticelExportByDateQuery({
      startDate,
      endDate,
      warehouse,
      q,
    });

  // console.log(startDate, endDate, data);
  const [articleSale, setArticleSale] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [totalSalesVat, setTotalSalesVat] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalVat, setTotalVat] = useState(0);

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading, isFetching]);

  const auth = signInUser();

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse, q]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  // console.log("warehouse", startDate, endDate, warehouse);

  const totalAmount = (mrp, qty) => {
    let amount = 0;
    amount = parseFloat(mrp) * parseFloat(qty);
    return amount;
  };
  const vatTotal = (mrp, qty, vat) => {
    let vatAmount = 0;
    if (vat !== 0) {
      // console.log(mrp, qty, vat / 100);
      vatAmount = parseFloat(mrp) * parseFloat(qty) * (parseFloat(vat) / 100);
    }
    // console.log(vatAmount);
    return vatAmount;
  };
  const eachDiscount = (mrp, qty, discount, total) => {
    let discountAmount = 0;
    if (discount !== 0) {
      let itemTotal = parseFloat(mrp) * parseFloat(qty);
      discountAmount = itemTotal * (parseFloat(discount) / parseFloat(total));
      // console.log("discount:", mrp, qty, itemTotal, discountAmount);
    }
    return discountAmount;
  };

  useEffect(() => {
    let articleSales = [];
    let totalSaleVat = 0;
    let totalSale = 0;
    let totalVat = 0;
    data?.map((sale) =>
      sale?.products?.map((product) => {
        // console.log(product);
        articleSales = [
          ...articleSales,
          {
            invoice_no: sale?.invoiceId,
            date:
              sale?.createdAt &&
              format(new Date(sale?.createdAt), "MM/dd/yyyy"),
            // Product Loop
            code: product?.article_code,
            name: product?.name,
            tp: product?.tp,
            mrp: product?.mrp,
            qty: product?.qty,
            promo: product?.promo?.promo_price,
            vat: vatTotal(product?.mrp, product?.qty, product?.vat),
            discount: eachDiscount(
              product?.mrp,
              product?.qty,
              sale?.discount,
              sale?.total
            ),
            total: totalAmount(product?.mrp, product?.qty),
            grossTotal:
              totalAmount(product?.mrp, product?.qty) +
              vatTotal(product?.mrp, product?.qty, product?.vat) -
              (parseFloat(
                eachDiscount(
                  product?.mrp,
                  product?.qty,
                  sale?.discount,
                  sale?.total
                )
              ) +
                parseFloat(product?.promo?.promo_price)),
          },
        ];
        totalSaleVat =
          totalSaleVat +
          totalAmount(product?.mrp, product?.qty) +
          vatTotal(product?.mrp, product?.qty, product?.vat) -
          (eachDiscount(
            product?.mrp,
            product?.qty,
            sale?.discount,
            sale?.total
          ) +
            parseFloat(product?.promo?.promo_price));
        totalSale = totalSale + totalAmount(product?.mrp, product?.qty);
        totalVat =
          totalVat + vatTotal(product?.mrp, product?.qty, product?.vat);
      })
    );

    // console.log("product", articleSales);
    setArticleSale(articleSales);
    setTotalSalesVat(totalSaleVat);
    setTotalSales(totalSale);
    setTotalVat(totalVat);
  }, [isSuccess, isFetching]);

  // let exportData = [];
  useEffect(() => {
    if (articleSale.length > 0) {
      let expD = articleSale;
      setExportData(expD);
    }
  }, [articleSale]);

  const handleSearch = (e) => {};
  const filterByArticleCode = (code) => {
    setQ(code);
    refetch();
  };

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-Sale-Export</title>
      </Helmet>

      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Article Sales"></Header>
            <div className="row mt-3">
              {/* Date Range, Article Code, and Warehouse Filter */}
              <div className="col-md-5">
                <div className=" mb-2">
                  <div className="d-flex align-items-center gap-2">
                    {/* Start Date */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control"
                      placeholderText="Start Date"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                      // style={{ width: "300px" }} // Consistent width for inputs
                    />

                    {/* End Date */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      placeholderText="End Date"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                      // style={{ width: "300px" }}
                    />
                  </div>

                  <div className="d-flex align-items-center gap-2 mt-2">
                    {/* Article Code */}
                    <input
                      type="text"
                      name="product"
                      className="form-control"
                      placeholder="Article Code"
                      onChange={(e) => filterByArticleCode(e.target.value)}
                      style={{ width: "200px" }}
                    />

                    {/* Warehouse Dropdown (Visible for Admins Only) */}
                    {auth?.type === "admin" && (
                      <div style={{ width: "215px" }}>
                        <WareHouseDW
                          id="warehouse"
                          name="warehouse"
                          className="form-control"
                          handleOnChange={handleOnchangeWareHouseFrom}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Export Navigation */}
              <div className="col-md-7 d-flex justify-content-end align-items-start">
                <ExportNav
                  Routes={salesRoutes}
                  data={exportData}
                  title="Article Sale"
                />
              </div>
            </div>

            {/* <Link to="/category-sales" className="btn btn-dark float-end my-2  mx-2">Category Sales </Link> */}
            <Table hover bordered striped className="mt-3">
              <thead>
                <tr>
                  <th scope="col">Invoice ID</th>
                  <th scope="col">Date</th>
                  <th scope="col" className="text-center">
                    Code
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col" className="text-center">
                    TP
                  </th>
                  <th scope="col" className="text-center">
                    MRP
                  </th>
                  <th scope="col" className="text-center">
                    Qty
                  </th>
                  <th scope="col" className="text-center">
                    Dis
                  </th>
                  <th scope="col" className="text-center">
                    Pro
                  </th>
                  <th scope="col" className="text-center">
                    VAT
                  </th>
                  <th scope="col" className="text-end">
                    Total
                  </th>
                  <th scope="col" className="text-end">
                    GrossTotal
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(exportData)} */}
                {exportData ? (
                  exportData.map((product) => (
                    <tr key={product?.invoice_no}>
                      {/* <th >{i++}</th> */}
                      <th scope="row">{product?.invoice_no}</th>
                      <td>{product?.date}</td>
                      <td className="text-center">{product?.code}</td>
                      <td>{product?.name}</td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(
                          parseFloat(product?.tp).toFixed(2)
                        )}
                      </td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(
                          parseFloat(product?.mrp).toFixed(2)
                        )}
                      </td>
                      <td className="text-center">
                        {new Intl.NumberFormat().format(
                          parseFloat(product?.qty).toFixed(3)
                        )}
                      </td>
                      <td>{product?.discount.toFixed(2)}</td>
                      <td>{product?.promo}</td>
                      <td className="text-end">
                        {new Intl.NumberFormat().format(
                          parseFloat(product?.vat).toFixed(2)
                        )}
                      </td>
                      <td className="text-end">
                        {new Intl.NumberFormat().format(
                          product?.total.toFixed(2)
                        )}
                      </td>
                      {/* <td>{sale.totalReceived}</td>
                       */}

                      <td className="text-end">
                        {product?.grossTotal.toFixed(2)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>Loading...</td>
                  </tr>
                )}
                {
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(totalVat).toFixed(2)
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(totalSales).toFixed(2)
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat().format(
                          parseFloat(totalSalesVat).toFixed(2)
                        )}
                      </b>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>

            {}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportArticleSale;
