import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import SupplierSelectByProduct from "../CustomSelect/SupplierSelectByProduct";
import WareHouseDW from "../CustomSelect/WareHouseDW";
import {
  useAddPriceMutation,
  usePriceByProductQuery,
  useUpdatePriceMutation,
  useDeletePriceMutation,
  useUpdateEditPriceMutation,
  useCreateInventoryMutation,
  useAsyncPriceMutation,
} from "../../../services/priceApi";
import * as Icons from "heroicons-react";
import { notify } from "../../Utility/Notify";
import LoadingModal from "./LoadingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductPrice,
  handleMrp,
  handleRemove,
  handleSupplier,
  handleTp,
  handleWarehouse,
  productPriceList,
} from "../../../features/ProductpriceListSlice";
import { Toaster } from "react-hot-toast";
// import { selectIsUpdate, selectPriceId } from "../../../features/priceUpdateSlice";

const ProductPriceModal = ({ show, handleClose, productId }) => {
  const dispatch = useDispatch();
  const priceTable = useSelector((state) => state.productPriceListReducer);
  const [addPrice] = useAddPriceMutation();
  const [updatePrice] = useUpdatePriceMutation();
  const [createPriceInventory] = useCreateInventoryMutation();
  const [asyncPrice] = useAsyncPriceMutation();

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);
  let i = 1;

  // const [priceTable, setPriceTable] = useState([]);

  const { data, isLoading, isSuccess, refetch } =
    usePriceByProductQuery(productId);

  useEffect(() => {
    if (data) {
      dispatch(productPriceList([]));
      setLoader(true);
      refetch();
    }
  }, [productId]);

  useEffect(() => {
    if (data) {
      dispatch(productPriceList(data.priceTable));
      setLoader(false);
    }
  }, [isSuccess, data]);

  // console.log("Data", data);
  // console.log("Price", priceTable);

  // OPERATIONS
  //onSyncPrice
  const onSyncPrice = () => {
    const sync = asyncPrice({ productId: productId });

    if (sync) {
      notify("Product Sync with Price Successfully", "success");
    }
  };

  //handleVendorChange
  const handleVendorChange = (index, e) => {
    // console.log("e", e, "index,", index);
    dispatch(
      handleSupplier({
        supplier: e.option,
        index: index,
      })
    );
  };

  //handleCustomTp
  const handleCustomTp = (e, index) => {
    console.log(e.target.value);
    // TODO:: Float Value does not count
    dispatch(
      handleTp({
        tp: e.target.value,
        index: index,
      })
    );
  };

  //handleCustomMrp
  const handleCustomMrp = (e, index) => {
    console.log(e.target.value);

    // TODO:: Float Value does not count
    dispatch(
      handleMrp({
        mrp: e.target.value,
        index: index,
      })
    );
  };

  //handleWhChange
  const handleWhChange = (e) => {
    // console.log("e", e, "name,", name);

    dispatch(
      handleWarehouse({
        warehouse: e?.option,
        index: e.index,
      })
    );
  };

  //addNewPrice
  const addNewPrice = (item) => {
    let priceData = { article_code: productId, ...item };

    if (item.supplier !== "" && item.warehouse !== "") {
      const createNewPrice = addPrice(priceData);
      if (createNewPrice) {
        refetch();
        notify("New Price Created", "success");
      } else {
        console.log(priceData);
      }
    } else {
      console.log("Supplier and Warehouse Should not be empty!");
    }
  };

  //removeAddNew
  const removeAddNew = (index) => {
    // console.log(index);

    dispatch(handleRemove(index));
  };

  //handleAddPrice
  const handleAddPrice = () => {
    dispatch(addProductPrice());
  };

  //handlePriceStatus
  const handlePriceStatus = (item) => {
    const data = {
      _id: item._id,
      status: item.status === "active" ? "inactive" : "active",
    };
    const update = updatePrice(data);
    if (update) {
      refetch();
      notify("Status Change Successfull.", "success");
    }
  };

  //createInventory
  const createInventory = (id) => {
    setLoader(true);
    const priceData = {
      article_code: data.article_code,
      priceId: id,
    };

    const create = createPriceInventory(priceData);
    if (create) {
      notify("create Inventory SuccessFull", "success");
      refetch();
      setLoader(false);
    }
  };

  //handleUpdatePrice
  const handleUpdatePrice = (item) => {
    console.log(item);
    const update = updatePrice(item);
    if (update) {
      notify("Price Update Success", "success");
      refetch();
    }
  };

  return (
    <>
      <LoadingModal
        title={"Please Wait"}
        onShow={loader}
        handleClose={handleLoaderClose}
      ></LoadingModal>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className=""
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Price Table</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <div className="mx-auto">
            {/* <Card className="text-center"> */}
            <div className="">
              <p className="">
                <b>Product Name: </b>
                {data && data?.name}
              </p>
              <div className="d-flex justify-content-between">
                <span>
                  <b>EAN:</b> {data && data?.ean}
                </span>
                <span>
                  <b>Category:</b> {data && data?.category}
                </span>
                <span>
                  <b>MC:</b> {data && data?.master_category}
                </span>
                <span>
                  <b>Code:</b> {data && data?.article_code}
                </span>
              </div>
              <hr />
              <Table>
                <thead className="container">
                  <tr className="row m-0">
                    <th className="col-md-1 text-center">#</th>
                    <th className="col-md-2 text-center">Vendor</th>
                    <th className="col-md-2 text-center">WH</th>
                    <th className="col-md-2 text-center">TP</th>
                    <th className="col-md-2 text-center">MRP</th>
                    <th className="col-md-1 text-center">Stock</th>
                    <th className="col-md-2 text-end">Actions</th>
                  </tr>
                </thead>
                <tbody className="container">
                  {priceTable.length > 0 &&
                    priceTable.map((item, index) => (
                      <tr className="row m-0" key={index}>
                        <th className="col-md-1 text-center">{i++}</th>
                        <td className="col-md-2">
                          <SupplierSelectByProduct
                            article_code={data && data?.article_code}
                            handleOnChange={handleVendorChange}
                            className={item.order}
                            name={index}
                            value={item?.supplier}
                          ></SupplierSelectByProduct>
                        </td>
                        <td className="col-md-2">
                          <WareHouseDW
                            handleOnChange={handleWhChange}
                            name={index}
                            warehouse={item?.warehouse}
                          />
                        </td>
                        <td className="col-md-2">
                          <input
                            className="form-control"
                            onChange={(e) => handleCustomTp(e, index)}
                            value={parseFloat(item?.tp)}
                            defaultValue={item?.tp}
                            type="number"
                          />
                        </td>
                        <td className="col-md-2">
                          <input
                            className="form-control"
                            onChange={(e) => handleCustomMrp(e, index)}
                            value={parseFloat(item?.mrp)}
                            defaultValue={item?.mrp}
                            type="number"
                          />
                        </td>
                        <td className="col-md-1 text-center">
                          {item.stock !== null
                            ? parseFloat(item.stock).toFixed(2)
                            : 0}
                        </td>
                        <td className="col-md-2 text-end">
                          <div className="d-flex justify-content-center align-items-center">
                            <Form.Check
                              type="switch"
                              checked={
                                item?.status === "active" ? "checked" : ""
                              }
                              // disabled={item.stock === null && "disabled"}
                              // id="custom-switch"
                              onChange={() => handlePriceStatus(item)}
                              label=""
                            />

                            {item?._id ? (
                              <>
                                <Icons.CheckCircleOutline
                                  className="icon-eye"
                                  // disabled
                                  color={"green"}
                                  onClick={() => handleUpdatePrice(item)}
                                ></Icons.CheckCircleOutline>
                                {item.stock !== null ? (
                                  <Icons.Check
                                    title={"Inventory"}
                                    className="icon-eye"
                                    color={"green"}
                                    // disabled
                                    // onClick={() => removeItem(item)}
                                  ></Icons.Check>
                                ) : (
                                  <Icons.ArchiveOutline
                                    title={"No Inventory"}
                                    className="icon-edit"
                                    color={"tomato"}
                                    // disabled
                                    onClick={() => createInventory(item._id)}
                                  ></Icons.ArchiveOutline>
                                )}
                              </>
                            ) : (
                              <>
                                <Icons.CheckCircleOutline
                                  className="icon-eye pr-3 text-gray hover:text-success"
                                  // disabled
                                  onClick={() => addNewPrice(item)}
                                ></Icons.CheckCircleOutline>
                                <Icons.XCircleOutline
                                  className="icon-trash text-gray hover:text-danger"
                                  // disabled
                                  onClick={() => removeAddNew(index)}
                                ></Icons.XCircleOutline>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}

                  {/* <td>BDT</td> */}
                </tbody>
              </Table>
            </div>
            {/* </Card> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark float-end"
            onClick={() => handleAddPrice(productId)}
          >
            + New Price
          </button>
          <Button onClick={onSyncPrice} variant="dark">
            <Icons.RefreshOutline></Icons.RefreshOutline>
            Sync Price
          </Button>
          <Button onClick={handleClose} variant="dark">
            Close
          </Button>
          <Toaster position="bottom-right" />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductPriceModal;
