import axios from "axios";
import Reaxt, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import useSaleByInvoiceQuery from "../../../services/saleApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import * as Icons from "heroicons-react";
import { posFinalizer } from "../../Utility/PosCalculations";
import { useDispatch, useSelector } from "react-redux";
import {
  saleReturnProducts,
  saleReturnInfo,
  saleReturnQty,
  // saleReturnCal,
} from "../../../features/posSlice";
import { Link } from "react-router-dom";

const GroupModal = ({
  title,
  onShow,
  handleClose,
  returnProducts,
  setReturnProducts,
  // reCal,
  // setReCal,
  updateCart,
  handleReturnCustomerSelect,
  invoice,
  setInvoice,
}) => {
  const posSaleData = useSelector((state) => state.posReducer);
  const [returnInvoice, setReturnInvoice] = useState({});
  const dispatch = useDispatch();

  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  const handleChangeInvoiceId = async (e) => {
    const id = e.target.value;
    const sale = await axios
      .get(`${BASE_URL}sale/invoice/${id}`)
      .then((res) => {
        console.log("return:", res.data);
        setReturnInvoice(res.data);
        // ReturnInvoice
        dispatch(
          saleReturnInfo({
            // returnInvoice
            returnInvoice: res.data._id,
            // Customer Info
            customerId: res.data.customerId._id,
            customerPhone: res.data.customerId.phone,
            customerName: res.data.customerId.name,
            customerPoint: res.data.customerId.point,
          })
        );
        dispatch(saleReturnProducts([]));
        setInvoice(res.data);
        setReturnProducts([]);
      });
  };

  useEffect(() => {
    // updateCart();
  }, [posSaleData.returnProducts]);

  // console.log(reCal);

  const handleAddToggleProduct = (e, product) => {
    const seleted = returnProducts.filter(
      (item) => item.article_code === product.article_code
    );
    const rest = returnProducts.filter(
      (item) => item.article_code != product.article_code
    );
    let returnList = [];
    if (e.target.checked) {
      returnList = [product, ...rest];
    } else {
      returnList = rest;
    }
    dispatch(saleReturnProducts(returnList));
    setReturnProducts(returnList);
  };

  const handleCancel = () => {
    setReturnProducts([]);
    setInvoice({});
    handleClose();
  };

  return (
    <Modal size="lg" show={onShow} onHide={handleClose}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            {"Group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={invoice?.customerName || ""}
                  // disabled
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Discount"
                  value={invoice?.discount || 0}
                  onChange={(e) => {
                    const updatedInvoice = {
                      ...invoice,
                      discount: e.target.value,
                    };
                    setInvoice(updatedInvoice);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={invoice?.status || "Pending"}
                  onChange={(e) => {
                    const updatedInvoice = {
                      ...invoice,
                      status: e.target.value,
                    };
                    setInvoice(updatedInvoice);
                  }}
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-dark"
              // onClick={() => handleGroup()}
              style={{
                borderRadius: "0.5rem",
              }}
            >
               Submit
            </Button>
          </div>
          <hr />
          <div className="">
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="text-end">
                    Action
                  </th>
                </tr>
              </thead>
              {/* <tbody>
      {isLoading ? (
        <tr>
          <td colSpan={6} className="text-center">
            Loading data...
          </td>
        </tr>
      ) : error ? (
        <tr>
          <td colSpan={6} className="text-center text-danger">
            Error fetching data. Please try again later.
          </td>
        </tr>
      ) :sale?.length > 0 ? (
        sale?.map((Customer, index) => (
          <tr key={Customer._id}>
            <th scope="row">{index + 1}</th>
            <td>{Customer.name}</td>
            <td>{Customer.phone ? Customer.phone : "No Phone"}</td>
            <td>{Customer.type}</td>
            <td>{Customer.point}</td>
            <td className="text-center">
              <Link to={`/customer/update/${Customer?._id}`}>
                <Icons.PencilAltOutline
                  className="icon-edit"
                  size={20}
                ></Icons.PencilAltOutline>
              </Link>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6} className="text-center">
            No customers found.
          </td>
        </tr>
      )}
    </tbody> */}
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="dark" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GroupModal;
