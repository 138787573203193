import React from "react";
import AccountSideBar from "../../Common/SideBar/AccountSideBar";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import { Link } from "react-router-dom";

const AccountReports = () => {
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Reports"></Header>
            <div className="row mt-3">
              <div className="col-3">
                <p className="text-center">
                  <h1>ICON</h1>
                </p>
                <Link className="btn-dark btn btn-block mx-4" to="/report">
                  Stock
                </Link>
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountReports;
