import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import {
  useSalesWeeklyQuery,
  useSaleTotalQuery,
} from "../../../../services/saleApi";
import {
  useTodayGrnsQuery,
  useWeeklyGrnsQuery,
} from "../../../../services/grnApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const PieChart = ({ sale, cogs, lossProfit }) => {
  const data = {
    labels: ["Sales", "Cogs", "Profit"],
    datasets: [
      {
        label: "Loss - Profit",
        data: [sale, cogs, lossProfit],
        backgroundColor: ["rgb(54, 162, 235)", "#ed3833", "rgb(255, 205, 86)"],
        hoverOffset: 4,
      },
    ],
    maintainAspectRatio: true,
    // responsive: true
  };
  return (
    <>
      <div style={{ height: `230px`, width: `230px`, margin: `0 auto` }}>
        <Doughnut data={data} />
      </div>
    </>
  );
};

export default PieChart;
