import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
import SelectHoldSale from "../../../Common/CustomSelect/SelectHoldSale";
// import { FontAwesomeIcon } from "@react";

const PosFooter = ({
  emptyCart,
  LastBillId,
  handleHoldSale,
  holdSale,
  handleReturn,
  handlePayDue,
  updateCartState,
  salesRerurnButton,
  holdBillButton,
  lastBillButton,
}) => {
  const holdCart = JSON.parse(localStorage.getItem("hold_cart"));
  const holdLength = holdCart?.length;

  return (
    <footer className="fixed-bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <ButtonGroup aria-label="Basic">
                {/* <Button variant="info" >
                        Sales 
                        <Icons.ClipboardListOutline className='ms-3'  size={18}/>         
                  </Button> */}
                <Button
                  variant="dark d-block"
                  ref={salesRerurnButton}
                  onClick={handleReturn}
                >
                  Return
                  <Icons.ReplyOutline className="ms-3" size={18} />
                  {/* <Icons.XOutline  className='ms-3' size={18}/> */}
                </Button>
                {/* <Button variant="secondary"> */}
                {holdLength > 0 && (
                  <SelectHoldSale updateCartState={updateCartState} />
                )}
                {/* </Button> */}
                <Button
                  variant="warning"
                  onClick={handleHoldSale}
                  ref={holdBillButton}
                >
                  {holdLength > 0 && `(${holdLength})`} Hold
                  <Icons.HandOutline className="ms-3" size={18} />
                </Button>
                <Button variant="success" onClick={emptyCart}>
                  Refresh
                  <Icons.Refresh className="ms-3" size={18} />
                </Button>
                <Button
                  variant="dark d-block"
                  ref={salesRerurnButton}
                  onClick={handlePayDue}
                >
                  Pay Due Bill
                  <Icons.CurrencyBangladeshi className="ms-3" size={18} />
                  {/* <Icons.XOutline  className='ms-3' size={18}/> */}
                </Button>
                <Link
                  variant="primary"
                  className="btn btn-primary"
                  to={`/print/${LastBillId ? LastBillId : "43587136450871"}`}
                  target="_blank"
                  ref={lastBillButton}
                >
                  Last Bill
                  <Icons.PrinterOutline className="ms-3" size={18} />
                </Link>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PosFooter;
