import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Inventory.css";
import * as Icons from "heroicons-react";
import { Table, Button } from "react-bootstrap";
import {
  useInventoriesQuery,
  useInventoryCountQuery,
} from "../../services/inventoryApi";
import ReactPaginate from "react-paginate";
import InventoryViewModal from "../Common/Modal/InventoryViewModal";
import InventoryExport from "./InventorySession/InventoryExport";
import ExportInventory from "../Common/Modal/ExportInventory";
import LoadingModal from "../Common/Modal/LoadingModal";
import { IconName } from "react-icons/fi";
import axios from "axios";
import ExportNav from "../Common/Nav/ExportNav";
import { InventoryRoutes } from "../../data";
import { signInUser } from "../Utility/Auth";
// import useInventory from '../Hooks/useInventory';
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
const Inventory = () => {
  let i = 1;

  // const [inventories, setInventories] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [onShow, setOnShow] = useState(false);
  const [onExportShow, setOnExportShow] = useState(false);
  const [code, setCode] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);
  const auth = signInUser();

  const handleClose = () => {
    setOnShow(false);
  };
  const handleExportClose = () => setOnExportShow(false);

  const { data, error, isSuccess, refetch } = useInventoriesQuery({
    pageNo,
    size,
    warehouse,
    q,
  });
  // console.log(pageNo);
  // console.log(size);
  // console.log(q);
  // console.log(data);

  useEffect(() => {
    refetch();
  }, [warehouse]);

  useEffect(() => {
    setWarehouse(auth?.warehouse);
  }, [auth]);
  
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  // get totel product count
  const pageCountQuery = useInventoryCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  // console.log("data", data);
  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  // console.log(pageNo);
  const handlePageClick = (data) => {
    setPageNo(parseInt(data.selected) + 1);
    console.log(data);
    // setPageNo(getPageNumber);
    refetch();
  };

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    // setPageNo(getPageNumber);
    refetch();
  };

  const handleViewInventory = (code) => {
    setCode(code);
    setOnShow(true);
  };

  console.log("code", code);
  // useEffect(() => {}, [code]);

  const handelExportModal = () => {
    // console.log("hello");
    setOnExportShow(true);
  };
  // console.log(data, error, isSuccess, pageNo, size, q);
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Products Inventory"></Header>
            <div className="row mt-3">
              <div className="col-md-5">
                <form action="javasctipt:void(0)">
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>
                {/* Pagenation */}

                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={
                      size < 100
                        ? 1
                        : parseInt(
                            Math.ceil(parseInt(pageCount) / parseInt(size))
                          )
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
              </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    data={data}
                    title="Inventory"
                    Routes={InventoryRoutes}
                  />
                </span>
              </div>
            </div>
            <Table hover striped bordered>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {/* <th scope="col">Product Name</th> */}
                  <th scope="col">Article Code</th>
                  <th scope="col">Name</th>
                  {/* <th scope="col">MRP</th> */}
                  <th scope="col">Opening Quantity</th>
                  <th scope="col">Current Quantity</th>
                  <th scope="col">Total Quantity</th>
                  <th scope="col">Total Sold</th>
                  <th scope="col">Total SoldReturn</th>
                  <th scope="col">Total Damage</th>
                  <th scope="col">Total RTV</th>
                  <th scope="col">Total TPN</th>
                  <th scope="col">Total Adjust</th>
                  <th scope="col">Stock Value</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  // console.log("data", data)
                }
                {data?.length > 0 ? (
                  data?.map((inventory) => (
                    <tr key={inventory?._id}>
                      <td>{i++}</td>
                      <td>{inventory?.article_code}</td>
                      <td>{inventory?.name?.substring(0, 30)}...</td>
                      <td>{parseFloat(inventory?.openingQty)?.toFixed(2)}</td>
                      <td>{parseFloat(inventory?.currentQty)?.toFixed(2)}</td>
                      <td>{parseFloat(inventory?.totalQty)?.toFixed(2)}</td>
                      <td>{parseFloat(inventory?.soldQty)?.toFixed(2)}</td>
                      <td>
                        {inventory?.soldReturnQty
                          ? inventory?.soldReturnQty?.toFixed(2)
                          : (0).toFixed(2)}
                      </td>
                      <td>{inventory?.damageQty?.toFixed(2)}</td>
                      {/* {console.log(inventory)} */}
                      <td>{inventory?.rtvQty?.toFixed(2)}</td>
                      <td>
                        {inventory?.tpnQty
                          ? inventory?.tpnQty?.toFixed(2)
                          : (0).toFixed(2)}
                      </td>
                      <td>
                        {inventory?.adjustQty
                          ? inventory?.adjustQty?.toFixed(2)
                          : (0).toFixed(2)}
                      </td>
                      <td>
                        {inventory?.prices?.tp
                          ? (
                              parseFloat(inventory?.prices?.tp) *
                              parseFloat(inventory?.currentQty)
                            ).toFixed(2)
                          : 0}{" "}
                        ৳
                      </td>
                      <td className="nowrap">
                        <Icons.EyeOutline
                          onClick={() => handleViewInventory(inventory?._id)}
                          className="icon-eye me-1"
                          size={20}
                        ></Icons.EyeOutline>
                        {inventory?.currentQty > 0 ? (
                          <small className="text-success">In Stock</small>
                        ) : (
                          <small className="text-danger">No Stock</small>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>No Inventory</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <InventoryViewModal
        onShow={onShow}
        handleClose={handleClose}
        code={code}
      />
      <ExportInventory
        pageCountQuery={pageCountQuery?.data}
        onShow={onExportShow}
        handleClose={handleExportClose}
      ></ExportInventory>
    </div>
  );
};

export default Inventory;
