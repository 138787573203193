import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { useRtvDetailsQuery } from "../../services/rtvApi";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { useDamageDetailsQuery } from "../../services/damageApi";
import { DmgRoutes } from "../../data";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

function DamageDetails() {
  // Date
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  //   const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [exportData, setExportData] = useState([]);
  const [warehouse, setWarehouse] = useState("allWh");
  const auth = signInUser();

  const [loader, setLoader] = useState(true);

  const { data, isSuccess, refetch } = useDamageDetailsQuery({
    startDate,
    endDate,
    warehouse
  });

  useEffect(() => {
    setLoader(false);
    if (data?.products?.length > 0) {
      setExportData(data?.products);
    }
  }, [isSuccess, data]);

  //warehouse set
  useEffect(() => {
    refetch();
  }, [startDate, endDate ,warehouse]);

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);
  const handleLoaderClose = () => setLoader(false);
  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
    // console.log('handle data:', e)
    
    // console.log('')
    // refetch();
  };
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Damage Details by Article"></Header>
            <div className="row mt-3">
              <div className="col-md-5">
                {/* Sort date range */}
                <div className="date-picker d-flex mb-2 align-items-center">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                  {/* Search */}
                  {/* <input
                    className="form-control"
                    name="search"
                    type="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />*/}
                </div>
              </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    Routes={DmgRoutes}
                    title="Damage Details"
                    data={exportData}
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover className="text-sm">
              <thead>
                <tr>
                  <th>Damage No</th>
                  {/* <th>Supplier</th> */}
                  <th className="text-center">Aricle Code</th>
                  <th>Name</th>
                  {/* <th className="text-center">MRP</th> */}
                  <th className="text-center">TP</th>
                  <th>QTY</th>
                  <th className="text-center">Unit</th>
                  <th className="text-end">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.length > 0 ? (
                  data?.products?.map((damage) => (
                    <tr key={damage.name}>
                      <td>{damage.damageNo}</td>
                      {/* <td>{damage.supplier}</td> */}
                      <td className="text-center">{damage.article_code}</td>
                      <td>{damage.name}</td>
                      {/* <td className="text-center">{damage.mrp} </td> */}
                      <td className="text-center">{damage.tp} </td>
                      <td className="text-center">
                        <b>{parseFloat(damage.qty).toFixed(2)} </b>
                        {/* {`[${damage?.unit}]`} */}
                      </td>
                      <td className="text-center">{damage.unit}</td>
                      <td className="text-end">
                        <b>
                          {new Intl.NumberFormat().format(damage.stockValue)}{" "}
                        </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={7}>No Product Found</tr>
                )}
                <tr>
                  <td className="text-end" colSpan={6}>
                    <b>GRAND TOTAL:</b>
                  </td>
                  <td className="text-end">
                    <b>{new Intl.NumberFormat().format(data?.stockValue)}</b> ৳
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* pagination */}
            {/* <ProductDataTable></ProductDataTable> */}
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default DamageDetails;
