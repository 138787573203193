import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { useGRNSummaryQuery } from "../../services/grnApi";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { GrnRoutes } from "../../data";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";
import { signInUser } from "../Utility/Auth";

function GRNSummary() {
  let i = 1;
  // Date
  const auth = signInUser();
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [exportData, setExportData] = useState([]);
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);
  const [warehouse, setWarehouse] = useState("allWh");
  const { data, isSuccess, isFetching, isLoading, refetch } = useGRNSummaryQuery({
    startDate,
    endDate,
    warehouse
  });
  const [totalGRN, setTotal] = useState(0);

  console.log("data", data)

  useEffect(() => {
    refetch();
  }, [startDate, endDate,warehouse]);

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setLoader(false);
    if (data?.products?.length > 0) {
      let total = 0
      data.products.map(pro => {
        // console.log(pro)
        total = total + parseFloat(pro.stockValue)
        // console.log(total)
      })
      setTotal(total)
      setExportData(data?.products);
    }
  }, [isSuccess, data]);
  console.log(totalGRN)

  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
  };


  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Grn Summary by Article"></Header>
            <div className="row mt-3">
              <div className="col-md-6">
                {/* Sort date range */}
                <div className="date-picker d-flex mb-2 align-items-center">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  {/* warehouse */}
                  <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                  {/* Search */}
                  {/* <input
                    className="form-control"
                    name="search"
                    type="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />*/}
                </div>
              </div>
              <div className="col-md-6">
                <span className="float-end">
                  <ExportNav
                    Routes={GrnRoutes}
                    data={exportData}
                    title="GRN Summary"
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="">SL</th>
                  <th className="">Aricle Code</th>
                  <th>Article Name</th>
                  <th className="text-end">Avg MRP</th>
                  <th className="text-end">Avg TP</th>
                  <th className="ps-3">QTY</th>
                  <th className="text-center">Unit</th>
                  <th className="text-center">GRNno</th>
                  <th className="text-end">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.length > 0 ? (
                  data?.products?.map((grn) => (
                    <tr key={grn._id}>
                      <td>{i++}</td>
                      <td className="">{grn.article_code}</td>
                      <td>{grn.name}</td>
                      <td className="text-end">{parseFloat(grn.mrp).toFixed(2)}</td>
                      <td className="text-end">
                        {parseFloat(grn.tp)?.toFixed(2)}
                      </td>
                      <td className="ps-3">
                        <b>{parseFloat(grn.qty)?.toFixed(2)} </b>
                        {/* {`[${grn?.unit}]`} */}
                      </td>
                      <td className="text-center">{grn.unit}</td>
                      <td className="text-center">{parseFloat(grn.grnQty)?.toFixed(2)}</td>
                      <td className="text-end">
                        <b>
                          {new Intl.NumberFormat().format(
                            parseFloat(grn.stockValue)?.toFixed(2)
                          )}
                        </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={9}>No Product Found</tr>
                )}
                <tr>
                  <td className="text-end" colSpan={8}>
                    <b>GRAND TOTAL:</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {new Intl.NumberFormat().format(totalGRN)
                      }
                      {/* {typeof data?.stockValue === "number"
                        ? new Intl.NumberFormat().format(data?.stockValue)
                        : 0} */}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default GRNSummary;
