import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../logo.png";
import "./SideBar.css";

const EcomSideBar = () => {
  let active = window.location.pathname;
  return (
    <aside className="sticky-md-top pb-5">
      <Link className="nav-link logo pt-3 pb-5" to="/">
        <Image src={logo} fluid />
      </Link>
      <nav className="nav flex-column">
        <>
          {/* DASHBOARD */}
          <Link
            className={`nav-link ${active === "/dashboard" && "active"}`}
            to="/dashboard"
          >
            <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
            Dashboard
          </Link>
          <Link
            className={`nav-link ${active === "/ecom" && "active"}`}
            to="/ecom"
          >
            <Icons.ShoppingCartOutline size={22}></Icons.ShoppingCartOutline>{" "}
            Ecom Sales
          </Link>
          <Link
            className={`nav-link mt-0 ${active === "/profile" && "active"}`}
            to="/profile"
          >
            <Icons.CogOutline size={22} /> Profile Settings
          </Link>
        </>
      </nav>
    </aside>
  );
};

export default EcomSideBar;
