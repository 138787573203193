import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCategoriesExportQuery } from "../../services/categoryApi";
import { usePricesExportQuery, usePricesQuery } from "../../services/priceApi";
import { useProductsExportQuery, useProductPopularQuery } from "../../services/productApi";
import { useSalesQuery } from "../../services/saleApi";
import { useSupplierExportQuery, useSupplierTestQuery } from "../../services/supplierApi";
import Header from "../Common/Header/Header";
import CategoryExportModal from "../Common/Modal/CategoryExportModal";
import COGS from "../Common/Modal/COGS";
import PopularProductExport from "../Common/Modal/PopularProductExport";
import PriceExportModal from "../Common/Modal/PriceExportModal";
import ProductDetailsExportModal from "../Common/Modal/ProductDetailsExportModal";
import SupllierMatchedProduct from "../Common/Modal/SupllierMatchedProduct";
import SupplierDetailsExportModal from "../Common/Modal/SupplierDetailsExportModal";
import SideBar from "../Common/SideBar/SideBar";
import { signInUser } from "../Utility/Auth";

const Exports = () => {
  const auth = signInUser();
  const [onShow, setOnShow] = useState(false);
  const [onShowSupplier, setOnShowSupplier] = useState(false);
  const [onShowCategory, setOnShowCategory] = useState(false);
  const [onShowSale, setOnShowSale] = useState(false);
  const [onShowPrice, setOnShowPrice] = useState(false);
  const [onShowPopular, setOnShowPopular] = useState(false);
  const [salesExport, setSalesExport] = useState([]);
  const [testExport, setTestExport] = useState(false);
  const [warehouse, setWarehouse] = useState("");

  const handleClose = () => setOnShow(false);
  const handleCloseSupplier = () => setOnShowSupplier(false);
  const handleCloseCategory = () => setOnShowCategory(false);
  const handleClosePrice = () => setOnShowPrice(false);
  const handleCloseSale = () => setOnShowSale(false);
  const handleClosePopularSale = () => setOnShowPopular(false);
  const handleCloseTest = () => setTestExport(false);

  const { data, isSuccess, refetch } = useProductsExportQuery({warehouse});

  const { data: supplier, isSuccess: supplierSuccess, refetch:supplierRefetch } =
    useSupplierExportQuery();

  const { data: category, isSuccess: categorySuccess, refetch:categoryRefetch } =
    useCategoriesExportQuery();

  const { data: price, isSuccess: priceSuccess } = usePricesExportQuery();
  const { data: popular, isSuccess: popularSuccess } = useProductPopularQuery();
  // const { data: test, isSuccess: testSuccess } = useSupplierTestQuery("62e91c15666a53f12aa41591");
  const sales = useSalesQuery();
  // console.log("test", test)


  useEffect(() => {
    let products = [];

    // for (const sale in sales?.data) {
    //   //   if (sale?.products !== null) {
    //   //     sale?.products?.map((product) => {
    //   //       products = [
    //   //         ...products,
    //   //         {
    //   //           article_code: product?.article_code,
    //   //           mrp: product?.mrp,
    //   //           qty: product?.qty,
    //   //           //   date: format(
    //   //           //     sale?.createdAt ? sale.createdAt : new Date(2023, 1, 1),
    //   //           //     "MM/dd/yyyy"
    //   //           //   ),
    //   //         },
    //   //       ];
    //   //     });
    //   //   }
    //   console.log(sale[products]);
    //   console.log("COGS", products);
    // }
    let i = 1;
    sales?.data?.map((sale) => {
      if (sale?.products !== null) {
        sale?.products?.map((product) => {
          if (product.article_code) {
            products = [
              ...products,
              {
                invoiceId: sale?.invoiceId ? sale.invoiceId : __dirname,
                article_code: product?.article_code,
                mrp: product?.mrp,
                qty: product?.qty,
                date: format(new Date(sale?.createdAt), "MM/dd/yyyy"),
              },
            ];
          }
        });
      }
      // console.log(i++);
      // console.log("COGS", products);
      setSalesExport(products);
    });
    console.log("Loop Complete");
  }, [sales.isSuccess]);


  useEffect(() => {
    refetch();
  }, [warehouse]);

  useEffect(() => {
    setWarehouse(auth?.warehouse);
  }, [auth]);

  const handleProductExport = () => {
    console.log("hello");
    setOnShow(true);
    console.log(onShow);
  };

  const handleSupplierExport = () => {
    setOnShowSupplier(true);
    console.log("hello1");
  };

  const handleCategoryExport = () => {
    setOnShowCategory(true);
  };
  const handlePriceTableExport = () => {
    // console.log(price)
    setOnShowPrice(true);
  };
  const handlePopularProductsExport = () => {
    // console.log(price)
    setOnShowPopular(true)
  };
  const handleProductMatching = () => {
    // console.log(price)
    // setOnShowPopular(true)
    setTestExport(true)
  };
  const handleSaleExport = () => {
    // console.log(price)
    setOnShowSale(true);
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Exports"></Header>
            <div className="row mt-3">
              <div className="col-md-4">
                <div>
                  <h4>Products</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handleProductExport()}
                    >
                      Export Product Details
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div>
                  <h4>Supplier</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handleSupplierExport()}
                    >
                      Export Product Details
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Category</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handleCategoryExport()}
                    >
                      Export Category Details
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Price Table</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handlePriceTableExport()}
                    >
                      Export Price Details
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Popular Products</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handlePopularProductsExport()}
                    >
                      Export Popular Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Category Sales Report</h4>
                  <Link
                    to="/categorySaleExport"
                    className="btn btn-dark mb-2 float-start me-2"
                  // onClick={() => setShow(true)}
                  >
                    Export Category Sales Report
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Supplier Sales Report</h4>
                  <Link
                    to="/supplierSaleExport"
                    className="btn btn-dark mb-2 float-start me-2"
                  // onClick={() => setShow(true)}
                  >
                    Export Supplier Sales Report
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>All Category Sales Report</h4>
                  <Link
                    to="/allCategorySales"
                    className="btn btn-dark mb-2 float-start me-2"
                  // onClick={() => setShow(true)}
                  >
                    All Category Sales Report
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Sample Import Files</h4>
                  <a
                    target="_blank"
                    href="https://drive.google.com/drive/folders/1fqSXoDEcjhqLHAhkgjtV5CHMw-OG6oGA"
                    className="btn btn-dark mb-2 float-start me-2"
                  // onClick={() => setShow(true)}
                  >
                    Sample Import Files
                  </a>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div>
                  <h4>Product Matching</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handleProductMatching()}
                    >
                      Export Popular Products
                    </Button>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-4">
                <div>
                  <h4>COGS</h4>
                  <div>
                    <Button
                      className="btn btn-dark mb-2 float-start me-2"
                      onClick={() => handleSaleExport()}
                    >
                      Export Lifetime Sales
                    </Button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ProductDetailsExportModal
        onShow={onShow}
        handleClose={handleClose}
        exportProduct={data}
      ></ProductDetailsExportModal>

      <SupplierDetailsExportModal
        onShow={onShowSupplier}
        handleClose={handleCloseSupplier}
        exportSupplier={supplier}
      ></SupplierDetailsExportModal>
      <COGS
        exportProduct={salesExport}
        handleClose={handleCloseSale}
        onShow={onShowSale}
      />

      <CategoryExportModal
        onShow={onShowCategory}
        handleClose={handleCloseCategory}
        exportCategory={category}
      ></CategoryExportModal>
      <PriceExportModal
        onShow={onShowPrice}
        handleClose={handleClosePrice}
        exportPrice={price}
      ></PriceExportModal>
      <PopularProductExport
        onShow={onShowPopular}
        handleClose={handleClosePopularSale}
        exportPopular={popular}
      >
      </PopularProductExport>
      {/* <SupllierMatchedProduct
        onShow={testExport}
        handleClose={handleCloseTest}
        exportPopular={test}
      >
      </SupllierMatchedProduct> */}
    </div>
  );
};

export default Exports;
