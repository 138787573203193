import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { useGrnDetailsQuery } from "../../services/grnApi";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { GrnRoutes } from "../../data";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

function GRNDetails() {
  // Date
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  //   const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [exportData, setExportData] = useState([]);
  const [total, setTotal] = useState(0);
  const [warehouse, setWarehouse] = useState("allWh");

  const [loader, setLoader] = useState(true);

  const { data, isSuccess, isFetching, isLoading, refetch } =
    useGrnDetailsQuery({
      startDate,
      endDate,
      warehouse,
    });

  const auth = signInUser();

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  // console.log("warehouse", warehouse);

  // console.log("data", data);
  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setLoader(false);
    if (data?.products?.length > 0) {
      let total = 0;
      setExportData(data.products);
      data.products.map((pro) => {
        total = total + parseFloat(pro.stockValue);
      });
      setTotal(total);
    }
  }, [isSuccess, data]);

  const handleLoaderClose = () => setLoader(false);

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
  };

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Grn Details by Article"></Header>
            <div className="row mt-3">
              <div className="col-md-5">
                {/* Sort date range */}
                <div className="date-picker mb-2">
                  <div className="d-flex  mb-2 align-items-center gap-2">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    {/* <span width="10px"></span> */}
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>
                  <div>
                    {auth?.type === "admin" && (
                      <div
                        style={{ width: "85%" }} // Consistent width for custom components
                      >
                        <WareHouseDW
                          id="warehouse"
                          name="warehouse"
                          handleOnChange={handleOnchangeWareHouseFrom}
                          className="form-control"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    Routes={GrnRoutes}
                    title="GRN Details"
                    data={exportData}
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover className="text-sm">
              <thead>
                <tr>
                  <th>GRN No</th>
                  {/* <th>Supplier</th> */}
                  <th className="text-center">Article Code</th>
                  <th>Name of article</th>
                  <th className="text-center">MRP</th>
                  <th className="text-center">TP</th>
                  <th>QTY</th>
                  <th className="text-center">Unit</th>
                  <th className="text-end">Stock Value</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.length > 0 ? (
                  data?.products?.map((grn) => (
                    <tr key={grn.name}>
                      <td>{grn.grnNo}</td>
                      {/* <td>{grn.supplier}</td> */}
                      <td className="text-center">{grn.article_code}</td>
                      <td>{grn.name}</td>
                      <td className="text-center">
                        {parseFloat(grn.mrp).toFixed(2)}{" "}
                      </td>
                      <td className="text-center">
                        {parseFloat(grn.tp).toFixed(2)}{" "}
                      </td>
                      <td>
                        <b>{parseFloat(grn.qty).toFixed(2)} </b>
                      </td>
                      <td className="text-center">{grn.unit}</td>
                      <td className="text-end">
                        <b>
                          {new Intl.NumberFormat().format(
                            parseFloat(grn.stockValue).toFixed(2)
                          )}{" "}
                        </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={8}>No Product Found</tr>
                )}
                <tr>
                  <td className="text-end" colSpan={7}>
                    <b>TOTAL VALUE:</b>
                  </td>
                  <td className="text-end">
                    {/* <b>{new Intl.NumberFormat().format(data?.stockValue)}</b> */}
                    <b>{new Intl.NumberFormat().format(total)}</b>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* pagination */}
            {/* <ProductDataTable></ProductDataTable> */}
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default GRNDetails;
