import axios from "axios";
import { ar } from "date-fns/locale";
import React, { useRef, useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import ProductSearch from "../../Common/ProductSearch/ProductSearch";
import SideBar from "../../Common/SideBar/SideBar";
import { ImBarcode } from "react-icons/im";
import "./InventorySession.css";
import { notify } from "../../Utility/Notify";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import InventoryCountTable from "../../Common/DataTable/InventoryCountTable";
import { BsArchive } from "react-icons/bs";
import InventorySessionExport from "../../Common/Modal/InventorySessionExport";
import { signInUser } from "../../Utility/Auth";
import SelectProduct from "../../Common/CustomSelect/SearchProduct";
import {
  useInventoryCountByUserQuery,
  useAddInventoryCountMutation,
  useInventoryCountByArticleCodeQuery,
} from "../../../services/inventoryCountApi";

const InventorySession = () => {
  const user = signInUser();
  const selectRef = useRef(null);
  const selectQtyInput = useRef(null);

  const [article_code, setArticle_code] = useState("");

  const [addInventoryCount] = useAddInventoryCountMutation();
  const { data, error, isSuccess, isFetching, refetch } =
    useInventoryCountByUserQuery(user.id);

  const ArticleCount = useInventoryCountByArticleCodeQuery(article_code);
  const initCount = {
    article_code: "",
    priceTable: "",
    qty: 0,
    warehouse: "62b5b575b4facb87eef3b47c",
    userId: user.id,
    status: "active",
  };
  const [article, setArticle] = useState({});
  const [matchProduct, setMatchProduct] = useState([]);
  const [invCount, setInvCount] = useState(initCount);
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    if (error) {
      console.log(error);
    } else {
      console.log(data);
      setInventory(data);
    }
  }, [isSuccess, refetch, isFetching]);

  console.log("data", data);

  useEffect(() => {
    if (error) {
      console.log(error);
    } else {
      console.log("Article Count:", ArticleCount.data);
      setMatchProduct(ArticleCount.data);
    }
  }, [ArticleCount.isSuccess, ArticleCount.isFetching]);

  useEffect(() => {
    ArticleCount.refetch();
  }, [article_code]);

  const addToList = (data) => {
    console.log("Find result:", data);

    setArticle_code(data._id);
    let count = {
      ...invCount,
      article_code: data._id,
      priceTable: data?.priceList[0]?._id,
      warhouse: "62b5b575b4facb87eef3b47c",
    };

    console.log(count);
    setArticle(data);
    setInvCount(count);
    // selectQtyInput.current.focus();
    if (selectQtyInput.current) {
      selectQtyInput.current.focus();
      console.log("select product");
    }
  };

  const selectPrice = (value) => {
    console.log(value);
    let count = {
      ...invCount,
      priceTable: value,
    };
    setInvCount(count);
  };
  const selectQty = (value) => {
    let count = {
      ...invCount,
      qty: value,
    };
    setInvCount(count);
  };

  const handleInventoryCount = async (e) => {
    e.preventDefault();
    const add = await addInventoryCount(invCount);
    if (add) {
      // console.log(add);
      setArticle({});
      setInvCount(initCount);

      setMatchProduct([]);
      refetch();
      selectRef.current.focus();
    }
  };

  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2">
          <SideBar></SideBar>
        </div>
        <div className="col-md-10">
          <Header title="Products Inventory Session"></Header>
          <div className="row">
            <div className="col-md-5 col-12 pt-4">
              <h4>Inventory Count</h4>
              <SelectProduct
                selectRef={selectRef}
                addToList={addToList}
              ></SelectProduct>

              {article?.article_code ? (
                <form
                  className="py-4 inventory-form"
                  onSubmit={(e) => handleInventoryCount(e)}
                >
                  <h5>
                    <BsArchive size={20}> </BsArchive> Article Information
                  </h5>
                  <label>
                    <b>Name: </b>
                    {article?.name}
                  </label>
                  <br />
                  <label>
                    <b>M. Category: </b>
                    {article?.master_category?.name}
                  </label>
                  <br />
                  <label>
                    <b>Category: </b>
                    {article?.category?.name}
                  </label>
                  <br />
                  <label>
                    <b>EAN: </b>
                    {article?.ean}
                  </label>
                  <br />
                  <label>
                    <b>Code: </b>
                    {article?.article_code}
                  </label>
                  <br />
                  <label className="row items-center">
                    <div className="col-1">
                      <b>MRP: </b>
                    </div>
                    <div className=" col-3">
                      <select
                        className="form-select"
                        onChange={(e) => selectPrice(e.target.value)}
                      >
                        {article?.priceList?.length > 1 ? (
                          article?.priceList?.map((price) => (
                            <option key={price?._id} value={price?._id}>
                              {console.log(price)}
                              {price?.mrp}
                            </option>
                          ))
                        ) : (
                          <option value={article?.priceList[0]?._id}>
                            {article?.priceList[0]?.mrp}{" "}
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="col-4">
                      BDT [{" "}
                      <small>
                        {article?.priceList?.length > 0
                          ? article?.priceList?.length
                          : 0}{" "}
                        prices
                      </small>{" "}
                      ]
                    </div>
                  </label>
                  <br />
                  <br />
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <span className="px-3">Count Stock Quantity</span>
                    </InputGroup.Text>
                    <FormControl
                      id="qtyField"
                      placeholder="Count Stock"
                      aria-label="Current Stock Quantity"
                      aria-describedby="basic-addon2"
                      onChange={(e) => selectQty(e.target.value)}
                      ref={selectQtyInput}
                    />
                    <Button type="submit" variant="dark" id="button-addon2">
                      Submit
                    </Button>
                  </InputGroup>
                </form>
              ) : (
                <div className="scanPlease mt-5 pt-3">
                  <p className="d-flex  align-items-center justify-content-center">
                    <ImBarcode size={60} className="m-3"></ImBarcode>
                    <i className="">
                      Please Scan the Article Bar Code <br />
                      Or search product name or code
                    </i>
                  </p>
                </div>
              )}

              {matchProduct?.length > 0 ? (
                <>
                  <h4>History</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Price ID</th>
                        <th>TP</th>
                        <th>MRP</th>
                        <th>QTY</th>
                        <th>CountBy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchProduct.map((item) => {
                        return (
                          <tr>
                            <td>{item?.priceTable?._id}</td>
                            <td>{item?.priceTable?.tp}</td>
                            <td>{item?.priceTable?.mrp}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.userId?.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <p className="text-center mt-5">No History Found</p>
                </>
              )}
            </div>
            <div className="col-md-7 col-12 pt-4">
              {/* <h4></h4> */}
              {inventory ? (
                <InventoryCountTable
                  inventory={inventory}
                ></InventoryCountTable>
              ) : (
                <div className="text-center">
                  <BsArchive size={50} />
                  <h1 className="text-center">No Products</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
      {/* <InventorySessionExport
        show={showA}
        handleClose={handleAClose}
        exportInventory={exportInventory}
      ></InventorySessionExport> */}
    </div>
  );
};

export default InventorySession;
