import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useWarehousesQuery } from "../../../services/warehouseApi";

const WareHouseDWPurchase = ({ wh, handleOnChange, name }) => {
  const { data, error, isLoading, isFetching, isSuccess } =
    useWarehousesQuery();
  let [warehouseOption, setWarehouseOption] = useState([]);
  // console.log("warehouse", data);
  useEffect(() => {
    let warehouseOptions = [];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((warehouse) => {
          warehouseOptions = [
            ...warehouseOptions,
            {
              option: warehouse._id,
              label: `${warehouse.name} - [ ${warehouse.address} ]`,
            },
          ];
        });
        setWarehouseOption(warehouseOptions);
      }
    }
  }, [data]);

  // console.log(warehouseOption[0]?.option);

  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={data[0].warehouse}
        // value={data[0].warehouse}
        defaultValue={{
          option: "62b5b575b4facb87eef3b47c",
          label: `TCM Sector#7 - [ Uttara, Dhaka-1230 ]`,
        }}
        value={
          wh === ""
            ? warehouseOption[0]
            : warehouseOption[warehouseOption.indexOf(wh)]
        }
        // value={warehouseOption[warehouseOption.indexOf(wh)].option}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        name={name}
        onChange={handleOnChange}
        options={warehouseOption}
      />
    </Fragment>
  );
};

export default WareHouseDWPurchase;
