import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
import "./User.css";
import "../Common/CSS/Table.css";
import SideBar from "../Common/SideBar/SideBar";
import { useUsersQuery, useDeleteUserMutation } from "../../services/userApi";
import { signInUser } from "../Utility/Auth";

const User = () => {
  const [warehouse, SetWarehouse] = useState("");

  const { data, error, isLoading, isFetching, isSuccess } = useUsersQuery({warehouse});
  let i = 1;
  const [deleteUser] = useDeleteUserMutation();

  const deleteHandler = async (id: string) => {
    const confirm = window.confirm("Are you Sure? Delete this User?");
    if (confirm) {
      const res = await deleteUser(id);
      if (res) {
        // TODO::
        // add error hendaler for delete error
        console.log(res);
      } else {
        console.log("Delete Operation Canceled by user!");
        return;
      }
    }
  };

  const auth = signInUser();

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="All Users"></Header>
            <div className="row pt-3">
              <div className="col-md-6">{/* <h3>All Users</h3> */}</div>

              <div className="col-md-6">
                <span className="float-end">
                  <Link className="btn btn-dark" to="/user/add">
                    <Icons.UserAddOutline size={18}></Icons.UserAddOutline>{" "}
                    Create User
                  </Link>
                </span>
              </div>
            </div>
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name </th>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {isSuccess && (
                  <>
                    {data?.map((user) => {
                      return (
                        <tr key={user?._id}>
                          <th scope="row">{i++}</th>
                          <td>{user?.name}</td>
                          <td>{user?.username}</td>
                          <td>{user?.email}</td>
                          <td>{user?.phone}</td>
                          <td>{user?.type}</td>
                          <td>{user?.status}</td>
                          <td className="d-flex ">
                            <Link to={`/user/${user._id}`}>
                              <Icons.EyeOutline
                                className="icon-eye"
                                size={22}
                              ></Icons.EyeOutline>
                            </Link>

                            <Link to={`/user/update/${user._id}`}>
                              <Icons.PencilAltOutline
                                className="icon-edit"
                                size={22}
                              ></Icons.PencilAltOutline>
                            </Link>
                            {auth?.type === "admin" && (
                              <Icons.TrashOutline
                                onClick={() => deleteHandler(user?._id)}
                                className="icon-trash"
                                size={22}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                {isLoading && <h2>...Loading</h2>}
                {isFetching && <h2>...isFetching</h2>}
                {error && <h2>Something went wrong</h2>}
              </tbody>
            </Table>

            {
              // console.log(users)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
