import React, { Component, Fragment, useEffect, useState } from "react";
import { useSuppliersQuery } from "../../../services/supplierApi";

import Select from "react-select";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const SelectSupplier = ({ selected, handleOnchange }) => {
  const [supplier, setSupplier] = useState([]);
  const [options, setOptions] = useState([
    { option: "0", label: "Select Supplier" },
  ]);

  const getSupplier = async (search) => {
    let cancelToken;
    let result;

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    if (search) {
      result = await axios.get(`${BASE_URL}supplier/dropdown/${search}`, {
        cancelToken: cancelToken.token,
      });
      if (result?.data) {
        setSupplier(result.data);
      } else {
        setSupplier([]);
      }
    } else {
      setSupplier([]);
      // result = [{option: "0", label: "No Price"}];
    }
  };

  useEffect(() => {
    let supplierList = [];
    if (supplier?.length) {
      supplier?.map((item) => {
        supplierList = [
          ...supplierList,
          {
            option: item._id,
            label: item?.company,
          },
        ];
      });
      setOptions(supplierList);
      // setDefaultValue(priceList[0]);
    } else {
      setOptions([{ option: "0", label: "Select Supplier" }]);
      // setDefaultValue(priceList[0]);
    }
  }, [supplier]);

  const handleInputChange = (inputValue) => {
    getSupplier(inputValue !== "" ? inputValue : "");
  };
  return (
    <Fragment>
      <Select
        className="select"
        defaultValue={options[0]}
        isLoading={false}
        isClearable={true}
        value={
          selected !== "" ? options[options.indexOf(selected)] : options[0]
        }
        isSearchable={true}
        name="supplier_code"
        onChange={handleOnchange}
        options={options}
        onInputChange={handleInputChange}
      />

      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      ></div>
    </Fragment>
  );
};

export default SelectSupplier;
