import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import "./Header.css";
import * as Icons from "heroicons-react";
import { IoFastFoodOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../logo.png";
import io from "socket.io-client";
import { useEcomSalesByStatusQuery } from "../../../services/ecomApi";
import alertSound from "../../../audio.mp3";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNewOrder,
  selectNewOrderAdd,
} from "../../../features/ecomSalesSlice";
import { useUpdateUserMutation } from "../../../services/userApi";
import { signInUser } from "../../Utility/Auth";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
const ENDPOINT = process.env.REACT_APP_WS_URL || "ws://localhost:5001/";

const Header = ({ title }) => {
  let active = window.location.pathname;

  const dispatch = useDispatch();
  const newOrder = useSelector((state) => state.ecomSalesReducer.newOrder);

  const { data, isSuccess } = useEcomSalesByStatusQuery("order");
  const [playAudio, setPlayAudio] = useState(false);
  const [enableAudio, setEnableAudio] = useState(false);

  const posSaleData = useSelector((state) => state.posReducer);

  // console.log("Header:", posSaleData);

  const audioRef = useRef();
  const playButtonRef = useRef();

  const play = async () => {
    // console.log(alertSound, logo);
    try {
      await new Audio(alertSound).play();
    } catch (err) {
      console.log(err);
    }

    setEnableAudio(true);
  };

  useEffect(() => {
    // if (playAudio & enableAudio) {
    //   console.log("play");
    //   playButtonRef.current.click();
    //   setPlayAudio(false);
    // }
  }, [playAudio]);

  useEffect(() => {
    if (data) {
      dispatch(selectNewOrder(data?.length));
    }
  }, [isSuccess]);

  useEffect(() => {
    //   // console.log("data", data);
    const socket = io(ENDPOINT);
    //   socket.on("connect", () => {
    //     console.log("WS data:", socket.id);
    //     // setEcomSale(data?.length);
    //   });

    //   // socket.on("ecom", (num) => {
    //   //   // console.log("ecom:", num);
    //   //   dispatch(selectNewOrder(num));
    //   // });

    socket.on("newEcomSale", (bool) => {
      // console.log(bool);
      if (bool == true) {
        // setPlayAudio(true);
        playButtonRef.current.click();
        // console.log("new sale:", newOrder, 1);
        dispatch(selectNewOrderAdd(newOrder + 1));
      }
    });
    socket.on("value", (bool) => {
      console.log(bool);
      // if (bool == true) {
      //   // setPlayAudio(true);
      //   playButtonRef.current.click();
      //   // console.log("new sale:", newOrder, 1);
      //   dispatch(selectNewOrderAdd(newOrder + 1));
      // }
    });

    //   // CLEAN UP THE EFFECT
    //   return () => socket.disconnect();
    //   //
  }, []);

  // console.log("newOrder:", newOrder);

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(localStorage.getItem("user"));
  // console.log("user", user)

  const accessToken = localStorage.getItem("accessTokens");
  const navigate = useNavigate();

  const user = signInUser();

  const [updateHoldSale] = useUpdateUserMutation();

  // handleLogOut
  const handleLogout = async (e) => {
    e.preventDefault();
    //CHECK IF POS HAS CART
    // console.log("posSaleData", posSaleData); // Check posSaleData
    if (posSaleData?.products?.length > 0) {
      let hold = localStorage.getItem("hold_cart");
      let holdData = JSON.parse(hold);
      console.log("holdData before update", holdData); // Check holdData before update
      if (holdData !== null) {
        holdData = [
          ...holdData,
          { products: posSaleData.products, order: new Date() },
        ];
      } else {
        holdData = [{ products: posSaleData.products, order: new Date() }];
      }
      console.log("holdData after update", holdData); // Check holdData after update
      // UPDATE USER HOLD DATA
      await Promise.all([updateHoldSale({ _id: user.id, holdSale: holdData })]);
      console.log("Hold sale updated successfully"); // Log after hold sale update
      // LOGOUT PROCESS
      localStorage.clear();
      navigate("/login", { replace: true });
    } else {
      //LOGOUT PROCESS
      localStorage.clear();
      navigate("/login", { replace: true });
    }
  };

  const handleUserProfile = (id) => {
    navigate(`/profile`);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#" className="logo me-5">
          <Link className="nav-link" to="/">
            {title ? title : <Image src={logo} height="40" />}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          {/* Notification */}
          {accessToken && (
            <>
              <div className="form-check form-switch">
                <input
                  ref={playButtonRef}
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked={enableAudio ? "checked" : ""}
                  onClick={play}
                />
              </div>
              <Nav>
                <Link className="nav-link" to="/ecom">
                  <Icons.BellOutline size={22} />
                  <Badge bg="danger" pill>
                    {newOrder}
                    {/* <audio ref={audioRef} src={alertSound} preload="auto" /> */}
                  </Badge>
                </Link>
              </Nav>

              {/* <Button className="" ref={playButtonRef} onClick={play}>
            Play
          </Button> */}

              <Nav>
                {user?.type === "admin" || user?.type === "POS" ? (
                  <Link className="nav-link" to="/pos">
                    <Icons.CalculatorOutline size={22} />
                  </Link>
                ) : (
                  <></>
                )}
                <Link
                  className={`nav-link ${active === "/dashboard" && "active"}`}
                  to="/dashboard"
                >
                  <Icons.AdjustmentsOutline
                    size={22}
                  ></Icons.AdjustmentsOutline>{" "}
                </Link>
              </Nav>
            </>
          )}
          {accessToken ? (
            <NavDropdown
              title={loggedInUser?.username}
              id="navbarScrollingDropdown"
            align='end'
            >
              <NavDropdown.Item
                onClick={() => {
                  handleUserProfile(loggedInUser.id);
                }}
              >
                {" "}
                <Icons.UserOutline className="h-3 w-3" /> Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout} href="/">
                {" "}
                <Icons.LockClosedOutline className="h-3 w-3" />
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav>
              <Link className="nav-link" to="/login">
                <Icons.LoginOutline size={22}></Icons.LoginOutline> Login
              </Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
