import React, { useEffect, useState } from "react";
import { Toast, Card, Table } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import * as Icons from "heroicons-react";
import LineChart from "./Chart/LineChart";
import PieChart from "./Chart/PieChart";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import {
  useSaleFootfallQuery,
  useSalesPointSpentQuery,
  useSalesWeeklyQuery,
  useSaleTotalQuery,
} from "../../../services/saleApi";
import {
  useTodayGrnsQuery,
  useWeeklyGrnsQuery,
} from "../../../services/grnApi";
import { useTodayDamagesQuery } from "../../../services/damageApi";
import subDays from "date-fns/subDays";

const EcomDashboard = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const {
    data: total,
    error,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useSaleTotalQuery({
    startDate: startDate,
    endDate: endDate,
  });

  // const loggedInUser = JSON.parse(localStorage.getItem("user"));

  // console.log(loggedInUser);

  const { data: footfall } = useSaleFootfallQuery({
    startDate: startDate,
    endDate: endDate,
  });
  const { data: pointSpent } = useSalesPointSpentQuery();
  // console.log({ startDate: "10-01-2022", endDate: "10-02-2022" });
  //{ data, error, isLoading, isFetching, isSuccess, refetch }

  useEffect(() => {
    // console.log(total);
  }, total?.isSuccess);

  // console.log(startDate, endDate, footfall, total);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { data: weeklySale, isSuccess: isSuccessSale } = useSalesWeeklyQuery();
  // const { data: weeklyPurchase, isSuccess: isSuccessPurchase } = useWeeklyPurchasesQuery();
  const { data: weeklyGRN, isSuccess: isSuccessGRN } = useWeeklyGrnsQuery();
  const { data: todayTotalGRN, isSuccess: isSuccessTodayGRN } =
    useTodayGrnsQuery();

  const { data: todayTotalDamage, isSuccess: isSuccessTodayDamage } =
    useTodayDamagesQuery();
  // const [weekDates, setWeekDates] = useState([])
  const [weekSales, setWeekSales] = useState(0);
  // const [weekPurchase, setWeekPurchase] = useState([])
  const [weekGRN, setWeekGRN] = useState([]);
  const [lossProfit, setLossProfit] = useState(0);
  const [todaySale, setTodaySale] = useState(0);
  const [todayGrn, setTodayGrn] = useState(0);
  const [todayDamage, setTodayDamage] = useState(0);
  useEffect(() => {
    // console.log(total);
    if (total?.length > 0) {
      setTodaySale(total.total);
    }
    // console.log(todayTotalGRN);
    if (todayTotalGRN?.length > 0) {
      setTodayGrn(todayTotalGRN[0]?.total);
    }
    // console.log(todayTotalDamage);
    if (todayTotalDamage?.length > 0) {
      setTodayDamage(todayTotalDamage[0]?.total);
    }
  }, [
    isSuccess,
    total,
    isSuccessTodayGRN,
    todayTotalGRN,
    todayTotalDamage,
    isSuccessTodayDamage,
  ]);
  // console.log(todayDamage);
  // console.log(todayGrn);
  // console.log(todaySale);

  useEffect(() => {
    const total = parseFloat(todaySale) - parseFloat(todayGrn);
    setLossProfit(total);
  }, [todaySale, todayGrn]);

  useEffect(() => {
    let g = 0;
    if (weeklyGRN?.length > 0) {
      weeklyGRN?.slice().map((grn) => {
        g = g + grn.total;
      });
      setWeekGRN(g);
    }
    // console.log(dates)
  }, [weeklyGRN, isSuccessGRN]);

  useEffect(() => {
    let sales = 0;
    if (weeklySale?.length > 0) {
      weeklySale?.slice().map((sale) => {
        sales = sales + sale.grossTotalRound;
      });
      setWeekSales(sales);
    }
  }, [weeklySale, isSuccessSale]);

  // console.log(weekSales);
  // console.log(weekGRN);

  const handleSelectDay = (day) => {
    const today = new Date();
    const last3Day = subDays(today, 3);
    const last7Day = subDays(today, 7);
    const last30Day = subDays(today, 30);

    // console.log(
    //   "today:",
    //   today,
    //   "last3Day:",
    //   last3Day,
    //   "last7Day:",
    //   last7Day,
    //   "last30Day:",
    //   last30Day
    // );

    setEndDate(format(new Date(), "MM-dd-yyyy"));
    if (day === 3) {
      setStartDate(format(new Date(last3Day), "MM-dd-yyyy"));
    } else if (day === 7) {
      setStartDate(format(new Date(last7Day), "MM-dd-yyyy"));
    } else if (day === 30) {
      setStartDate(format(new Date(last30Day), "MM-dd-yyyy"));
    } else {
      setStartDate(format(new Date(), "MM-dd-yyyy"));
    }
    refetch();
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Dashboard"></Header>
            <div className="row">
              <h3>Comming Soon</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcomDashboard;
