import React, { useEffect, useState } from "react";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
import { useForm } from "react-hook-form";
import { notify } from "../Utility/Notify";
import {
  useCustomerQuery,
  useUpdateCustomerMutation,
} from "../../services/customerApi";
import LoadingModal from "../Common/Modal/LoadingModal";
import { v4 as uuidv4 } from "uuid";
import { Toaster } from "react-hot-toast";

const UpdateCustomer = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const { data, error, isLoading, isFetching, isSuccess } = useCustomerQuery(
    `${id}`
  );

  const { register, handleSubmit, reset, watch, getValues } = useForm({});
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    if (data) {
      reset({
        _id: data?._id,
        name: data?.name,
        phone: data?.phone,
        email: data?.email,

        // type: data?.address[0]?.type,
        // holdingNo: data?.address[0]?.holdingNo,
        // sector: data?.address[0]?.sector,
        // road: data?.address[0]?.street,
        // town: data?.address[0]?.town,
        // city: data?.address[0]?.city,
        // division: data?.address[0]?.division,
        // country: data?.address[0]?.country,
        // zipCode: data?.address[0]?.zipCode,

        membership: data?.membership,
        point: data?.point,
        type: data?.type,
        status: data?.status,
      });
    }
  }, [isSuccess, data]);

  // console.log(data);

  // handel user update
  const [UpdateCustomer] = useUpdateCustomerMutation();

  const updateHandler = async (customer) => {
    console.log("customer", customer);
    setLoader(true);
    let newCustomer = {};
    if (customer.password === customer.rePassword) {
      newCustomer = {
        _id: data?._id,
        name: customer?.name,
        password: customer.password === "" ? customer?.password : data.password,
        phone: customer?.phone,
        email: customer?.email,
        membership: customer?.membership,
        address: [
          {
            type: data?.address[0]?.type ? data?.address[0]?.type : "Home",
            holdingNo: customer?.holdingNo,
            sector: customer?.sector,
            street: customer?.road,
            town: customer?.town,
            city: customer?.city,
            division: customer?.division,
            country: customer?.country,
            zipCode: customer?.zipCode,
          },
        ],
        point: data?.point ? data?.point : 0,
        type: customer?.type,
        status: customer?.status,
      };
      console.log("customer", newCustomer);

      const response = UpdateCustomer(newCustomer);
      if (response) {
        setLoader(false);

        notify("Update Customer Profile Successful", "success");
        navigate("/customer");
      } else {
        setLoader(false);
        notify("Customer Profile Update Field", "error");
      }
      console.log("newCustomer", newCustomer);
    } else {
      notify("Password does not matched", "error");
    }

    setLoader(false);
  };
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data, isSuccess]);

  console.log(data);
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title={`Update Customer: ${data?.name}`}></Header>
            <div className="row  mt-3 pt-3 pb-5">
              <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit(updateHandler)}>
                  <h6 className="mb-2">
                    <Icons.UserOutline size={20} /> Basic Info
                  </h6>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        {...register("name", { required: true })}
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="email"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                      <input
                        {...register("phone")}
                        type="phone"
                        className="form-control"
                        id="phone"
                        placeholder="phone"
                      />
                    </div>
                  </div>
                  <h6 className="mb-2 mt-4">
                    <Icons.LocationMarkerOutline size={20} /> Address
                  </h6>
                  <div className="row">
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">House</label>
                      <input
                        {...register("holdingNo")}
                        type="text"
                        className="form-control"
                        id="holdingNo"
                        placeholder="House"
                      />
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">Road</label>
                      <input
                        {...register("road")}
                        type="text"
                        className="form-control"
                        id="road"
                        placeholder="Road"
                      />
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">Sector</label>
                      <select {...register("sector")} className="form-control">
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option selected value="7">
                          07
                        </option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">Town</label>
                      <select {...register("town")} className="form-control">
                        <option selected value="Uttara">
                          Uttara
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">City</label>
                      <select {...register("city")} className="form-control">
                        <option selected value="Dhaka">
                          Dhaka
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">Division</label>
                      <select
                        {...register("division")}
                        className="form-control"
                      >
                        <option selected value="Dhaka">
                          Dhaka
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="MCId">Zip Code</label>
                      <select {...register("zipCode")} className="form-control">
                        <option selected value="1230">
                          1230
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-8  mb-3">
                      <label htmlFor="MCId">Country</label>
                      <select {...register("country")} className="form-control">
                        <option selected value="BD">
                          Bangladesh
                        </option>
                      </select>
                    </div>
                  </div>
                  <h6 className="mb-2 mt-4">
                    <Icons.ShieldCheckOutline size={20} /> Security
                  </h6>

                  <div className="form-group row mb-2">
                    <label
                      for="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Username
                    </label>
                    <div className="col-sm-10 ">
                      <input
                        type="text"
                        readonly
                        className="form-control-plaintext"
                        id="staticEmail"
                        value={data?.username}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Password
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="password"
                        className="form-control"
                        id="inputPassword"
                        {...register("password")}
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Confirm Password
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="password"
                        className="form-control"
                        id="inputRePassword"
                        {...register("rePassword")}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                  {watch("rePassword") !== watch("password") &&
                  getValues("rePassword") ? (
                    <p>password not match</p>
                  ) : null}

                  <h6 className="mb-2 mt-4">
                    <Icons.BadgeCheckOutline size={20} /> Membership
                  </h6>
                  <div className="row">
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="inputMC">Member Ship</label>

                      <select
                        {...register("membership")}
                        className="form-select"
                        id="status"
                      >
                        <option value="gold">Gold</option>
                        <option value="diamond">Diamond</option>
                        <option value="premium">Premium</option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="inputMC">Customer Type</label>
                      <select
                        {...register("type")}
                        className="form-select"
                        id="status"
                      >
                        <option value="regular" selected>
                          Regular
                        </option>
                        <option value="premium">Premium</option>
                        <option value="vip">VIP</option>
                      </select>
                    </div>
                    <div className="form-group col-4  mb-3">
                      <label htmlFor="inputMC">status</label>
                      <select
                        {...register("status")}
                        className="form-select"
                        id="status"
                        placeholder="status"
                      >
                        <option value="active">active</option>
                        <option value="inactive">inactive</option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-dark col-4">
                    <Icons.SaveOutline /> Customer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default UpdateCustomer;
