import React, { Component, Fragment, useEffect, useState } from "react";
import Select from "react-select";
import {
  useBillerDwQuery,
  useManagerDwQuery,
  useUserDwQuery,
} from "../../../services/userApi";

const ManagerSelect = ({ handleOnchange, manager }) => {
  let [customer, setCustomer] = useState([]);
  const { data, error, isLoading, isFetching, isSuccess } = useManagerDwQuery();

  // console.log(customer);

  useEffect(() => {
    let pos = [{ option: "no-user", label: "Select Manager" }];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((user) => {
          pos = [
            ...pos,
            {
              option: user.username,
              label: `${user.name}`,
            },
          ];
        });
        setCustomer(pos);
      }
    }
  }, [data]);

  const handleOnchange1 = (value) => {
    console.log(value);
  };

  // console.log(data);
  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        defaultValue={customer[0]}
        value={
          manager &&
          customer[customer.map((obj) => obj.option).indexOf(manager)]
        }
        onChange={handleOnchange}
        styles={{
          menu: (css) => ({ ...css, width: "100%" }),
          control: (css) => ({
            ...css,
            display: "inline-flex",
            width: "100%",
          }),
        }}
        isSearchable={true}
        name="Biller"
        options={customer}
      />
    </Fragment>
  );
};

export default ManagerSelect;
