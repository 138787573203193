import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import * as Icons from "heroicons-react";
import AccountPrint from "./AccountPrint";
import { useAccountQuery } from "../../services/accountApi";


// import { ComponentToPrint } from './ComponentToPrint';

const AccountView = ({ show, handleClose, accountId }) => {
  const componentRef = useRef();
  const { data, refetch } = useAccountQuery(accountId);

  useEffect(() => {
    refetch()
  }, [accountId])
  // console.log(grnView.data);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log("accountId", accountId);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>Account Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AccountPrint ref={componentRef} account={data} refetchAccount={refetch} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button className="btn btn-dark float-end" onClick={handlePrint}>
          <Icons.PrinterOutline className="ms-3" size={18} /> Print{" "}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountView;
