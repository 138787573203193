import React, { useEffect, useRef, useState } from "react";
import LoadingModal from "../Common/Modal/LoadingModal";
import { Toaster } from "react-hot-toast";
import { Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import WareHouseDWPurchase from "../Common/CustomSelect/WareHouseDWPurchase";
import useAdjust from "../Hooks/useAdjust";
import {
  resetAdjust,
  selectNote,
  selectProducts,
  selectStatus,
  selectWareHouse,
} from "../../features/adjustSlice";
import * as Icons from "heroicons-react";
import DamageNewProductSelect from "../Common/CustomSelect/DamageNewProductSelect";
import AdjustProductImporter from "../Common/CsvImporter/AdjustProductImporter";
import { notify } from "../Utility/Notify";
import axios from "axios";
import AdjustProducts from "./AdjustProducts";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { useAddAdjustMutation } from "../../services/adjustApi";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const CreateAdjust = () => {
  let i = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [wh, setWh] = useState("");

  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);

  const adjustNewProductsRef = useRef(null);

  const [csvData, setCsvData] = useState([]);
  const [adjustProducts, setAdjustProducts] = useState([]);

  const { totalItems } = useAdjust();
  const { total_item, total, productList } = totalItems(adjustProducts);

  const adjustData = useSelector((state) => state.adjustReducer);

  const [addAdjust] = useAddAdjustMutation();

  // console.log(adjustData);

  const handleOnchangeWareHouse = (e) => {
    // console.log(e);
    setWh(e.option);
    dispatch(selectWareHouse(e.option));
  };

  const handleAdjustSubmit = async () => {
    const newAdjust = { ...adjustData, total: total };
    // console.log("newAdjust", newAdjust);

    if (newAdjust?.products.length > 0) {
      const confirm = window.confirm("Are you sure? Wanna create Adjust?");
      if (confirm) {
        try {
          // console.log("Adjust Data", newAdjust);
          const response = await addAdjust(newAdjust);
          if (response?.error?.status === 500) {
            apiUniqueErrHandle(response?.error?.data);
          } else {
            dispatch(resetAdjust());
            notify("Adjust Creation update successful!", "success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          // setPurchaseProduct([]);
          // UPDATE INVENTORY
          navigate("/adjust");
        }
      }
    } else {
      notify("There must be products for Damage", "error");
    }
  };

  const emptyAdjust = () => {
    if (window.confirm("Refresh Cart! Start New Damage?") === true) {
      dispatch(selectProducts([]));
      // localStorage.removeItem("damage_cart"); // check by manishankar please
    } else {
      console.log("Refresh Operation Cancelled by User");
    }
  };

  const addToList = async (data) => {
    // console.log(data);
    if (data) {
      // console.log("add to list:", data);
      await adjustNewProductsRef.current.blur();
      let listData = adjustData?.products;

      let newProduct = {};
      // unique check
      const idExist = listData?.find((pro) => pro.id === data._id);
      if (idExist) {
        notify("Product Already in List", "error");
        // console.log()
      } else {
        notify("Product is Added", "success");

        newProduct = {
          id: data?._id,
          name: data?.name,
          article_code: data?.article_code,
          qty: 1,
          order: listData?.length + 1,
          priceId: data?.priceList[0]?._id,
          tp: data?.priceList[0]?.tp,
          reason: "",
          type: true,
        };
        listData = [...listData, newProduct];
      }

      // console.log("upcoming damage:", newProduct);
      // console.log("Updated damage:", listData);
      setAdjustProducts(listData);
      dispatch(selectProducts(listData));
    } else {
      return false;
    }
  };
  const handleImportButton = () => {
    let importProducts = [];
    let i = 1;
    console.log(csvData);
    setOnShow(true);
    if (csvData?.length > 0) {
      csvData.map(async (pro) => {
        console.log(pro?.article_code);
        try {
          const details = await axios(
            `${BASE_URL}product/pro-details/${pro.article_code}`
          );
          // console.log(pro.article_code, ": ", details.data.priceList);
          if (details.status === 200) {
            const data = details.data;
            importProducts = [
              ...importProducts,
              {
                id: data?._id,
                article_code: data?.article_code,
                priceId: data?.priceList[0]?._id || null,
                tp: data?.priceList[0]?.tp || 0,
                name: data?.name,
                qty: pro.qty,
                type: pro.type === "in" ? true : false,
                tax: 0,
                discount: 0,
                order: i++,
              },
            ];
            setAdjustProducts(importProducts);
          }
        } catch (err) {
          console.log(err);
          
          notify(err, "error");
        } finally {
          // console.log(importProducts);
          dispatch(selectProducts(importProducts));
          if (importProducts?.length === csvData?.length) {
            setOnShow(false);
          }
        }
      });
    } else {
      setOnShow(false);
      notify("There is no products to import", "error");
    }
  };
  const handleOnChangePrice = async (id, e) => {
    let newCart = [];
    // console.log(e);
    try {
      let price = await axios.get(`${BASE_URL}price/${e.option}`);
      // console.log(price);
      let selected = adjustData.products.find((item) => item.id === id);
      let rest = adjustData.products.filter((item) => item.id !== id);
      if (price.data) {
        newCart = [
          ...rest,
          {
            ...selected,
            tp: price.data?.tp,
            priceId: price.data?._id,
          },
        ];
        setAdjustProducts(newCart);
      }
    } catch (err) {
      notify(err, "error");
    } finally {
      dispatch(selectProducts(newCart));
      // dispatch(selectProducts(newCart));
    }

    // localStorage.setItem("pos_cart", JSON.stringify(newCart));
    // console.log(e.option);
    // updateCart();
  };

  const handleCustomQty = (qty, id) => {
    // const qty = e.target.value();
    const selected = adjustData?.products?.find((p) => p.article_code === id);
    const rest = adjustData?.products?.filter((p) => p.article_code !== id);
    // console.log("selected", selected);
    // console.log("rest", rest);
    // console.log("id", id);
    let newProducts = [
      ...rest,
      {
        ...selected,
        qty: qty,
      },
    ];
    setAdjustProducts(newProducts);
    dispatch(selectProducts(newProducts));
  };

  const handleCustomReason = (reason, id) => {
    const selected = adjustData?.products?.find((p) => p.article_code === id);
    const rest = adjustData?.products?.filter((p) => p.article_code !== id);

    let newProducts = [];
    if (rest) {
      newProducts = [
        ...rest,
        {
          ...selected,
          reason: reason,
        },
      ];
    } else {
      newProducts = [...{ ...selected, reason: reason }];
    }

    dispatch(selectProducts(newProducts));
  };

  const removeFromCart = (id) => {
    const selected = adjustData?.products?.find((p) => p.id === id);
    const rest = adjustData?.products?.filter((p) => p.id !== id);
    setAdjustProducts(rest);
    dispatch(selectProducts(rest));
  };

  const handleDeSelectIsType = (id) => {
    const selected = adjustData?.products?.find((p) => p.article_code === id);
    const rest = adjustData?.products?.filter((p) => p.article_code !== id);

    let newProducts = [];
    if (rest) {
      newProducts = [
        ...rest,
        {
          ...selected,
          type: false,
        },
      ];
    } else {
      newProducts = [...{ ...selected, type: false }];
    }
    setAdjustProducts(newProducts);
    dispatch(selectProducts(newProducts));
  };

  const handleIsType = (id) => {
    const selected = adjustData?.products?.find((p) => p.article_code === id);
    const rest = adjustData?.products?.filter((p) => p.article_code !== id);

    let newProducts = [];
    if (rest) {
      newProducts = [
        ...rest,
        {
          ...selected,
          type: true,
        },
      ];
    } else {
      newProducts = [...{ ...selected, type: true }];
    }
    setAdjustProducts(newProducts);
    dispatch(selectProducts(newProducts));
  };

  // console.log("Adjust", total, total_item);

  // useEffect(() => {
  //   totalItems(adjustProducts);
  // }, [adjustProducts]);

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mt-2">
            <Header title="Create Adjust"></Header>
            <Form className="pt-3" action="javascript:void(0)">
              <div className="row">
                <div className="col-8">
                  <Form.Group className="" controlId="warehouse">
                    <Form.Label>Warehouse</Form.Label>
                    <WareHouseDWPurchase
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouse}
                      wh={wh !== "" ? wh : 0}
                      // {...register("warehouse")}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <Form.Group className="">
                    <Form.Label>Adjust Status</Form.Label>
                    <Form.Select
                      onChange={(e) => dispatch(selectStatus(e.target.value))}
                    >
                      <option value="active">Active</option>
                      <option disabled value="inactive">
                        Inactive
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-6 my-3">
                  <Form.Label>Adjust Products</Form.Label>
                  <DamageNewProductSelect
                    className="searchProduct"
                    // getValue={getValue}
                    addToList={addToList}
                    damageNewProductsRef={adjustNewProductsRef}
                  ></DamageNewProductSelect>
                </div>
                <div className="col-6 my-3 ">
                  <AdjustProductImporter
                    setCsvData={setCsvData}
                    handleImportButton={handleImportButton}
                    title=""
                  />
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Adjusted Products List</div>
                    <div className="card-body">
                      <Table hover bordered striped className="mt-3 ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>TP</th>
                            {/* <th>Tax</th> */}
                            <th>Total</th>
                            <th>Reason</th>
                          </tr>
                        </thead>

                        <tbody>
                          <AdjustProducts
                            adjustProducts={adjustData?.products}
                            i={i}
                            handleOnChangePrice={handleOnChangePrice}
                            handleCustomQty={handleCustomQty}
                            removeFromCart={removeFromCart}
                            handleCustomReason={handleCustomReason}
                            handleIsType={handleIsType}
                            handleDeSelectIsType={handleDeSelectIsType}
                          ></AdjustProducts>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-2">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Note</Form.Label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Note"
                      onChange={(e) => dispatch(selectNote(e.target.value))}
                    />
                  </Form.Group>
                </div>
              </div>
              <Table className="bordered striped ">
                <thead>
                  <tr>
                    <th>Total Items:{total_item}</th>
                    <th>Adjust Value: {total?.toFixed(2)} TK</th>
                  </tr>
                </thead>
              </Table>
              <Button
                variant="dark"
                onClick={handleAdjustSubmit}
                className="float-end my-2"
                type="button"
              >
                <Icons.SaveOutline size={20} /> Submit
              </Button>
              <Button
                variant="dark"
                className="float-end my-2 mx-2"
                type="button"
                onClick={emptyAdjust}
              >
                Reset Cart
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <LoadingModal
        onShow={onShow}
        title="Please Wait.."
        handleClose={handleClose}
      />
      <Toaster position="bottom-right" />
    </div>
  );
};

export default CreateAdjust;
