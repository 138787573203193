import React, { useState, useEffect } from "react";
import * as Icons from "heroicons-react";
import { Toaster } from "react-hot-toast";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { Link } from "react-router-dom";
import { notify } from "../Utility/Notify";
import { compareAsc, format } from "date-fns";
import {
  useRtvesQuery,
  useRtvCountQuery,
  useRtvPagenationQuery,
  useDeleteRtvMutation,
  useRtvByDateQuery,
} from "../../services/rtvApi";
import RtvView from "../Common/Modal/RtvView";
import ReactPaginate from "react-paginate";
// import useInventory from "../Hooks/useInventory";
import axios from "axios";
import LoadingModal from "../Common/Modal/LoadingModal";
import DatePicker from "react-datepicker";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

const Rtv = () => {
  const [deleteRtv] = useDeleteRtvMutation();
  const [totalRTV, setTotalRTV] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let i = 1;
  const [rtv, setRtv] = useState([]);
  const [grnView, setGrnView] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [warehouse, setWarehouse] = useState("allWh");

  const { data, isSuccess, isFetching, refetch } = useRtvByDateQuery({
    startDate,
    endDate,
    warehouse
  });

  const auth = signInUser();

  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";


    useEffect(() => {
      refetch();
    }, [startDate, endDate ,warehouse]);
  
    useEffect(() => {
      if(auth?.type === "admin"){
        setWarehouse("allWh");
      }else{
        setWarehouse(auth?.warehouse);
      }
    }, []);
  // const { updateInventoryInOnRTVDel } = useInventory();
  useEffect(() => {
    setRtv(data);

    isSuccess ? setLoader(false) : setLoader(true);
    let total = 0;
    if (data?.length > 0) {
      data.map((rtv) => {
        if (rtv.status !== "Canceled") {
          total = total + parseFloat(rtv.total);
        }
      });
      setTotalRTV(total);
    }
  }, [isSuccess, isFetching]);
  // console.log(purchase)
  // console.log(data);
  // useEffect(() => {
  //   rtv ? setLoader(false) : setLoader(true);
  // }, [rtv]);

  const handelDeleteGrn = async (id) => {
    const confirm = window.confirm("Are you Sure? Delete this RTV?");
    if (confirm) {
      try {
        setLoader(true);
        const res = await deleteRtv(id);
        if (res) {
          notify("RTV Deleted Successful!", "error");
        } else {
          notify("Delete Operation Canceled!", "success");
          return;
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleGrnView = (id) => {
    // console.log(id);
    setGrnView(id);
    setShow(true);
  };

  // search and Pagenation funcation
  const pageCountQuery = useRtvCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  console.log('data',rtv)
  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
  };

  return (
    <>
      <div>
        <div className="container-fluid ">
          <LoadingModal
            title={"Please Wait"}
            onShow={loader}
            handleClose={handleLoaderClose}
          ></LoadingModal>

          <div className="row">
            <div className="col-md-2">
              <SideBar></SideBar>
            </div>
            <div className="col-md-10">
              <Header title="Return To Vendor"></Header>
              <div className="row">
                <div className="col-md-6">
                  {/* Sort date range */}
                  <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                  </div>
                  {/* <form>
                    <div className="input-group mb-3 mt-3">
                      <select
                        className="form-select"
                        onChange={(e) => handleDataLimit(e)}
                      >
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                        <option value="250">250</option>
                      </select>
                      <input
                        className="form-control"
                        type="text"
                        onKeyUp={(e) => handleSearch(e)}
                      /> */}
                  {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  {/* </div>
                  </form> */}

                  {/* <nav aria-label="Page navigation example">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      //dynamic page count
                      // page count total product / size
                      pageCount={Math.ceil(
                        parseInt(pageCount) / parseInt(size)
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination pt-0 pb-2"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                    ></ReactPaginate>
                  </nav> */}
                </div>
                <div className="col-md-6 ">
                  <Link
                    to="/rtv-create"
                    className="btn btn-dark float-end mt-2 me-2"
                  >
                    <Icons.Plus /> Create New RTV
                  </Link>
                  <Link
                    to="/rtv-details"
                    className="btn btn-dark float-end mt-2 me-2"
                  >
                    <Icons.ChartBar /> Report
                  </Link>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-12"></div> */}
                <div className="col-md-12">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Rtv NO</th>
                        <th>Supplier</th>
                        <th>Warehouse</th>
                        <th>Date</th>
                        <th>Rtv By</th>
                        <th>Item No</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rtv?.length > 0 ? (
                        rtv?.map((item) => (
                          <tr key={item._id}>
                            <th>{i++}</th>
                            <td>{item?.rtvNo}</td>
                            <td>{item?.supplier?.company}</td>
                            <td>{item?.warehouse?.name}</td>
                            <td>
                              {item?.createdAt &&
                                format(new Date(item.createdAt), "MM/dd/yyyy")}
                            </td>
                            <td>{item?.userId?.name}</td>
                            <td>{item.totalItem.toFixed(2)}</td>
                            {/* <td>{item.tax}</td> */}
                            <td>
                              {new Intl.NumberFormat().format(
                                item.total.toFixed(2)
                              )}
                            </td>
                            <td>{item.status}</td>
                            {/* <td>{item.is_full ? "Full" : "Partials"}</td> */}
                            <td>
                              <Icons.EyeOutline
                                onClick={() => handleGrnView(item._id)}
                                className="icon-eye me-1"
                                size={20}
                              ></Icons.EyeOutline>
                              {/* <Link to={``}>
                              <Icons.PencilAltOutline className="icon-edit" size={20} ></Icons.PencilAltOutline>
                            </Link> */}
                              {item.status !== "Canceled" &&
                                auth?.type === "admin" && (
                                  <Icons.TrashOutline
                                    className="icon-trash"
                                    onClick={() => handelDeleteGrn(item?._id)}
                                    size={20}
                                  />
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr colSpan={9}>No Return Found</tr>
                      )}
                      {
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <b>Grand Total</b>
                          </td>
                          <td>
                            <b>
                              {new Intl.NumberFormat().format(
                                parseFloat(totalRTV).toFixed(2)
                              )}
                            </b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <Toaster position="bottom-right" />
            </div>
          </div>
        </div>
        <RtvView show={show} handleClose={handleClose} grn={grnView}></RtvView>
      </div>
    </>
  );
};

export default Rtv;
