import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

const initialState = {
  invoiceId: "0171000000",
  source: "web",
  warehouse: user?.warehouse,
  products: [],
  returnProducts: [],
  returnCal: {
    totalItem: 0,
    total: 0,
    vat: 0,
    grossTotal: 0,
    grossTotalRound: 0,
    point: 0,
  },
  returnInvoice: null,
  paidAmount: {
    cash: 0,
    mfs: {
      name: "bkash",
      amount: 0,
    },
    card: {
      name: "visa",
      amount: 0,
    },
    point: 0,
  },
  changeAmount: 0,
  totalReceived: 0,
  grossTotal: 0,
  grossTotalRound: 0,
  totalItem: 0,
  total: 0,
  vat: 0,
  point: {
    old: 0,
    new: 0,
  },
  todayPoint: 0,
  discount: 0,
  billerId: user?.id,
  customerId: "62e301c1ee6c8940f6ac1515",
  customerPhone: "0171000000",
  customerName: "Walkway Customer",
  updateUser: user?.id,
  promo_discount: 0,
  status: "complete",
};

export const ecomSaleUpdateSlice = createSlice({
  name: "ecomSalesUpdate",
  initialState: initialState,

  reducers: {
    selectAllData: (state, action) => {
      state = action.payload;
      return state;
    },
    selectProducts: (state, action) => {
      return {
        ...state,
        products: action.payload,
        totalItem: action.payload.length,
      };
    },
    selectInvoiceId: (state, action) => {
      return {
        ...state,
        invoiceId: action.payload,
      };
    },
    selcetBiller: (state, action) => {
      return { ...state, billerId: action.payload };
    },
    selcetCustomer: (state, action) => {
      return {
        ...state,
        customerId: action.payload.customerId,
        point: {
          old: action.payload.point,
          new: state.todayPoint + action.payload.point,
        },
        customerPhone: action.payload.phone,
        customerName: action.payload.name,
      };
    },
    selcetProductsCart: (state, action) => {
      return {
        ...state,
        products: action.payload,
      };
    },
    salePointUpdate: (state, action) => {
      const pointToday = Math.floor(state.grossTotalRound / 100);
      const newPoint =
        Number(state.point.old) + pointToday - state.paidAmount.point;
      return {
        ...state,

        point: {
          ...state.point,
          new: newPoint,
        },
        todayPoint: pointToday,
      };
    },

    selectCalculation: (state, action) => {
      return {
        ...state,
        // totalItem: Number(action.payload.totalItem),
        grossTotal:
          parseInt(Number(action.payload.total)) +
          parseInt(Number(action.payload.vatAmount)) -
          (parseInt(Number(action.payload.promoPrice)) > 0
            ? parseInt(Number(action.payload.promoPrice))
            : 0),
        total: parseInt(Number(action.payload.total)),
        totalReceived:
          parseInt(
            Number(action.payload.total) +
              parseInt(Number(action.payload.vatAmount))
          ) -
          (parseInt(Number(action.payload.promoPrice)) > 0
            ? parseInt(Number(action.payload.promoPrice))
            : 0),
        changeAmount: 0,
        // changeAmount: parseInt(Number(action.payload.total) + parseInt(Number(action.payload.vatAmount))) - (parseInt(Number(action.payload.promoPrice)) > 0 ? parseInt(Number(action.payload.promoPrice)) : 0),
        grossTotalRound:
          parseInt(
            Number(action.payload.total) +
              parseInt(Number(action.payload.vatAmount))
          ) -
          (parseInt(Number(action.payload.promoPrice)) > 0
            ? parseInt(Number(action.payload.promoPrice))
            : 0),
        vat: parseInt(Number(action.payload.vatAmount)),
        paidAmount: {
          ...state.paidAmount,
          cash:
            parseInt(
              Number(action.payload.total) +
                parseInt(Number(action.payload.vatAmount))
            ) -
            (parseInt(Number(action.payload.promoPrice)) > 0
              ? parseInt(Number(action.payload.promoPrice))
              : 0),
        },
        promo_discount: parseInt(Number(action.payload.promoPrice)),
      };
    },
    saleReset: () => initialState,
  },
});

export const {
  selectAllData,
  selectProducts,
  selcetBiller,
  selcetCustomer,
  selcetProductsCart,
  salePointUpdate,
  selectCalculation,
} = ecomSaleUpdateSlice.actions;

export const ecomSaleUpdateReducer = ecomSaleUpdateSlice.reducer;
