import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../logo.png";
import "./SideBar.css";

const AccountSideBar = () => {
  let active = window.location.pathname;
  return (
    <aside className="sticky-md-top pb-5">
      <Link className="nav-link logo pt-3 pb-5" to="/">
        <Image src={logo} fluid />
      </Link>
      <nav
        className="nav flex-column"
        style={{ overflowY: "scroll", display: "block", height: "100vh" }}
      >
        <>
          {/* DASHBOARD */}
          <Link
            className={`nav-link ${active === "/dashboard" && "active"}`}
            to="/dashboard"
          >
            <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
            Dashboard
          </Link>

          {/* SALES DW */}
          {/* <Link
            className={`nav-link ${
              (active === "/ecom" ||
                active === "/sales" ||
                active === "/customer") &&
              "active"
            }`}
            to="/sales"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.ShoppingCartOutline size={22} /> Sales
              </span>
              <span className="text-end">
                {active === "/ecom" ||
                active === "/sales" ||
                active === "/customer" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/ecom" ||
              active === "/sales" ||
              active === "/customer"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link pt-0 ${active === "/sales" && "active"}`}
                to="/sales"
              >
                <Icons.ShoppingCartOutline
                  size={18}
                ></Icons.ShoppingCartOutline>{" "}
                Sales
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${active === "/ecom" && "active"}`}
                to="/ecom"
              >
                <Icons.ShoppingCartOutline
                  size={18}
                ></Icons.ShoppingCartOutline>{" "}
                Ecom Sales
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${
                  active === "/customer" && "active"
                }`}
                to="/customer"
              >
                <Icons.UsersOutline size={18}></Icons.UsersOutline> Customer
              </Link>
            </li>
          </ul> */}

          {/* ACCOUNTS DW */}
          <Link
            className={`nav-link ${
              (active === "/accountheads" ||
                active === "/accounts" ||
                active === "/accounts" ||
                active === "/addAfterSale" ||
                active === "/addAccountExpense" ||
                active === "/addAccount" ||
                active === "/ledger" ||
                active === "/addAccountHead") &&
              "active"
            }`}
            to="/accounts"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.CurrencyDollarOutline size={22} /> Accounts
              </span>
              <span className="text-end">
                {active === "/accountheads" ||
                active === "/accounts" ||
                active === "/accounts" ||
                active === "/addAfterSale" ||
                active === "/addAccountExpense" ||
                active === "/addAccount" ||
                active === "/ledger" ||
                active === "/addAccountHead" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/accountheads" ||
              active === "/accounts" ||
              active === "/accounts" ||
              active === "/addAfterSale" ||
              active === "/addAccountExpense" ||
              active === "/addAccount" ||
              active === "/ledger" ||
              active === "/addAccountHead"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/accountheads" ||
                    active === "/addAccountHead") &&
                  "active"
                }`}
                to="/accountheads"
              >
                <Icons.BookmarkAltOutline size={18} /> Account Heads
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/accounts" ||
                    active === "/addAfterSale" ||
                    active === "/addAccountExpense" ||
                    active === "/addAccount") &&
                  "active"
                }`}
                to="/accounts"
              >
                <Icons.CurrencyBangladeshiOutline size={18} />
                Transactions
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${
                  active === "/payments" && "active"
                }`}
                to="/payments"
              >
                <Icons.CurrencyBangladeshiOutline size={18} /> Collection
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${active === "/ledger" && "active"}`}
                to="/ledger"
              >
                <Icons.ChartPieOutline size={18} /> Ledger
              </Link>
            </li>
          </ul>

          {/* procurement */}
          {/* <Link
            className={`nav-link ${
              (active === "/purchase" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
                active === "/create-supplier") &&
              "active"
            }`}
            to="/purchase"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.InboxInOutline size={22} /> Procurement
              </span>
              <span className="text-end">
                {active === "/purchase" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
                active === "/create-supplier" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/purchase" ||
              active === "/purchase-create" ||
              active === "/grn" ||
              active === "/grn-create" ||
              active === "/grn-details" ||
              active === "/grn-summary" ||
              active === "/rtv" ||
              active === "/rtv-create" ||
              active === "/rtv-details" ||
              active === "/rtv-summary" ||
              active === "/tpn" ||
              active === "/tpn-out" ||
              active === "/tpn-details" ||
              active === "/tpn-summary" ||
              active === "/damage" ||
              active === "/damagecreate" ||
              active === "/damage-details" ||
              active === "/damage-summary" ||
              active === "/supplier" ||
              active === "/create-supplier"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/purchase" || active === "/purchase-create") &&
                  "active"
                }`}
                to="/purchase"
              >
                <Icons.CurrencyDollarOutline
                  size={18}
                ></Icons.CurrencyDollarOutline>{" "}
                Purchase
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/grn" ||
                    active === "/grn-create" ||
                    active === "/grn-details" ||
                    active === "/grn-summary") &&
                  "active"
                }`}
                to="/grn"
              >
                <Icons.ClipboardCheckOutline size={18} /> GRN
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/rtv" ||
                    active === "/rtv-create" ||
                    active === "/rtv-details" ||
                    active === "/rtv-summary") &&
                  "active"
                }`}
                to="/rtv"
              >
                <Icons.ReceiptRefundOutline size={18} /> RTV
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/tpn" ||
                    active === "/tpn-out" ||
                    active === "/tpn-details" ||
                    active === "/tpn-summary") &&
                  "active"
                }`}
                to="/tpn"
              >
                <Icons.HomeOutline size={18}></Icons.HomeOutline> TPN
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/damage" ||
                    active === "/damagecreate" ||
                    active === "/damage-details" ||
                    active === "/damage-summary") &&
                  "active"
                }`}
                to="/damage"
              >
                <Icons.TrashOutline size={18} /> Damage
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/supplier" || active === "/create-supplier") &&
                  "active"
                }`}
                to="/supplier"
              >
                <Icons.UsersOutline size={18}></Icons.UsersOutline> Supplier
              </Link>
            </li>
          </ul> */}

          {/* <Link
            className={`nav-link ${active === "/exports" && "active"}`}
            to="/exports"
          >
            <Icons.DownloadOutline size={22} /> Exports
          </Link> */}

          {/* SETTINGS */}
          <Link
            className={`nav-link mt-0 ${active === "/profile" && "active"}`}
            to="/profile"
          >
            <Icons.CogOutline size={18} /> Profile Settings
          </Link>
        </>
      </nav>
    </aside>
  );
};

export default AccountSideBar;
