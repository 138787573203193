import React, { useEffect, useState } from "react";
import * as Icons from "heroicons-react";
import {
  // posFinalizer,
  itemVatTotal,
  itemVat,
  toDecimal,
} from "../../../Utility/PosCalculations";
import { useSelector, useDispatch } from "react-redux";
import PriceSelectByProduct from "../../../Common/CustomSelect/PriceSelectByProduct";
import { saleReturnProducts } from "../../../../features/posSlice";

const PosCart = ({
  addQuantities,
  removeQuantities,
  removeFromCart,
  handleCustomQty,
  updateCart,
  invoice,
  returnProducts,
  reCal,
}) => {
  let i = 1;
  let j = 1;
  // console.log(carts)
  const dispatch = useDispatch();
  const [tempQty, setTempQty] = useState([]);
  const [reTempQty, setReTempQty] = useState([]);
  const [scValue, setScValue] = useState("");
  const posSaleData = useSelector((state) => state.posReducer);
  const carts = useSelector((state) => state.posReducer.products);
  const reCarts = useSelector((state) => state.posReducer.returnProducts);

  // console.log(carts);

  // useEffect(() => {
  //   setScValue(carts.id);
  // }, [carts]);

  useEffect(() => {
    let cartTempQty = [];
    let reCartTempQty = [];
    carts?.map((item) => {
      cartTempQty = [...cartTempQty, { id: item.article_code, qty: item.qty }];
    });
    // console.log(cartTempQty);
    setTempQty(cartTempQty);
    reCarts?.map((item) => {
      reCartTempQty = [
        ...reCartTempQty,
        { id: item.article_code, qty: item.qty },
      ];
    });
    // console.log(cartTempQty);
    setTempQty(cartTempQty);
    setReTempQty(reCartTempQty);
  }, [carts, reCarts]);
  // console.log(carts);

  const handleOnChangeCategory = (id, e) => {
    console.log("id", id, "e", e);
    let ProductCart = JSON.parse(localStorage.getItem("pos_cart"));
    let selected = ProductCart.find((item) => item.id === id);
    let rest = ProductCart.filter((item) => item.id !== id);
    let newCart = [];
    newCart = [
      ...rest,
      {
        ...selected,
        priceId: e.option,
        mrp: e.mrp,
        tp: e.tp,
        stock: e.stock,
        supplier: e.supplier,
      },
    ];

    localStorage.setItem("pos_cart", JSON.stringify(newCart));
    console.log("e.option", e.option);
    updateCart();
  };

  const returnCustomQuantity = (e, old, id) => {
    if (reCarts) {
      let selectedItem = reCarts.find((p) => p.article_code === id);
      let restItem = reCarts.filter((p) => p.article_code !== id);

      console.log(reCarts, id, e.target.value);

      selectedItem = {
        ...selectedItem,
        qty: e.target.value > 0 ? e.target.value : 0,
      };
      restItem = [...restItem, selectedItem];

      console.log(restItem);
      const returnItems = restItem.sort((a, b) => a.order - b.order);

      // GET OLD QTY
      const selectedOldItem = invoice.products.filter(
        (pro) => pro.article_code === id
      );
      console.log(selectedOldItem[0].qty);

      if (selectedOldItem[0].qty >= e.target.value) {
        dispatch(saleReturnProducts(returnItems));
      } else {
        return;
      }
      // return localStorage.setItem("pos_cart", JSON.stringify(newCart))
      //   ? "true"
      //   : "false";
    }
  };
  // console.log(returnProducts);
  return (
    <>
      {/* {console.log(carts, reCarts)} */}
      {carts ? (
        carts?.map((cartItem, index) => (
          <tr key={cartItem?.article_code}>
            <th scope="row">{i++}</th>
            <td title={cartItem?.article_code}>{cartItem?.name}</td>
            {/* <td>{cartItem?.stock ? cartItem?.stock?.toFixed(2) : 0} pcs</td> */}

            <td>{!isNaN(Number(cartItem?.stock)) ? Number(cartItem.stock).toFixed(2) : "0.00"} pcs</td>
            
            <td>
              <PriceSelectByProduct
                sc={cartItem?.id}
                setVal={cartItem?.mrp}
                handleOnChangeCategory={handleOnChangeCategory}
              ></PriceSelectByProduct>
            </td>
            <td className="col-md-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div
                    onClick={() => removeQuantities(cartItem.article_code)}
                    className="input-group-text"
                  >
                    <Icons.Minus size="28" />
                  </div>
                </div>
                <input
                  type="number"
                  className="form-control quantity"
                  id={cartItem?.article_code}
                  onChange={(e) =>
                    handleCustomQty(e, index, cartItem?.article_code)
                  }
                  value={
                    tempQty[index]?.qty ? tempQty[index]?.qty : cartItem?.qty
                  }
                />
                <div className="input-group-append">
                  <div
                    onClick={() => addQuantities(cartItem.article_code)}
                    className="input-group-text"
                  >
                    <Icons.Plus size="28" />
                  </div>
                </div>
              </div>
            </td>
            <td>
              {toDecimal(
                parseFloat(itemVat(cartItem?.vat, cartItem?.qty, cartItem?.mrp))
              )}
            </td>
            <td>
              {toDecimal(
                itemVatTotal(cartItem?.vat, cartItem?.qty, cartItem?.mrp)
              )}
              <Icons.X
                className="float-end"
                onClick={() => removeFromCart(cartItem.article_code)}
              />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <th scope="row" colSpan="5">
            <p className="text-center">No Item in Cart</p>
          </th>
        </tr>
      )}
      {posSaleData.returnProducts?.length > 0 && (
        <>
          <tr>
            <th className="border-bottom-1 border-top-1" colSpan={7}>
              <br />
              <b>Return Products</b>
            </th>
          </tr>
          {reCarts?.map((reItem, index) => (
            <tr key={reItem?.article_code}>
              <th scope="row">{j++}</th>
              <td title={reItem?.article_code}>{reItem?.name}</td>
              <td> - </td>
              <td> {reItem?.mrp} </td>
              <td className="col-md-2">
                <input
                  type="number"
                  className="form-control quantity"
                  id={reItem?.article_code}
                  onChange={(e) =>
                    returnCustomQuantity(
                      e,
                      reTempQty[index]?.qty,
                      reItem.article_code
                    )
                  }
                  value={
                    reTempQty[index]?.qty ? reTempQty[index]?.qty : reItem.qty
                  }
                />
                {/* {reItem?.qty} */}
              </td>
              <td>
                {parseFloat(
                  itemVat(reItem?.vat, reItem?.qty, reItem?.mrp)
                )?.toFixed(2)}
              </td>
              <td>
                {toDecimal(itemVatTotal(reItem?.vat, reItem?.qty, reItem?.mrp))}
              </td>
            </tr>
          ))}
          <tr>
            <th></th>
            <th className="border-bottom-1 border-top-1">
              <br />
              <b>Item No:</b> {posSaleData?.returnCal?.totalItem}
            </th>
            <td>
              <br />
              <b>Total:</b> {posSaleData?.returnCal?.total?.toFixed(2)}
            </td>
            <td>
              <br />
              <b>Vat:</b> {posSaleData?.returnCal?.vat?.toFixed(2)}
            </td>
            <td>
              <br />
              <b>Gross Total:</b>{" "}
              {parseFloat(posSaleData?.returnCal?.grossTotal)?.toFixed(2)}
            </td>
            <td>
              <br />
              <b>Round Total:</b>{" "}
              {posSaleData?.returnCal?.grossTotalRound?.toFixed(2)}
            </td>
            <td>
              <br />
              <b>Point:</b> {posSaleData?.returnCal?.point}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default PosCart;
