import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Inventory.css";
import * as Icons from "heroicons-react";
import { ProgressBar, Table } from "react-bootstrap";
import CsvImporterInventory from "../Common/CsvImporter/CsvImporterInventory";
// import { useCSVReader } from "reactparse";
import axios from "axios";
import {
  useAddInventoryMutation,
  useAddInventoryPriceMutation,
  useInventoryByArticleQuery,
  useUpdateInventoryMutation,
} from "../../services/inventoryApi";
import { notify } from "../Utility/Notify";
import { Papa, usePapaParse } from "react-papaparse";
// import useInventory from '../Hooks/useInventory';
// let selected = {};
const InventoryImport = () => {
  const [file, setFile] = useState(null);
  const [csvfile, setCsvFile] = useState(null);
  // const { Papa } = usePapaParse();
  const { readString } = usePapaParse();
  // const [useAddInventoryPrice] = useAddInventoryPriceMutation();
  const [updateInventory] = useUpdateInventoryMutation();
  const [addInventory] = useAddInventoryMutation();
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  // const {data, error,isSuccess} = useInventoryByArticleQuery()

  let i = 0;
  const [inventories, setInventories] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setCurrent(0);
    let invData = [];
    csvData?.length > 0 &&
      csvData?.map((inv) => {
        invData = [
          ...invData,
          {
            name: inv?.name,
            warehouse: inv?.warehouse,
            article_code: inv?.article_code,
            priceId: inv?.priceId,
            qty: inv?.qty,
          },
        ];
      });

    setInventories(invData);
  }, [csvData]);

  console.log("Upload file", file);
  // console.log(csvData);
  const importInventory = async (data) => {
    const { article_code, qty, priceId, name, warehouse } = data;
    let inventory = {};
    const success = await axios.get(
      `${BASE_URL}inventory/article_code/${article_code}`
    );
    console.log(success);
    // inventory = { ...success?.data };
    console.log(success?.data?.priceTable?.length);
    console.log(success?.data?.priceTable);

    console.log(success?.data?.article_code);
    if (success.data !== "") {
      // INVENTORY EXIST

      if (success?.data?.priceTable?.length > 0) {
        // CHECK IF PRICE TABLE EXIST
        const checked = success?.data?.priceTable?.filter(
          (p) => p.id === priceId
        );
        const rest = success?.data?.priceTable?.filter((p) => p.id !== priceId);
        console.log("selected", checked);
        // console.log("rest", rest);
        if (checked?.length > 0) {
          console.log(checked, "exist", qty, Number(checked[0]?.currentQty));
          inventory = {
            ...success?.data,
            priceTable: [
              ...rest,
              {
                id: priceId,
                currentQty: Number(checked[0]?.currentQty) + Number(qty),
                openingQty: Number(checked[0]?.openingQty) + Number(qty),
                totalQty: Number(checked[0]?.totalQty) + Number(qty),
                soldQty: 0,
                damageQty: 0,
                rtvQty: 0,
                tpnQty: 0,
              },
            ],
            currentQty: Number(success?.data?.currentQty) + Number(qty),
            openingQty: Number(success?.data?.openingQty) + Number(qty),
            totalQty: Number(success?.data?.totalQty) + Number(qty),
            soldQty: 0,
            damageQty: 0,
            rtvQty: 0,
            tpnQty: 0,
          };
          const update = await updateInventory(inventory);
          console.log("updateInventory", update);
          console.log(inventory);
        } else {
          console.log("create Inventory price");
          inventory = {
            ...success?.data,
            priceTable: [
              ...rest,
              {
                id: priceId,
                currentQty: Number(qty),
                openingQty: Number(qty),
                totalQty: Number(qty),
                soldQty: 0,
                damageQty: 0,
                rtvQty: 0,
                tpnQty: 0,
              },
            ],
            currentQty: Number(success?.data?.currentQty) + Number(qty),
            openingQty: Number(success?.data?.openingQty) + Number(qty),
            totalQty: Number(success?.data?.totalQty) + Number(qty),
            soldQty: 0,
            damageQty: 0,
            rtvQty: 0,
            tpnQty: 0,
          };
          // console.log("create price", inventory);
          const update = await updateInventory(inventory);
          console.log("updateInventory", update);
          console.log(inventory);
        }

        // console.log(typeof success.data.openingQty);
        // console.log(typeof Number(qty));
      } else {
        // INVENTORY PRICE IS NOT EXIST
        // WRKING/
        // CREATE NEW INVENTORY
        inventory = {
          ...success?.data,
          priceTable: [
            {
              id: priceId,
              currentQty: Number(qty),
              openingQty: Number(qty),
              totalQty: Number(qty),
              soldQty: 0,
              damageQty: 0,
              rtvQty: 0,
              tpnQty: 0,
            },
          ],
          currentQty: Number(qty),
          openingQty: Number(qty),
          totalQty: Number(qty),
          soldQty: 0,
          damageQty: 0,
          rtvQty: 0,
          tpnQty: 0,
          status: "active",
        };

        // console.log(inventory);
      }
      const update = await updateInventory(inventory);
      console.log("updateInventory", update);
      console.log(inventory);
    } else {
      inventory = {
        name: name,
        article_code: article_code,
        warehouse: "62b5b575b4facb87eef3b47c",
        currentQty: Number(qty),
        openingQty: Number(qty),
        totalQty: Number(qty),
        soldQty: 0,
        damageQty: 0,
        rtvQty: 0,
        tpnQty: 0,
        status: "active",
        priceTable: [
          {
            id: priceId,
            openingQty: Number(qty),
            currentQty: Number(qty),
            totalQty: Number(qty),
            soldQty: 0,
            damageQty: 0,
            rtvQty: 0,
            tpnQty: 0,
          },
        ],
      };
      const add = await addInventory(inventory);
      console.log("add-Inventory", add);
    }
  };

  // HANDLE IMPORT BUTTON
  // const handleImportButton = async () => {
  //   let counter = 0;
  //   // console.log(inventories?.length);
  //   const timer = setInterval(function () {
  //     importInventory(inventories[counter]);
  //     // console.log(inventories[counter]?.article_code);
  //     ++counter;
  //     console.log("length", counter, inventories?.length);
  //     setCurrent(counter);

  //     if (counter >= inventories?.length) {
  //       clearInterval(timer);
  //     }
  //   }, 150);
  // };
  // const { file, data } = useCSVReader({
  //   onError: (err) => console.error("CSV Error:", err),
  //   onLoad: (data, file) => {
  //     setCsvFile(file);
  //     setCsvData(file);

  //     console.log("CSV Loaded:", data);
  //   },
  // });

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(e.target.files[0]);

    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      parseCSVToJSON(text);
    };
    reader.readAsText(uploadedFile);
  };

  const parseCSVToJSON = (csvText) => {
    readString(csvText, {
      header: true,
      worker: true,
      complete: (results) => {
        console.log("---------------------------");
        console.log(results);
        setCsvData(results?.data);

        console.log("---------------------------");
      },
    });
    // Papa.parse(csvText, {
    //   header: true,
    //   complete: (result) => {
    //     setCsvData(result.data);
    //   },
    // });
  };

  // HANDLE IMPORT
  const handleUpload = async () => {
    const confirm = window.confirm(
      "Confirm Inport Inventory? this may update your real inventory"
    );
    if (confirm) {
      const formData = new FormData();
      formData.append("csv", file);

      try {
        console.log("Uploading File.");
        await axios.post(`${BASE_URL}inventory/import`, formData);
        console.log("CSV file uploaded and processing initiated.");
      } catch (error) {
        console.error("Error uploading CSV:", error);
      }
    } else {
      console.log("cencel");
    }
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Products Inventory Import"></Header>
            <div className="card p-5 my-3 px-5">
              <div className="row gap-0">
                <div class=" col-10">
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    // {...file.input}
                    onChange={handleFileChange}
                  />
                </div>
                <div className="col-2">
                  <div class="d-grid ">
                    <button
                      class="btn btn-dark"
                      type="button"
                      onClick={handleUpload}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <CsvImporterInventory
              setCsvData={setCsvData}
              csvData={csvData}
              title="Inventory by price"
              handleImportButton={handleImportButton}
            ></CsvImporterInventory> */}
            {/* ProgressBAr */}
            {/* <ProgressBar
              striped
              variant="success"
              now={
                current > 0
                  ? csvData?.length > 0
                    ? Math.fround((100 / csvData?.length) * current)?.toFixed(2)
                    : 0
                  : 0
              }
              label={`${
                current > 0
                  ? csvData?.length > 0
                    ? Math.fround((100 / csvData?.length) * current)?.toFixed(2)
                    : 0
                  : 0
              }%  - ${current} of ${csvData?.length}`}
            /> */}
            <Table hover className="mt-5">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Article Code</th>
                  <th scope="col">Price ID</th>
                  <th scope="col">Qty</th>
                  <th scope="col">User</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  csvData?.length > 0 ? (
                    csvData.map((inventory, index) => (
                      <tr key={index}>
                        {/* {console.log("-loop", inventory)} */}
                        <th scope="row">{++i}</th>
                        <td>{inventory?.article_code}</td>
                        <td>{inventory?.priceId}</td>
                        <td>{inventory?.qty}</td>
                        <td>{inventory?.user}</td>
                        <td>{inventory?.date}</td>
                        <td>{inventory?.time}</td>
                        <td className="text-center">
                          {/* <Link to={`/inventory/${user._id}`}> </Link> */}
                          {inventory?.status === "active" ? (
                            <Icons.CheckOutline
                              className="icon-eye text-success"
                              size={22}
                            ></Icons.CheckOutline>
                          ) : (
                            <Icons.ClockOutline
                              className="icon-trash text-warning"
                              size={22}
                            ></Icons.ClockOutline>
                          )}
                          {/* <Link to={`/user/update/${user._id}`}> */}
                          {/* <Icons.PencilAltOutline className="icon-edit" size={22} ></Icons.PencilAltOutline> */}
                          {/* </Link> */}
                          {/* <Icons.TrashOutline className="icon-trash"  size={22} ></Icons.TrashOutline> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )
                  //   csvData?.map((inventory) => (
                  //     <tr key={inventory?._id}>
                  //       {/* {console.log("-loop", inventory)} */}
                  //       <th scope="row">{i++}</th>
                  //       <td>{inventory?.article_code}</td>
                  //       <td>{inventory?.name}</td>
                  //       <td>0</td>
                  //       <td>0</td>
                  //       <td>0</td>
                  //       <td>0</td>
                  //       <td className="text-center">
                  //         {/* <Link to={`/inventory/${user._id}`}> </Link> */}
                  //         {inventory?.status === true ? (
                  //           <Icons.CheckOutline
                  //             className="icon-eye text-success"
                  //             size={22}
                  //           ></Icons.CheckOutline>
                  //         ) : (
                  //           <Icons.ClockOutline
                  //             className="icon-trash text-warning"
                  //             size={22}
                  //           ></Icons.ClockOutline>
                  //         )}
                  //       </td>
                  //     </tr>
                  //   ))
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryImport;
