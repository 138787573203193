import React, { useEffect, useState } from "react";
// import { Table } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import * as Icons from "heroicons-react";
import { useNavigate, useParams } from "react-router-dom";
import CategorySelectByMC from "../Common/CustomSelect/categorySelectByMC";
import SelectBrand from "../Common/CustomSelect/selectBrand";
import SelectMC from "../Common/CustomSelect/selectMC";
import SelectUnit from "../Common/CustomSelect/selectUnit";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import productPhoto from "../../product.jpg";
import { notify } from "../Utility/Notify";
import {
  useUpdateProductMutation,
  useProductQuery,
  useProductPromoQuery,
} from "../../services/productApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
// import format from "date-fns/format";
// import PriceSelectByProduct from "../Common/CustomSelect/PriceSelectByProduct";
// import PriceSelectByProductPromoPrice from "../Common/CustomSelect/PriceSelectByProductPromoPrice";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { useDispatch } from "react-redux";
import { selectUpdateProduct } from "../../features/productPriceSlice";
// import { DevTool } from "@hookform/devtools";
// import Toast from "../Common/Toast/Toast";
import { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";

const UpdateProducts = () => {
  const BASE_URL =
    process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  const PHOTO_BASE_URL =
    process.env.REACT_APP_PHOTO_URL || "http://localhost:5001";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [product, setProduct] = useState([]);
  // const [pp, setPP] = useState("");
  const [mc, setMc] = useState("");
  const [sc, setSc] = useState("");
  const [scValue, setScValue] = useState("");
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [promoType, setPromoType] = useState(false);

  const [fileName, setFileName] = useState("Upload Category Photo");
  const [file, setFile] = useState({});
  const [uploadFile, setUploadedFile] = useState({});
  // const [uploadPercentage, setUploadPercentage] = useState(0);
  // const [message, setMessage] = useState("");
  const [spp, setSpp] = useState({});
  // const [promoPriceID, setPromoPriceID] = useState("")

  const [isEANUnique, setIsEANUnique] = useState(true);
  const [isArticleCodeUnique, setIsArticleCodeUnique] = useState(true);
  // const [preLoadValue, setPreLoadValue] = useState({});
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useProductPromoQuery(`${id}`);
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const [priceModal, setPriceModal] = useState(false);

  const eanValue = watch("ean"); 
  const articleCodeValue = watch("article_code");
  // console.log(promoType);

  useEffect(() => {
    const isEANValid = eanValue && eanValue?.length === 13;
    if (isEANValid) checkEan(eanValue);

    const isAcValid = articleCodeValue && articleCodeValue?.length === 7;
    if (isAcValid) checkAC(articleCodeValue);
  }, [eanValue, articleCodeValue]);

  const checkEan = async (ean) => {
    console.log(ean);
    try {
      const eanData = await axios.get(`${BASE_URL}product/update/uniqueEan/${ean}/${id}`);
      const { eanExists } = eanData.data; // Correct property name
      if (eanExists) {
        setIsEANUnique(false);
        setError("ean", { message: "EAN code already exists!" });
      } else {
        setIsEANUnique(true);
        clearErrors("ean");
      }
    } catch (error) {
      console.error("Error checking EAN code:", error);
    }
  };

  const checkAC = async (ac) => {
    try {
      console.log(ac);
      const acData = await axios.get(`${BASE_URL}product/update/uniqueAc/${ac}/${id}`);
      const { acExists } = acData.data; // Ensure this matches the backend response

      if (acExists) {
        setIsArticleCodeUnique(false);
        setError("article_code", { message: "Article code already exists!" });
      } else {
        setIsArticleCodeUnique(true);
        clearErrors("article_code");
      }
    } catch (error) {
      console.error("Error checking Article Code:", error);
    }
  };

  const handlePriceModalClose = () => {
    setPriceModal(false);
    refetch();
  };
  // console.log(data?.promo_type)

  // console.log(data);
  // console.log("pp", pp);
  // console.log(data?.promo_price?.mrp);
  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   control,
  //   formState: { errors },
  // } = useForm({});

  useEffect(() => {
    if (data) {
      reset({
        _id: data._id,
        name: data.name,
        ean: data.ean,
        article_code: data.article_code,
        master_category: data.master_category,
        category: data.category,
        product_type: data.product_type,
        priceList: data.priceList,
        brand: data.brand,
        slug: data.slug,
        details: data.details,
        unit: data.unit,
        alert_quantity: data.alert_quantity,
        vat: data.vat,
        vat_method: data.vat_method,
        featured: data.featured,
        shipping_method: data.shipping_method,
        hide_website: data.hide_website,
        // promo_price: data?.promo_price?._id,
        // checked: data?.isPercentage,
        promo_price: data?.promo_price,
        promo_type: promoType,
        promo_start: data?.promo_start
          ? new Date(data?.promo_start)
          : new Date(),
        promo_end: data?.promo_end ? new Date(data?.promo_end) : new Date(),
        specification: data?.specification,
        // photo: data?.photo ? data?.photo : "",
        status: data?.status,
      });
      setMc(data?.master_category);
      setBrand(data?.brand);
      setScValue(data?.category?._id);
      setUnit(data?.unit);
      setPromoType(data?.promo_type === undefined ? false : data?.promo_type);
      // setChecked(data?.promo_type === undefined ? false : data?.promo_type)
      setEndDate(data?.promo_end ? new Date(data?.promo_end) : new Date());
      setStartDate(
        data?.promo_start ? new Date(data?.promo_start) : new Date()
      );
      setSpp({
        label: `${
          data?.promo_price?.mrp === undefined
            ? "No Price"
            : data?.promo_price?.mrp
        } BDT`,
        option: data?.promo_price?._id,
      });
    }
    setUploadedFile({
      fileName: data?.name,
      filePath: data?.photo ? data?.photo : productPhoto,
    });
  }, [data, dispatch]);
  // console.log(uploadFile);

  const [updateProduct] = useUpdateProductMutation();

  const onSubmit = async (data) => {
    console.log("data form", data);

    const response = await updateProduct(data);
    // const formData = new FormData();
    // formData.append("file", data.file[0]);
    console.log(response);
    if (response) {
      console.log("Update Product SuccessFull");

      // console.log(response);
      notify("Product Update Successful!", "success");
      navigate("/product");
    } else {
      notify("Product Update Filed!", "error");
    }
  };

  // SUPPLIER SELECT HANDLE
  const handleOnchangeMasterCategory = (e) => {
    // console.log(e)
    if (e.label) {
      let mId = e.label.split(" - ");

      setSc(mId[1]);
    }
    setMc(e.option);
    setValue("master_category", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // reset({
    //   master_category: e.option,
    // });
  };

  //category
  const handleOnchangeCategory = (e) => {
    setScValue(e.option);
    setValue("category", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // reset({
    //   category: e.option,
    // });
  };

  //Brand
  const handleOnChangeBand = (e) => {
    setBrand(e.option);
    setValue("brand", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleOnchangeUnit = (e) => {
    // console.log(e);
    setUnit(e.option);
    setValue("unit", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePromoStart = (date) => {
    setStartDate(date);
    setValue("promo_start", date, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePromoEnd = (date) => {
    setEndDate(date);
    setValue("promo_end", date, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleProductPhoto = async (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const handleProductPhotoUpload = async (e) => {
    e.preventDefault();
    // console.log(e);
    const formData = new FormData();
    formData.append("file", file);
    console.log(file);
    try {
      const res = await axios.post(
        `${BASE_URL}product/upload/${id}`,
        formData,
        {
          "Content-type": "multipart/form-data",
        }
      );

      const { fileName, filePath } = res.data;
      dispatch(selectUpdateProduct(filePath));
      // setUploadedFile({ fileName, filePath });
    } catch (err) {
      if (err.response.status === 500) {
        console.log(
          "There was an problem with the server",
          err.response.data.message
        );
      } else {
        console.log(err.response.data.message);
      }
    }
  };
  const handleOnChangePP = (e) => {
    setSpp(e);
    setValue("promo_price", e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handlePriceModalShow = () => {
    setPriceModal(true);
  };

  const handlePromoType = (e) => {
    setPromoType(!promoType);
    setValue("promo_type", !promoType, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Update Product"></Header>
            <div className="row  mt-3">
              <div className="col-md-8">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  // onSubmit={() => handleSubmit(handelUpdateProduct)}
                  className="mt-4"
                >
                  {/* 1st row */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="masterCategory" className="form-label">
                          Master Category
                        </label>
                        <input
                          type="hidden"
                          placeholder="Master Category"
                          id="masterCategory"
                          aria-describedby="masterHelp"
                          className="form-control"
                          {...register("master_category", { required: true })}
                        />
                        <SelectMC
                          mc={mc}
                          handleOnchange={(e) => {
                            handleOnchangeMasterCategory(e);
                          }}
                        ></SelectMC>
                        <div id="masterHelp" className="form-text">
                          Product Master Category
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="ean" className="form-label">
                          EAN Code{" "}
                        </label>
                        <input
                          placeholder="EAN CODE"
                          className="form-control"
                          id="ean"
                          aria-describedby="eanHelp"
                          {...register("ean", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                         {!isEANUnique && (
                          <span className="text-danger">
                            EAN code already exists!
                          </span>
                        )}
                        <div id="eanHelp" className="form-text">
                          International EAN CODE
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Product Type
                        </label>
                        <select
                          className="form-select"
                          {...register("product_type")}
                        >
                          <option value="standard">Standard</option>
                          <option value="combo">combo</option>
                          <option value="offer">Offer</option>
                        </select>
                        {/* <input placeholder='Product Type' className="form-control" id="p_type" aria-describedby="typeHelp" {...register("type", { required: true, maxLength: 20 })} /> */}
                        <div id="typeHelp" className="form-text">
                          Single or Combo Product
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd row */}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_name">
                          Name
                        </label>
                        <input
                          placeholder="Product name"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          {...register("name", { required: true })}
                        />
                        <div id="nameHelp" className="form-text">
                          Product Name with pack size
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_article_code">
                          Article Code
                        </label>
                        <input
                          placeholder="Article Code"
                          className="form-control"
                          id="p_article_code"
                          aria-describedby="article_codeHelp"
                          {...register("article_code", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        {!isArticleCodeUnique && (
                          <span className="text-danger">
                            Article code already exists!
                          </span>
                        )}
                        <div id="article_codeHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3rd row  */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_brand">
                          Brand
                        </label>
                        <input
                          placeholder="Brand"
                          type="hidden"
                          className="form-control"
                          id="p_brand"
                          aria-describedby="brandHelp"
                          {...register("brand", {
                            maxLength: 20,
                          })}
                        />

                        <SelectBrand
                          brand={brand}
                          handleOnchange={(e) => handleOnChangeBand(e)}
                        />

                        <div id="brandHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_category">
                          Category
                        </label>
                        <input
                          placeholder="Category"
                          type="hidden"
                          className="form-control"
                          id="p_category"
                          aria-describedby="categoryHelp"
                          {...register("category", {
                            required: true,
                          })}
                        />
                        <CategorySelectByMC
                          scValue={scValue}
                          sc={sc}
                          handleOnChangeCategory={(e) =>
                            handleOnchangeCategory(e)
                          }
                        />
                        <div id="categoryHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_slug">
                          Slug
                        </label>
                        <input
                          type="text"
                          placeholder="Slug"
                          className="form-control"
                          id="p_slug"
                          aria-describedby="slugHelp"
                          {...register("slug")}
                        />
                        <div id="slugHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 4th row  */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_unit_code">
                          Product Unit
                        </label>
                        {/* <input placeholder='Product Unit' className="form-control" id="p_unit_code" aria-describedby="unit_codeHelp" {...register("unit_code", { maxLength: 20 })} /> */}
                        <SelectUnit
                          unit={unit}
                          handleOnchange={(e) => handleOnchangeUnit(e)}
                        ></SelectUnit>
                        <div id="unit_codeHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_slug">
                          Alert Quantity
                        </label>
                        <input
                          type="number"
                          placeholder="Alert Quantity"
                          className="form-control"
                          id="p_slug"
                          aria-describedby="slugHelp"
                          {...register("alert_quantity", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        <div id="slugHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 6th row  */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat">
                          VAT
                        </label>
                        <input
                          type="number"
                          placeholder="VAT"
                          className="form-control"
                          id="p_vat"
                          aria-describedby="vatHelp"
                          {...register("vat", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        <div id="vatHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat_method">
                          Vat Method
                        </label>
                        {/* <input placeholder='Vat Method' className="form-control" id="p_vat_method" aria-describedby="vat_methodHelp" {...register("vat_method", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          {...register("vat_method")}
                        >
                          <option value="true">Include</option>
                          <option value="false">Exclude</option>
                        </select>
                        <div id="vat_methodHelp" className="form-text">
                          Vat Include or Not
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Shipping Method
                        </label>
                        {/* <input placeholder='shipping' className="form-control" id="p_shipping" aria-describedby="shippingHelp" {...register("shipping", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          {...register("shipping")}
                        >
                          <option value="cod">COD</option>
                          <option value="free">Free Shipping</option>
                          <option value="uttara">Inside Uttara</option>
                        </select>
                        <div id="shippingHelp" className="form-text">
                          What is the delivery method?
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 7th row */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Featured
                        </label>
                        <select
                          className="form-select"
                          {...register("featured")}
                        >
                          <option value="true">Featured</option>
                          <option value="false">Non-Featured</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("featured")} id="featured" /> */}
                        <div id="featuredHelp" className="form-text">
                          Is this product featured on E-commerce?
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Web Site
                        </label>
                        <select
                          className="form-select"
                          {...register("hide_website")}
                        >
                          <option value="true">Show Web Site</option>
                          <option value="false">Hide Web Site</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("hide_website")} id="hide_on_website" /> */}

                        <div id="hide_on_websiteHelp" className="form-text">
                          Is this product hide on E-commerce?
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Status
                        </label>
                        <select className="form-select" {...register("status")}>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("hide_website")} id="hide_on_website" /> */}

                        <div id="hide_on_websiteHelp" className="form-text">
                          Is this product hide on E-commerce?
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 8th row */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_product_details"
                        >
                          Product Description
                        </label>
                        <textarea
                          placeholder="Product Description"
                          className="form-control"
                          id="p_product_details"
                          aria-describedby="product_detailsHelp"
                          {...register("details", { required: false })}
                        />
                        <div id="product_detailsHelp" className="form-text">
                          Product Details for E-commerce
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_product_specification"
                        >
                          Product Specification
                        </label>
                        <textarea
                          placeholder="Product Specification"
                          className="form-control"
                          id="p_product_specification"
                          aria-describedby="product_specificationHelp"
                          {...register("specification", { required: false })}
                        />
                        <div
                          id="product_specificationHelp"
                          className="form-text"
                        >
                          Product Details for E-commerce
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 9th row  */}
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3 ">
                        <div className="d-flex justify-content-around ">
                          <div>
                            <label
                              className="form-label"
                              htmlFor="p_promotional_price"
                            >
                              Promo Price{" "}
                            </label>
                            <input
                              type="number"
                              placeholder="Promotional Price"
                              className="form-control"
                              id="p_promotional_price"
                              aria-describedby="promotional_priceHelp"
                              {...register("promo_price", { required: false })}
                            />
                          </div>
                          <div className="d-flex justify-content-around align-items-end p-1">
                            <Form.Check
                              type="checkbox"
                              checked={promoType ? "checked" : ""}
                              // checked={data?.promo_type === undefined ? false : data?.promo_type}
                              id="custom-switch"
                              onChange={() => handlePromoType()}
                              label=""
                            />
                            <label
                              className="form-check-label text-sm"
                              for="flexCheckDefault"
                            >
                              Percentage
                            </label>
                          </div>
                        </div>
                        {/* <PriceSelectByProductPromoPrice
                          data={data}
                          handleOnChangePP={handleOnChangePP}
                          spp={spp}
                        /> */}

                        {/* <div id="promotional_priceHelp" className="form-text">
                          Product Cost{" "}
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotion_start"
                        >
                          Promo Starts
                        </label>
                        <DatePicker
                          className="form-control"
                          selected={new Date(startDate)}
                          {...register("promo_start", { required: false })}
                          onChange={handlePromoStart}
                        />
                        <div id="promotion_startHelp" className="form-text">
                          Product Price
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotion_ends"
                        >
                          Promo Ends
                        </label>
                        <DatePicker
                          className="form-control"
                          selected={endDate}
                          {...register("promo_end", { required: false })}
                          onChange={handlePromoEnd}
                        />
                        <div id="promotion_endsHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_promotion_ends">Image</label>
                        <input type="file" className="form-control" {...register("file")} />
                        <div id="promotion_endsHelp" className="form-text">Article code start with Master Category id</div>
                      </div>
                    </div> */}
                    <div className="col-md-1">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotion_ends"
                        >
                          Price
                        </label>
                        <Icons.CurrencyBangladeshiOutline
                          onClick={() => {
                            handlePriceModalShow();
                          }}
                        ></Icons.CurrencyBangladeshiOutline>
                      </div>
                    </div>
                  </div>

                  {/* <input
                    type="submit"
                    // onClick={handleSubmit(handelUpdatProduct)}
                    className="btn btn-dark"
                  // onClick={console.log("Click")}
                  >
                   
                  </input> */}
                  <input
                    type="submit"
                    className="btn btn-dark"
                    onClick={console.log("Click")}
                    disabled={!isEANUnique || !isArticleCodeUnique}
                  />
                  {/* <input className="btn btn-dark" type="submit" onClick={() => console.log("clicked")} /> */}
                </form>
              </div>
              <div className="col-md-4">
                <Form onSubmit={(e) => handleProductPhotoUpload(e)}>
                  <div className="card">
                    <img
                      className="card-img-top"
                      // {data?.photo !== null ?}
                      src={
                        data?.photo
                          ? `${PHOTO_BASE_URL}${uploadFile.filePath}`
                          : productPhoto
                      }
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="text-wrap w-100">
                          {data?.name}
                        </Form.Label>
                        <Form.Control
                          name="c_photo"
                          type="file"
                          onChange={(e) => handleProductPhoto(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="card-footer">
                      <div className="d-grid gap-2">
                        <Button type="submit" variant="dark" size="lg">
                          <Icons.UploadOutline size={20}></Icons.UploadOutline>{" "}
                          Save Photo{" "}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
      <ProductPriceModal
        show={priceModal}
        handleClose={handlePriceModalClose}
        productId={data?._id}
      ></ProductPriceModal>
      {/* <DevTool control={control} placement="top-left" /> */}
    </div>
  );
};

export default UpdateProducts;
