import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CategorySelectByMC from "../Common/CustomSelect/categorySelectByMC";
import SelectBrand from "../Common/CustomSelect/selectBrand";
import SelectMC from "../Common/CustomSelect/selectMC";
import SelectUnit from "../Common/CustomSelect/selectUnit";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import productPhoto from "../../product.jpg";
import { useAddProductMutation } from "../../services/productApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from "../Common/Modal/LoadingModal";
import { DevTool } from "@hookform/devtools";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import { signInUser } from "../Utility/Auth";
import axios from "axios";

const AddProduct = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const [mc, setMc] = useState("");
  const [sc, setSc] = useState("");
  const [scValue, setScValue] = useState("");
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [AddProduct] = useAddProductMutation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isEANUnique, setIsEANUnique] = useState(true);
  const [isArticleCodeUnique, setIsArticleCodeUnique] = useState(true);
  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);
  const auth = signInUser();

  const eanValue = watch("ean"); // Watches the 'ean' field value
  const articleCodeValue = watch("article_code");

  const BASE_URL =
    process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

// check  ean and article lenth values
  useEffect(() => {
    const isEANValid = eanValue && eanValue?.length === 13;
    if (isEANValid) checkEan(eanValue);

    const isAcValid = articleCodeValue && articleCodeValue?.length === 7;
    if (isAcValid) checkAC(articleCodeValue);
  }, [eanValue, articleCodeValue]);

// check EAN Code Value
  const checkEan = async (ean) => {
    console.log(ean);
    try {
      const eanData = await axios.get(`${BASE_URL}product/uniqueEan/${ean}`);
      const { eanExists } = eanData.data; // Correct property name
      if (eanExists) {
        setIsEANUnique(false);
        setError("ean", { message: "EAN code already exists!" });
      } else {
        setIsEANUnique(true);
        clearErrors("ean");
      }
    } catch (error) {
      console.error("Error checking EAN code:", error);
    }
  };

  // check Article Code Value
  const checkAC = async (ac) => {
    try {
      console.log(ac);
      const acData = await axios.get(`${BASE_URL}product/uniqueAc/${ac}`);
      const { acExists } = acData.data; // Ensure this matches the backend response

      if (acExists) {
        setIsArticleCodeUnique(false);
        setError("article_code", { message: "Article code already exists!" });
      } else {
        setIsArticleCodeUnique(true);
        clearErrors("article_code");
      }
    } catch (error) {
      console.error("Error checking Article Code:", error);
    }
  };

  // console.log(eanValue, articleCodeValue)

  const handelAddProduct = async (data) => {
    setLoader(true);
    try {
      // Assuming `AddProduct` handles the API request to add a product
      const response = await AddProduct({
        ...data,
        // Add warehouse data here if it's part of the form data
        warehouse: auth?.warehouse,
      });

      console.log("data", data);

      if (response) {
        console.log(response);
        if (response?.error) {
          const check = apiUniqueErrHandle(response);
          if (!check.status) {
            for (const key in check.option) {
              notify(`${key}: ${check.option[key]} is not unique`, "error");
            }
          }
        } else {
          // Reset the form fields
          reset({
            _id: "",
            name: "",
            ean: "",
            article_code: "",
            master_category: "",
            category: "",
            product_type: "",
            priceList: "",
            brand: "",
            slug: "",
            details: "",
            unit: "",
            alert_quantity: "",
            vat: "",
            vat_method: "",
            featured: "",
            shipping_method: "",
            hide_website: "",
            promo_price: "",
            promo_start: "",
            promo_end: "",
            photo: "",
            status: "",
            warehouse: "", // Include warehouse in the reset
          });

          // Show success message
          notify(
            response?.data?.message || "Product added successfully!",
            "success"
          );
          return navigate("/product");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  // SUPPLIER SELECT HANDLE
  const handleOnchangeMasterCategory = (e) => {
    if (e?.label) {
      let mId = e.label.split(" - ");

      console.log(mId);

      setSc(mId[1]);
    }
    // console.log(e);
    setMc(e.option);
    setValue("master_category", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  // console.log(mc);

  //category
  const handleOnchangeCategory = (e) => {
    setScValue(e.option);
    setValue("category", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  //Brand
  const handleOnChangeBand = (e) => {
    setBrand(e.option);
    setValue("brand", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleOnchangeUnit = (e) => {
    setUnit(e.option);
    setValue("unit", e.option, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePromoStart = (date) => {
    setStartDate(date);
    setValue("promo_start", startDate, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePromoEnd = (date) => {
    setEndDate(endDate);
    setValue("promo_end", startDate, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mb-4">
            <Header title="Add New Product"></Header>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form
                  onSubmit={handleSubmit(handelAddProduct)}
                  className="mt-4"
                >
                  {/* 1st row */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="masterCategory" className="form-label">
                          Master Category
                        </label>
                        <input
                          type="hidden"
                          {...register("master_category", { required: true })}
                        />
                        <SelectMC
                          // {...reset("master_category", `${mc}`)}
                          // {...register("master_category", { required: true })}
                          handleOnchange={(e) => {
                            handleOnchangeMasterCategory(e);
                          }}
                          mc={mc}
                        ></SelectMC>
                        <div id="masterHelp" className="form-text">
                          Product Master Category
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="ean" className="form-label">
                          EAN Code{" "}
                        </label>
                        <input
                          placeholder="EAN CODE"
                          className="form-control"
                          id="ean"
                          aria-describedby="eanHelp"
                          {...register("ean", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        {!isEANUnique && (
                          <span className="text-danger">
                            EAN code already exists!
                          </span>
                        )}

                        <div id="eanHelp" className="form-text">
                          International EAN CODE
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Product Type
                        </label>
                        <select
                          className="form-select"
                          {...register("product_type")}
                        >
                          <option value="standard">Standard</option>
                          <option value="combo">combo</option>
                          <option value="offer">Offer</option>
                        </select>
                        {/* <input placeholder='Product Type' className="form-control" id="p_type" aria-describedby="typeHelp" {...register("type", { required: true, maxLength: 20 })} /> */}
                        <div id="typeHelp" className="form-text">
                          Single or Combo Product
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd row */}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_name">
                          Name
                        </label>
                        <input
                          placeholder="Product name"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          {...register("name", { required: true })}
                        />
                        <div id="nameHelp" className="form-text">
                          Product Name with pack size
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_article_code">
                          Article Code
                        </label>
                        <input
                          placeholder="Article Code"
                          className="form-control"
                          id="p_article_code"
                          aria-describedby="article_codeHelp"
                          {...register("article_code", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        {!isArticleCodeUnique && (
                          <span className="text-danger">
                            Article code already exists!
                          </span>
                        )}
                        <div id="article_codeHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3rd row  */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_brand">
                          Brand
                        </label>
                        <input
                          type="hidden"
                          placeholder="Brand"
                          className="form-control"
                          id="p_brand"
                          aria-describedby="brandHelp"
                          {...register("brand", {
                            maxLength: 20,
                          })}
                        />
                        <SelectBrand
                          brand={brand}
                          handleOnchange={(e) => handleOnChangeBand(e)}
                        />

                        <div id="brandHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_category">
                          Category
                        </label>
                        <input
                          type="hidden"
                          placeholder="Category"
                          className="form-control"
                          id="p_category"
                          aria-describedby="categoryHelp"
                          {...register("category", {
                            required: true,
                          })}
                        />
                        <CategorySelectByMC
                          scValue={scValue}
                          sc={sc}
                          handleOnChangeCategory={(e) =>
                            handleOnchangeCategory(e)
                          }
                        />
                        <div id="categoryHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_slug">
                          Slug
                        </label>
                        <input
                          placeholder="Slug"
                          className="form-control"
                          id="p_slug"
                          aria-describedby="slugHelp"
                          {...register("slug", { required: true })}
                        />
                        <div id="slugHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 4th row  */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_unit_code">
                          Product Unit
                        </label>
                        <input
                          type="hidden"
                          placeholder="Product Unit"
                          className="form-control"
                          id="p_unit_code"
                          aria-describedby="unit_codeHelp"
                          {...register("unit", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        <SelectUnit
                          unit={unit}
                          // {...setValue("unit", `${unit}`)}
                          // {...register("unit", { required: true })}
                          handleOnchange={(e) => handleOnchangeUnit(e)}
                        ></SelectUnit>
                        <div id="unit_codeHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_slug">
                          Alert Quantity
                        </label>
                        <input
                          type="number"
                          placeholder="Alert Quantity"
                          className="form-control"
                          id="p_slug"
                          aria-describedby="slugHelp"
                          {...register("alert_quantity", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        <div id="slugHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 6th row  */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat">
                          VAT
                        </label>
                        <input
                          type="number"
                          placeholder="VAT"
                          className="form-control"
                          id="p_vat"
                          aria-describedby="vatHelp"
                          {...register("vat", {
                            required: true,
                            maxLength: 20,
                          })}
                        />
                        <div id="vatHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat_method">
                          Vat Method
                        </label>
                        {/* <input placeholder='Vat Method' className="form-control" id="p_vat_method" aria-describedby="vat_methodHelp" {...register("vat_method", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          {...register("vat_method")}
                        >
                          <option value="true">Include</option>
                          <option value="false">Exclude</option>
                        </select>
                        <div id="vat_methodHelp" className="form-text">
                          Vat Include or Not
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Shipping Method
                        </label>
                        {/* <input placeholder='shipping' className="form-control" id="p_shipping" aria-describedby="shippingHelp" {...register("shipping", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          {...register("shipping")}
                        >
                          <option value="cod">COD</option>
                          <option value="free">Free Shipping</option>
                          <option value="uttara">Inside Uttara</option>
                        </select>
                        <div id="shippingHelp" className="form-text">
                          What is the delivery method?
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 7th row */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Featured
                        </label>
                        <select
                          className="form-select"
                          {...register("featured")}
                        >
                          <option value="true">Featured</option>
                          <option value="false">Non-Featured</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("featured")} id="featured" /> */}
                        <div id="featuredHelp" className="form-text">
                          Is this product featured on E-commerce?
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Web Site
                        </label>
                        <select
                          className="form-select"
                          {...register("hide_website")}
                        >
                          <option value="true">Show Web Site</option>
                          <option value="false">Hide Web Site</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("hide_website")} id="hide_on_website" /> */}

                        <div id="hide_on_websiteHelp" className="form-text">
                          Is this product hide on E-commerce?
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Status
                        </label>
                        <select className="form-select" {...register("status")}>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                        {/* <input type="checkbox" className="form-check-input me-2" {...register("hide_website")} id="hide_on_website" /> */}

                        <div id="hide_on_websiteHelp" className="form-text">
                          Is this product hide on E-commerce?
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 8th row */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_product_details"
                        >
                          Product Description
                        </label>
                        <textarea
                          placeholder="Product Description"
                          className="form-control"
                          id="p_product_details"
                          aria-describedby="product_detailsHelp"
                          {...register("details", { required: false })}
                        />
                        <div id="product_detailsHelp" className="form-text">
                          Product Details for E-commerce
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_product_specification"
                        >
                          Product Specification
                        </label>
                        <textarea
                          placeholder="Product Specification"
                          className="form-control"
                          id="p_product_specification"
                          aria-describedby="product_specificationHelp"
                          {...register("specification", { required: false })}
                        />
                        <div
                          id="product_specificationHelp"
                          className="form-text"
                        >
                          Product Details for E-commerce
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 9th row  */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotional_price"
                        >
                          Promotional Price{" "}
                        </label>
                        <input
                          type="number"
                          placeholder="Promotional Price"
                          className="form-control"
                          id="p_promotional_price"
                          aria-describedby="promotional_priceHelp"
                          {...register("promo_price", { required: false })}
                        />
                        <div id="promotional_priceHelp" className="form-text">
                          Product Cost{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotion_start"
                        >
                          Promotion Starts
                        </label>
                        {/* <input placeholder='Promotion Starts' className="form-control" id="p_promotion_start" aria-describedby="promotion_startHelp" {...register("promotion_start", { required: false })} /> */}
                        <DatePicker
                          className="form-control"
                          wrapperClassName="datePicker"
                          openCalendarOnFocus={true}
                          showTimeSelect
                          selected={startDate}
                          {...register("promo_start")}
                          onChange={handlePromoStart}
                          format={"MM/DD/YYYY"}
                        />
                        <div id="promotion_startHelp" className="form-text">
                          Product Price
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_promotion_ends"
                        >
                          Promotion Ends
                        </label>
                        {/* <input placeholder='Promotion Ends' className="form-control" id="p_promotion_ends" aria-describedby="promotion_endsHelp" {...register("promotion_ends", { required: false })} /> */}
                        <DatePicker
                          className="form-control"
                          wrapperClassName="datePicker"
                          openCalendarOnFocus={true}
                          showTimeSelect
                          selected={startDate}
                          {...register("promo_end")}
                          onChange={handlePromoEnd}
                          format={"MM/DD/YYYY"}
                        />
                        <div id="promotion_endsHelp" className="form-text">
                          Article code start with Master Category id
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                                            <div className="mb-3 ">
                                                <label className="form-label" htmlFor="p_promotion_ends">Image</label>
                                                <input type="file" className="form-control" {...register("photo")} />
                                                <div id="promotion_endsHelp" className="form-text">Article code start with Master Category id</div>
                                            </div>
                                        </div> */}
                  </div>

                  <input
                    type="submit"
                    className="btn btn-dark"
                    value="Add Product"
                    disabled={!isEANUnique || !isArticleCodeUnique}
                  />
                </form>
              </div>
              {/* <div className="col-md-4">
                <div className="card my-5 ">
                  <img
                    className="card-img-top"
                    src={productPhoto}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">ProductCover Photo</h5>
                  </div>
                  <div className="card-footer">
                    <button className="btn btn-primary">Go somewhere</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <DevTool control={control} placement="top-left" /> */}
      <Toaster position="bottom-right" />
    </div>
  );
};

export default AddProduct;
