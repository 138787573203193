import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ExportNav from "../Common/Nav/ExportNav";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { GrnRoutes } from "../../data";
import { Table } from "react-bootstrap";
import { useGrnArticleWiseQuery } from "../../services/grnApi";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";
import { signInUser } from "../Utility/Auth";

const GrnArticleWise = () => {
  const auth = signInUser();
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [warehouse, setWarehouse] = useState("allWh");
  const [exportData, setExportData] = useState([]);
  const [q, setQ] = useState("");
  const [totalQty, setTotalQty] = useState(0);
  const [totalTp, setTotalTp] = useState(0);
  const [totalMrp, setTotalMrp] = useState(0);

  let i = 1;

  const { data, isSuccess, refetch } = useGrnArticleWiseQuery({
    startDate,
    endDate,
    warehouse,
    q,
  });
  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    setLoader(false);
    let grnDetails = [];
    setExportData([]);

    // Check if data exists and iterate safely
    if (data?.length > 0) {
      data.forEach((product) => {
        // Check if grnDetails exists and is an array
        if (Array.isArray(product.grnDetails)) {
          grnDetails = [...grnDetails, ...product.grnDetails];
        } else {
          console.warn(`grnDetails is not an array or is missing in product:`, product);
        }
      });
    }

    console.log(grnDetails);
    setExportData(grnDetails);
  }, [isSuccess, data, startDate, endDate, q]);

  useEffect(() => {
    let totalQty = 0;
    let totalTP = 0;
    let totalMRP = 0;
    if (exportData?.length > 0) {
      exportData?.forEach((product) => {
        totalQty = totalQty + parseFloat(product?.qty > 0 ? product?.qty : 0);
        totalTP =
          totalTP +
          parseFloat(product?.qty > 0 ? product?.qty : 0) *
            parseFloat(product?.tp > 0 ? product?.tp : 0);
        totalMRP =
          totalMRP +
          parseFloat(product?.qty > 0 ? product?.qty : 0) *
            parseFloat(product?.mrp > 0 ? product?.mrp : 0);
      });
      setTotalQty(totalQty);
      setTotalTp(totalTP);
      setTotalMrp(totalMRP);
    }
  }, [exportData, data, startDate, endDate, q]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse, q]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
  };

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Grn Article Wise"></Header>
            <div className="row mt-3">
              <div className="col-md-6">
                {/* Sort date range */}
                <div className="date-picker d-flex mb-2 align-items-center">
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <div>
                    {auth?.type === "admin" && (
                      <WareHouseDW
                        id="warehouse"
                        name="warehouse"
                        handleOnChange={handleOnchangeWareHouseFrom}
                        className="form-control"
                        style={{ width: "20%" }}
                      />
                    )}
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search"
                    onKeyUp={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <span className="float-end">
                  <ExportNav
                    Routes={GrnRoutes}
                    data={exportData}
                    title="GRN Article"
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="">SL</th>
                  <th className="">Grn No</th>
                  <th className="">Article Code</th>
                  <th>Name</th>
                  <th className="text-end">MRP</th>
                  <th className="text-end">TP</th>
                  <th className="ps-3">QTY</th>
                  <th className="text-end">Total TP</th>
                  <th className="text-end">Total MRP</th>
                </tr>
              </thead>
              <tbody>
                {exportData?.length > 0 ? (
                  exportData?.map((pro) => (
                    <tr key={pro?.article_code + pro?.createdAt + pro?.grnNo}>
                      <td>{i++}</td>
                      <td>{pro.grnNo}</td>
                      <td className="">{pro.article_code}</td>
                      <td>{pro.name}</td>
                      <td className="text-end">
                        {parseFloat(pro.mrp).toFixed(2)}
                      </td>
                      <td className="text-end">
                        {parseFloat(pro.tp)?.toFixed(2)}
                      </td>
                      <td className="ps-3">
                        <b>
                          {new Intl.NumberFormat().format(
                            parseFloat(pro.qty)?.toFixed(2)
                          )}{" "}
                        </b>
                      </td>
                      <td className="ps-3">
                        <b>
                          {new Intl.NumberFormat().format(
                            (parseFloat(pro.qty) * parseFloat(pro.tp)).toFixed(
                              2
                            )
                          )}{" "}
                        </b>
                      </td>
                      <td className="ps-3">
                        <b>
                          {new Intl.NumberFormat().format(
                            (parseFloat(pro.qty) * parseFloat(pro.mrp)).toFixed(
                              2
                            )
                          )}{" "}
                        </b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={9}>
                    <td colSpan={9} className="text-center">
                      No Product Found
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>
                      {new Intl.NumberFormat().format(parseFloat(totalQty))}
                    </b>
                  </td>
                  <td>
                    <b>
                      {new Intl.NumberFormat().format(parseFloat(totalTp))}
                    </b>
                  </td>
                  <td>
                    <b>
                      {new Intl.NumberFormat().format(parseFloat(totalMrp))}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
};

export default GrnArticleWise;