import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../logo.png";
import "./SideBar.css";

const SupervisorSideBar = () => {
  let active = window.location.pathname;
  return (
    <aside className="sticky-md-top pb-5">
      <Link className="nav-link logo pt-3 pb-5" to="/">
        <Image src={logo} fluid />
      </Link>
      <nav className="nav flex-column">
        {/* <Link className='nav-link' to="/"><Icons.HomeOutline size={22}></Icons.HomeOutline> Home</Link> */}
        <>
          <Link
            className={`nav-link ${active === "/dashboard" && "active"}`}
            to="/dashboard"
          >
            <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
            Dashboard
          </Link>
          {/* RPODUCT DW */}
          <Link
            className={`nav-link ${
              (active === "/product" ||
                active === "/prduct/import" ||
                active === "/price/import" ||
                active === "/product/add" ||
                active === "/brand" ||
                active === "/unit" ||
                active === "/category" ||
                active === "/category/addCategory") &&
              "active"
            }`}
            to="/product"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.ArchiveOutline size={22} /> Products
              </span>
              <span className="text-end">
                {active === "/product" ||
                active === "/prduct/import" ||
                active === "/price/import" ||
                active === "/product/add" ||
                active === "/brand" ||
                active === "/unit" ||
                active === "/category" ||
                active === "/category/addCategory" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/product" ||
              active === "/prduct/import" ||
              active === "/price/import" ||
              active === "/product/add" ||
              active === "/brand" ||
              active === "/unit" ||
              active === "/category" ||
              active === "/category/addCategory"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/product" ||
                    active === "/prduct/import" ||
                    active === "/price/import" ||
                    active === "/product/add") &&
                  "active"
                }`}
                to="/product"
              >
                <Icons.ArchiveOutline size={18} /> Products
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/category" ||
                    active === "/category/addCategory") &&
                  "active"
                }`}
                to="/category"
              >
                <Icons.CubeTransparentOutline
                  size={18}
                ></Icons.CubeTransparentOutline>{" "}
                Category
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${active === "/brand" && "active"}`}
                to="/brand"
              >
                <Icons.SparklesOutline size={18} /> Brand
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${active === "/unit" && "active"}`}
                to="/unit"
              >
                <Icons.ChipOutline size={18} /> Unit
              </Link>
            </li>
          </ul>

          {/* procurement */}
          <Link
            className={`nav-link ${
              (active === "/purchase" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
                active === "/create-supplier") &&
              "active"
            }`}
            to="/purchase"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.InboxInOutline size={22} /> Procurement
              </span>
              <span className="text-end">
                {active === "/purchase" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
                active === "/create-supplier" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/purchase" ||
              active === "/purchase-create" ||
              active === "/grn" ||
              active === "/grn-create" ||
              active === "/grn-details" ||
              active === "/grn-summary" ||
              active === "/rtv" ||
              active === "/rtv-create" ||
              active === "/rtv-details" ||
              active === "/rtv-summary" ||
              active === "/tpn" ||
              active === "/tpn-out" ||
              active === "/tpn-details" ||
              active === "/tpn-summary" ||
              active === "/damage" ||
              active === "/damagecreate" ||
              active === "/damage-details" ||
              active === "/damage-summary" ||
              active === "/adjust" ||
              active === "/create-adjust" ||
              active === "/supplier" ||
              active === "/create-supplier"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/purchase" || active === "/purchase-create") &&
                  "active"
                }`}
                to="/purchase"
              >
                <Icons.CurrencyDollarOutline
                  size={18}
                ></Icons.CurrencyDollarOutline>{" "}
                Purchase
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/grn" ||
                    active === "/grn-create" ||
                    active === "/grn-details" ||
                    active === "/grn-summary") &&
                  "active"
                }`}
                to="/grn"
              >
                <Icons.ClipboardCheckOutline size={18} /> GRN
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/rtv" ||
                    active === "/rtv-create" ||
                    active === "/rtv-details" ||
                    active === "/rtv-summary") &&
                  "active"
                }`}
                to="/rtv"
              >
                <Icons.ReceiptRefundOutline size={18} /> RTV
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/tpn" ||
                    active === "/tpn-out" ||
                    active === "/tpn-details" ||
                    active === "/tpn-summary") &&
                  "active"
                }`}
                to="/tpn"
              >
                <Icons.HomeOutline size={18}></Icons.HomeOutline> TPN
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/damage" ||
                    active === "/damagecreate" ||
                    active === "/damage-details" ||
                    active === "/damage-summary") &&
                  "active"
                }`}
                to="/damage"
              >
                <Icons.TrashOutline size={18} /> Damage
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${active === "/adjust" && "active"}`}
                to="/adjust"
              >
                <Icons.SwitchVerticalOutline size={18} /> Adjust
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/supplier" || active === "/create-supplier") &&
                  "active"
                }`}
                to="/supplier"
              >
                <Icons.UsersOutline size={18}></Icons.UsersOutline> Supplier
              </Link>
            </li>
          </ul>
          <Link
            className={`nav-link mt-0 ${active === "/profile" && "active"}`}
            to="/profile"
          >
            <Icons.CogOutline size={18} /> Profile Settings
          </Link>
        </>
      </nav>
    </aside>
  );
};

export default SupervisorSideBar;
