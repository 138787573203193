import React, { useState } from "react";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import * as Icons from "heroicons-react";
import { Icon } from "@mui/material";
import POSClosingModal from "../../Common/Modal/POSClosingModal";
import { signInUser } from "../../Utility/Auth";
import WareHouseDW from "../../Common/CustomSelect/WareHouseDW";

const DayBook = () => {
  const auth = signInUser();
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleOnchangeWareHouseFrom = (e) => {
    console.log("handle data:", e);
    // setWarehouse(e.option)
    // refetch();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <SideBar></SideBar>
        </div>
        <div className="col-md-10">
          <Header title="Day Book"></Header>
          <div className="row my-2">
            {/* Left Section: Date and Warehouse */}
            <div className="col-md-7 d-flex align-items-center gap-3">
              {/* Date Picker */}
              <div className="d-flex align-items-center">
                <b className="me-2">Date:</b>
                <DatePicker
                  selected={new Date(date)}
                  className="form-control"
                  onChange={(date) =>
                    setDate(format(new Date(date), "MM-dd-yyyy"))
                  }
                />
              </div>

              {/* Warehouse Dropdown (Visible for Admins) */}
              {auth?.type === "admin" && (
                <div className="d-flex align-items-center">
                  <b className="me-2">Warehouse:</b>
                  <WareHouseDW
                    id="warehouse"
                    name="warehouse"
                    handleOnChange={handleOnchangeWareHouseFrom}
                    className="form-control warehouse-dropdown "
                  />
                </div>
              )}
            </div>

            {/* Right Section: Action Buttons */}
            <div className="col-md-5 d-flex justify-content-end gap-2">
              <button className="btn btn-dark btn-sm">
                <Icons.PrinterOutline /> Print
              </button>
              <button className="btn btn-dark btn-sm" onClick={handleOpenModal}>
                POS Closing
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <table className="table table-responsive table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">System</th>
                    <th scope="col">Recieved</th>
                    <th scope="col">Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Cash sales</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>BRAC</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>DBBL</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Amex</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>CITY</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>MTBL</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>EBL</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>UCB</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>bkash</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Rocket</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Nagad</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Upay</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>E-CommerceOnline</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <th>Loyalty/Cupon/Others</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Due Bill</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Due Collection</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-end">Total Sales: </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <table className="table table-responsive table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Particulars Name</th>
                    <th>Unit</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="text-center">1000</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">500</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">200</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">100</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">50</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">20</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">10</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">5</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">2</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">1</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Total cash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Cash out</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Office Bill</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Opening Cash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Due Bill</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Due Collection</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Total Cash Sales</th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row py-4">
            <h3>Ollid POS:</h3>
            <div className="col-4">
              <b className="py-2">Sales OverView: </b>
              <table className="table table-responsive table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Cash sales</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>BRAC</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>DBBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Amex</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>CITY</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>MTBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>EBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>UCB</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>bkash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Rocket</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Nagad</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Upay</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Loyalty/Cupon/Others</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Due Bill</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Due Collection</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-end">Total Sales: </th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4">
              <b className="py-2">Bank / Card: </b>
              <table className="table table-responsive table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>BRAC</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>DBBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Amex</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>CITY</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>MTBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>EBL</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>UCB</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>bkash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Rocket</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Nagad</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Upay</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Loyalty/Cupon/Others</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-end">Total Sales: </th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4">
              <b className="py-2">Cash: </b>
              <table className="table table-responsive table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Particulars Name</th>
                    <th>Unit</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="text-center">1000</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">500</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">200</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">100</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">50</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">20</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">10</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">5</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">2</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th className="text-center">1</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Total cash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Cash out</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Office Bill</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Opening Cash</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Due Bill</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Due Collection</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th colSpan={2}>Total Cash Sales</th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <POSClosingModal onShow={open} handleClose={handleCloseModal} />
    </div>
  );
};

export default DayBook;
