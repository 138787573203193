import React, { useEffect, useRef, useState } from "react";
import { Form, ListGroup, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEcomSalesByIdQuery,
  useUpdateEcomSaleMutation,
} from "../../../../services/ecomApi";
import Header from "../../../Common/Header/Header";
import SideBar from "../../../Common/SideBar/SideBar";
import * as Icons from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";
import PrintReceiptById from "../../../Common/PrintReceipt/PrintReceiptByIdEcom";
import {
  selcetProductsCart,
  selectAllData,
  selectProducts,
  selectCalculation,
  salePointUpdate,
} from "../../../../features/ecomSaleUpdateSlice";
import { notify } from "../../../Utility/Notify";
import {
  itemVat,
  itemVatTotal,
  toDecimal,
  total,
} from "../../../Utility/EcomSaleCalculations";
import EcomNewProductSelect from "../../../Common/CustomSelect/EcomNewProductSelect";
import SelectMrpEcomProducts from "../../../Common/CustomSelect/SelectMrpEcomProducts";
import axios from "axios";

const EcomSaleUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const statusRef = useRef();
  const ecomNewProductsRef = useRef(null);
  const BASE_URL =
    process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  const navigate = useNavigate();
  console.log("id", id)
  const { data, isSuccess } = useEcomSalesByIdQuery(id);
  console.log(data)
  const [updateEcomSale] = useUpdateEcomSaleMutation();
  let i = 1;

  useEffect(() => {
    if (data) {
      dispatch(selectAllData(data));
    }
  }, [data, isSuccess]);
  // cfonsole.log(ecomSaleData)
  const ecomSaleData = useSelector((state) => state.ecomSaleUpdateReducer);

  // console.log("data", data)
  // console.log("ecomSaleData", ecomSaleData)
  const [saleProducts, setSaleProducts] = useState([]);
  // const [qty, setQty] = useState(0)

  useEffect(() => {
    if (ecomSaleData) {
      setSaleProducts(ecomSaleData?.products);
      // dispatch(selcetProductsCart(data?.products))
      const { totalTax, total1, vatAmount, promoPrice } = total(
        ecomSaleData?.products
      );
      // console.log("total1, vatAmount promoPrice", total1, vatAmount, promoPrice)
      dispatch(
        selectCalculation({
          total: total1,
          vatAmount: vatAmount,
          promoPrice: promoPrice,
        })
      );
      dispatch(salePointUpdate());
    }
  }, [ecomSaleData?.products]);

  // console.log("sale data", saleProducts)

  const removeQuantities = (id) => {
    if (ecomSaleData.products) {
      let list = [];
      const item = ecomSaleData.products.find(
        (item) => item.article_code === id
      );
      const restItem = ecomSaleData.products.filter(
        (item) => item.article_code !== id
      );

      if (item) {
        list = [...restItem, { ...item, qty: item.qty - 1 }];
      } else {
        list = [...restItem];
      }
      // console.log("list", list)
      dispatch(selectProducts(list));
    }
  };
  const addQuantities = (id) => {
    if (ecomSaleData.products) {
      let list = [];
      const item = ecomSaleData.products.find(
        (item) => item.article_code === id
      );
      const restItem = ecomSaleData.products.filter(
        (item) => item.article_code !== id
      );

      if (item) {
        list = [...restItem, { ...item, qty: item.qty + 1 }];
      } else {
        list = [...restItem];
      }
      // console.log("list", list)
      dispatch(selectProducts(list));
    }
  };
  const removeFromCart = (code) => {
    if (window.confirm("Do you want to remove this product?") === true) {
      const rest = ecomSaleData.products.filter(
        (pro) => pro.article_code !== code
      );
      notify("Product is removed", "error");
      // cfonsole.log(code);
      dispatch(selectProducts(rest));
    } else {
      // console.log("Product Remove Cancel");
    }
  };
  const handleCustomQty = (e, id) => {
    let list = [];
    const customQty = e.target.value !== "" ? e.target.value : 0;
    // cfonsole.log(customQty, id);
    // const products = getStoredCart();
    const item = ecomSaleData?.products?.find(
      (item) => item?.article_code === id
    );
    if (item) {
      let restItem = ecomSaleData?.products?.filter(
        (item) => item?.article_code !== id
      );
      if (customQty >= 0) {
        // item.qty = customQty;
        list = [...restItem, { ...item, qty: customQty }];
        dispatch(selectProducts(list));
      }
    }
  };
  const handleSaleConfirmation = async () => {
    // console.log(statusRef.current.value)
    // console.log(data)
    const confirm = window.confirm("Confirm?");
    if (confirm) {
      const newData = { ...ecomSaleData, status: statusRef.current.value };
      // console.log(newData)
      await updateEcomSale({ _id: id, newData }).then((res) => {
        // console.log(res)
        navigate(`/ecom`);
      });
    }
    // cfonsole.log(update)
  };
  const handleSaleCancelation = async () => {
    // console.log(data)
    const confirm = window.confirm("Are you sure you want to Cancel?");
    if (confirm) {
      const newData = { ...data, status: "cancel" };
      // console.log(newData)
      await updateEcomSale({ _id: id, newData }).then((res) => {
        // console.log(res)
        navigate(`/ecom`);
      });
    }
  };
  const handleNavigateBack = () => {
    navigate(`/ecom`);
  };
  const addToList = async (data) => {
    // console.log(data);
    if (data) {
      // console.log("add to list:", data);
      await ecomNewProductsRef.current.blur();
      let listData = ecomSaleData.products;

      let newProduct = {};
      // unique check
      const idExist = listData.find((pro) => pro.id === data._id);
      if (idExist) {
        notify("Product Already in List", "error");
        // cfonsole.log()
      } else {
        notify("Product is Added", "success");
        // console.log("new product", data)
        newProduct = {
          article_code: data?.article_code,
          ean: data?.ean,
          id: data?._id,
          name: data?.name,
          vat: data?.vat,
          mrp: data?.priceList[0].mrp,
          tp: data?.priceList[0].tp,
          priceId: data?.priceList[0].priceId,
          qty: 1,
          unit: data?.unit,
          supplier: data?.priceList[0]?.supplier,
          promo_start: data?.promo_start,
          promo_end: data?.promo_end,
          promo_price: data?.promo_price,
          promo_type: data?.promo_type,
          order: ecomSaleData?.products?.length + 1,
        };
        listData = [...listData, newProduct];
      }

      // cfonsole.log("upcoming damage:", newProduct);
      // cfonsole.log("Updated damage:", listData);
      dispatch(selectProducts(listData));
    } else {
      return false;
    }
  };
  const handleOnChangePrice = async (id, e) => {
    let newCart = [];
    console.log(e);
    try {
      let price = await axios.get(`${BASE_URL}price/${e.option}`);
      console.log(price);
      let selected = ecomSaleData.products.find((item) => item.id === id);
      let rest = ecomSaleData.products.filter((item) => item.id !== id);
      if (price.data) {
        newCart = [
          ...rest,
          {
            ...selected,
            mrp: price.data?.mrp,
            tp: price.data?.tp,
            priceId: price.data?._id,
          },
        ];
        // setDamageProducts(newCart)
      }
    } catch (err) {
      notify(err, "error");
    } finally {
      dispatch(selectProducts(newCart));
      // dispatch(selectProducts(newCart));
    }

    // localStorage.setItem("pos_cart", JSON.stringify(newCart));
    // console.log(e.option);
    // updateCart();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Ecom Order Details "></Header>
            <div className="row  mt-3">
              {/* COL-8 */}
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mt-2">
                      <div className="card-header">
                        <h5 className="card-title">Customer Details</h5>
                      </div>
                      <div className="d-flex justify-content-between p-3">
                        <p><b>Name:</b> {data?.customerId?.name} </p>
                        <p><b>Phone: </b>{data?.customerId?.phone}</p>
                        <p><b>Email: </b>{data?.customerId?.email}</p>
                        <p><b>Address:</b> {data?.customerId?.delivery}</p>
                        <p><b>Point: </b>{data?.customerId?.point}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card mt-2">
                      <div className="card-header">
                        <h5 className="card-title">Payment method</h5>
                      </div>
                      <div className="d-flex justify-content-between p-2 px-3">
                        <Form.Check
                          type="checkbox"
                          checked={"checked"}
                          id="custom-switch"
                          // onChange={() => handleNewPrice(item?.id)}
                          label="Cash on delivery"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={""}
                          id="custom-switch"
                          // onChange={() => handleNewPrice(item?.id)}
                          label="Bkash"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={""}
                          id="custom-switch"
                          // onChange={() => handleNewPrice(item?.id)}
                          label="Nagad"
                        />
                        <Form.Check
                          type="checkbox"
                          checked={""}
                          id="custom-switch"
                          // onChange={() => handleNewPrice(item?.id)}
                          label="Card"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputCustomer">Products</label>
                      <EcomNewProductSelect
                        className="searchProduct"
                        // getValue={getValue}
                        addToList={addToList}
                        ecomNewProductsRef={ecomNewProductsRef}
                      ></EcomNewProductSelect>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <table className="table mt-3">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Product</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Vat</th>
                          <th scope="col">Sub-Total</th>
                        </tr>
                      </thead>
                      <tbody className="cart-list">
                        {saleProducts?.length > 0 ? (
                          saleProducts
                            ?.slice()
                            ?.sort((a, b) => a.order - b.order)
                            ?.map((product, index) => (
                              <tr key={product?.article_code}>
                                <th scope="row">{i++}</th>
                                <td title={product?.article_code}>
                                  {product?.name}
                                </td>
                                <td>
                                  <SelectMrpEcomProducts
                                    sc={product?.id}
                                    setVal={product?.mrp}
                                    handleOnChangeCategory={handleOnChangePrice}
                                  // handleCustomMrp={handleCustomMrp}
                                  ></SelectMrpEcomProducts>
                                </td>
                                <td className="col-md-2">
                                  <div className="input-group ">
                                    <div className="input-group-prepend">
                                      <div
                                        onClick={() =>
                                          removeQuantities(product.article_code)
                                        }
                                        className="input-group-text"
                                      >
                                        <Icons.Minus size="28" />
                                      </div>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control quantity"
                                      id={product?.article_code}
                                      onChange={(e) =>
                                        handleCustomQty(
                                          e,
                                          index,
                                          product?.article_code
                                        )
                                      }
                                      value={product.qty}
                                    />
                                    <div className="input-group-append">
                                      <div
                                        onClick={() =>
                                          addQuantities(product.article_code)
                                        }
                                        className="input-group-text"
                                      >
                                        <Icons.Plus size="28" />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {toDecimal(
                                    parseFloat(
                                      itemVat(
                                        product?.vat,
                                        product?.qty,
                                        product?.mrp
                                      )
                                    )
                                  )}
                                </td>
                                <td>
                                  {toDecimal(
                                    itemVatTotal(
                                      product?.vat,
                                      product?.qty,
                                      product?.mrp
                                    )
                                  )}
                                  <Icons.X
                                    className="float-end"
                                    onClick={() =>
                                      removeFromCart(product.article_code)
                                    }
                                  />
                                </td>
                              </tr>

                              // <p>{product.name}</p>
                              // cfonsole.log(product)
                            ))
                        ) : (
                          <p>No Products</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="card mt-2">
                      <div className="card-header">
                        <h5 className="card-title">
                          Ordered Products
                        </h5>
                      </div>
                      <div className="card-body">
                        <ListGroup variant="flush">
                          {
                            saleProducts?.length > 0 ?
                              (saleProducts?.slice()?.sort((a, b) => a.order - b.order)?.map((product, index) => (

                                <ListGroup.Item>
                                  <p>{product.article_code} {product.name} - {product.mrp}tk</p>
                                  <SelectMrpEcomProducts
                                    sc={product?.id}
                                    setVal={product?.mrp}
                                    handleOnChangeCategory={handleOnChangePrice}
                                  // handleCustomMrp={handleCustomMrp}
                                  ></SelectMrpEcomProducts>
                                  <div className="col-md-2">
                                    <div className="input-group ">
                                      {
                                        product?.qty > 0 ?
                                          <div className="input-group-prepend">
                                            <div
                                              onClick={() => removeQuantities(product.article_code)}
                                              className="input-group-text"
                                            >
                                              <Icons.Minus size="28" />
                                            </div>
                                          </div> : <div className="input-group-prepend">
                                            <div
                                              // onClick={() => removeQuantities(product.article_code)}
                                              className="input-group-text"
                                            >
                                              <Icons.Minus size="28" />
                                            </div>
                                          </div>
                                      }
                                      {/* quantity */}
                  {/* <input
                                        type="text"
                                        className="form-control quantity"
                                        width="60%"
                                        id={product?.article_code}
                                        onChange={(e) => handleCustomQty(e, product?.article_code)}
                                        value={product?.qty}
                                        defaultValue={product?.qty}
                                      />
                                      <div className="input-group-append">
                                        <div
                                          onClick={() => addQuantities(product.article_code)}
                                          className="input-group-text"
                                        >
                                          <Icons.Plus size="28" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Icons.X
                                    className="float-end"
                                    onClick={() => removeFromCart(product.article_code)}
                                  />

                                </ListGroup.Item> */}

                  {/* // <p>{product.name}</p>
                                // cfonsole.log(product)
                              ))) : <p>No Products</p>
                          }
                        </ListGroup>
                      </div>
                    </div> */}

                  {/* </div> */}
                  <Table className="bordered striped ">
                    <thead>
                      <tr>
                        <th>Total: {ecomSaleData.total}</th>
                        <th> Vat: {ecomSaleData.vat}</th>
                        <th> Promo Discount: {ecomSaleData.promo_discount}</th>
                        <th> Gross Total: {ecomSaleData.grossTotalRound}</th>
                      </tr>
                    </thead>
                  </Table>
                  {/* <div className="col-md-12">
                    <b> Total:</b> {ecomSaleData.total}
                  </div>
                  <div className="col-md-12">
                    <b> Vat:</b> {ecomSaleData.vat}
                  </div>
                  {
                    ecomSaleData.promo_discount > 0 ?
                      <div className="col-md-12">
                        <b> Promo Discount:</b> {ecomSaleData.promo_discount}
                      </div> : <></>
                  }
                  <div className="col-md-12">
                    <b> Gross Total:</b> {ecomSaleData.grossTotalRound}
                  </div> */}
                  <div className="col-md-4">
                    <div className="mt-2 d-flex justify-content-between">
                      {/* <div className="input input-group mb-3">
                                        <select className="custom-select" id="inputGroupSelect02">
                                            <option selected value="confirm">confirm</option>
                                            <option value="cancel">cancel</option>
                                        </select>
                                    </div> */}
                      {/* <button className='btn btn-dark'
                                        onClick={() => handleSaleConfirmation()}
                                    >Confirm</button>
                                    <button className='btn btn-dark'
                                        onClick={() => handleSaleCancelation()}
                                    >Cancel</button> */}
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        <b>Order Status</b>{" "}
                      </Form.Label>

                      <select
                        ref={statusRef}
                        className="form-select"
                        id="status"
                      >
                        <option selected value="confirm">
                          confirm
                        </option>
                        <option value="cancel">cancel</option>
                      </select>
                    </Form.Group>
                    <button
                      className="btn btn-dark"
                      onClick={() => handleSaleConfirmation()}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-dark mx-2"
                      onClick={() => handleNavigateBack()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mt-[-50]">
                <PrintReceiptById props={id}></PrintReceiptById>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcomSaleUpdate;
