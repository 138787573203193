import React, { useState } from "react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
import * as Icons from "heroicons-react";
import CsvImporter from "../Common/CsvImporter/CsvImporter";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// import PurchaseView from "../Common/Modal/PurchaseView";
import { compareAsc, format } from "date-fns";
// import useInventory from "../Hooks/useInventory";
import GrnView from "../Common/Modal/GrnView";
import {
  useGrnsQuery,
  useGrnPagenationQuery,
  useGrnCountQuery,
  useDeleteGrnMutation,
  useGrnByDateQuery,
} from "../../services/grnApi";
// import ReactPaginate from "react-paginate";
// import { Spinner } from "react-bootstrap";
import LoadingModal from "../Common/Modal/LoadingModal";
import DatePicker from "react-datepicker";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

const GRN = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  const [deleteGrn] = useDeleteGrnMutation();
  const [show, setShow] = useState(false);
  const [totalGrn, setTotalGrn] = useState(0);
  const [delProducts, setDelProducts] = useState([]);
  // const { updateInventoryOUTOnGRNDel } = useInventory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  let i = 1;
  const [grn, setGrn] = useState([]);
  const [grnView, setGrnView] = useState([]);
  const [warehouse, setWarehouse] = useState('allWh');
  const { data, isSuccess, isLoading, isFetching, refetch } = useGrnByDateQuery(
    {
      startDate,
      endDate,
      warehouse
    }
  );

  const auth = signInUser();

  useEffect(() => {
    refetch();
  }, [startDate, endDate ,warehouse]);

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);

  

  // console.log('Data',data)

  // const [updateInventoryOUTOnGRNDel] = useInventory;

  // const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

  useEffect(() => {
    setGrn(data);
    setLoader(false);

    isSuccess ? setLoader(false) : setLoader(true);

    let total = 0;

    if (data?.length > 0) {
      data.map((grn) => {
        total = total + parseFloat(grn.total);
      });
    }
    setTotalGrn(total);
  }, [isSuccess, data]);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);
  // console.log(purchase)
  // console.log(grn);

  const handelDeleteGrn = async (data) => {
    try {
      const confirm = window.confirm("Are you Sure? Delete this GRN?");

      if (confirm) {
        setLoader(true);
        const res = await deleteGrn(data);
        if (res) {
          refetch();
          notify("GRN Deleted Successful!", "success");
        } else {
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handleGrnView = (id) => {
    console.log(id);
    setGrnView(id);
    setShow(true);
  };

  const pageCountQuery = useGrnCountQuery();

  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };


  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
    
  };

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Goods Receive Note"></Header>
            <div className="row">
              <div className="col-md-7">
                {/* Sort date range */}
                <div className="date-picker d-flex mt-2 mb-2 align-items-center gap-2">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "20%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                {/* </div>
              <div className="col-md-4"> */}
                <Link
                  to="/grn-create"
                  className="btn btn-dark float-end me-2 mt-2"
                >
                  <Icons.Plus /> Create New GRN
                </Link>
                <Link
                  to="/grn-details"
                  className="btn btn-dark float-end me-2 mt-2"
                >
                  <Icons.ChartBar /> GRN Report
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>PO/TPN</th>
                      <th>GRN</th>
                      <th>Warehouse</th>
                      <th>Date</th>
                      <th>Supplier</th>
                      <th>GRN By</th>
                      <th className="text-center">Item No</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grn?.length > 0 ? (
                      grn?.map((item) => (
                        <tr key={item._id}>
                          <th>{i++}</th>
                          <td>
                            {item?.poNo ? item?.poNo?.poNo : item?.tpnNo?.tpnNo}
                          </td>
                          <td>{item?.grnNo}</td>
                          <td>{item?.warehouse?.name}</td>
                          <td>
                            {item?.createdAt &&
                              format(new Date(item.createdAt), "MM/dd/yyyy")}
                          </td>
                          <td>{item?.supplier?.company}</td>
                          <td>{item?.userId?.name}</td>
                          <td className="text-center">
                            {new Intl.NumberFormat().format(
                              parseFloat(item?.totalItem)?.toFixed(2)
                            )}
                          </td>
                          <td>
                            {new Intl.NumberFormat().format(
                              parseFloat(item?.total)?.toFixed(2)
                            )}
                          </td>
                          <td>{item?.status}</td>
                          <td>
                            <Icons.EyeOutline
                              onClick={() => handleGrnView(item._id)}
                              className="icon-eye me-1"
                              size={20}
                            ></Icons.EyeOutline>
                            {/* <Link to={``}>
                              <Icons.PencilAltOutline className="icon-edit" size={20} ></Icons.PencilAltOutline>
                            </Link> */}

                            {/* GRN DELETE */}

                            {item.status !== "Canceled" &&
                              auth?.type === "admin" && (
                                <Icons.TrashOutline
                                  className="icon-trash"
                                  onClick={() =>
                                    handelDeleteGrn({
                                      _id: item?._id,
                                      poNo: item?.poNo?._id,
                                      tpnNo: item?.tpnNo?._id,
                                    })
                                  }
                                  size={20}
                                />
                              )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr colSpan={9}>No Grn Found</tr>
                      // <tr>
                      //   <td colSpan={9} className="">
                      //     <p className="text-center mt-5 mb-5">
                      //       <Spinner animation="border" variant="dark" />
                      //       <br></br>
                      //       <b>Please Wait Grn is Loading</b>
                      //     </p>
                      //   </td>
                      // </tr>
                    )}
                    {
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>Grand Total</b>
                        </td>
                        <td>
                          <b>
                            {new Intl.NumberFormat().format(
                              parseFloat(totalGrn).toFixed(2)
                            )}
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <Toaster position="bottom-right" />
          </div>
        </div>
      </div>
      <GrnView show={show} handleClose={handleClose} grn={grnView}></GrnView>
    </div>
  );
};

export default GRN;
