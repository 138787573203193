import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Barcode from "react-barcode";
import Logo from "../../../../../logo.png";
import { signInUser } from "../../../../Utility/Auth";
import { useWarehouseQuery } from "../../../../../services/warehouseApi";

const DamageHeader = ({ damage, format, title }) => {
  
  // const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  // const [supplier, setSupplier] = useState({});
  // const getSupplier = async () => {
  //   await axios
  //     .get(`${BASE_URL}supplier/${grn?.supplier}`)
  //     .then((response) => setSupplier(response.data));
  // };
  // useEffect(() => {
  //   getSupplier();
  // }, [grn]);
  // console.log(grn?.supplier?.name);
  // console.log(damage)

  
  return (
    <>
      <div className="row pt-3 pb-3 mb-5 border-bottom">
        <div className="col-7">
          <img src={Logo} height="40" alt="not Image" />
        </div>

        <div className="col-5">
          <p className="p-0 m-0 text-end">
            <b>Hotline: </b> 01332553955
          </p>
          <p className="p-0 m-0 text-end">
            <small>{damage?.warehouse?.name}</small>{" "}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-6 print-header-text">
          <p>
            <b>Form</b> <br />
            The Community Magasin <br />
            communitymagasin@gmail.com <br />
            +880 1747-563337
            <br />
            Uttara, Dhaka - 1230 <br />
          </p>
        </div>
        <div className="col-6 text-end">
          {!damage?.print ? <h4>[ORIGINAL]</h4> : <h4>[DUPLICATE]</h4>}
          <h4>{title}</h4>
          <span className="ps-6 d-block">
            <p>
              Damage No: {damage?.damageNo}
              <br />
              Damage Date:{" "}
              {damage?.createdAt &&
                format(new Date(damage?.createdAt), "yyyy-MM-dd")}{" "}
              <br />
              Damage Time:{" "}
              {damage?.createdAt &&
                format(new Date(damage?.createdAt), "h:m:s aaa")}
              <br />
            </p>
            <p className="text-end bar-code">
              <Barcode
                value={damage?._id}
                height="60"
                width="2"
                fontSize="10"
              />
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default DamageHeader;
