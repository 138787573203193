import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import CategorySelectByMC from "../Common/CustomSelect/categorySelectByMC";
import { useSaleExportByDateAndCatQuery } from "../../services/saleApi";
import ExportNav from "../Common/Nav/ExportNav";
import { salesRoutes } from "../../data";
import LoadingModal from "../Common/Modal/LoadingModal";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";

const CategorySale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  //   const [sales, setSales] = useState([]);
  const [exportCSV, setExportCSV] = useState([]);
  const [scValue, setScValue] = useState("");
  const [cat, setCat] = useState("All");
  const [catName, setCatName] = useState("");

  const [totalSalesVat, setTotalSalesVat] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalVat, setTotalVat] = useState(0);

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);
  const [warehouse, setWarehouse] = useState("allWh");
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleExportByDateAndCatQuery({
      startDate,
      endDate,
      warehouse,
      cat,
    });
  //   console.log(data);
  const auth = signInUser();

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse, cat]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  useEffect(() => {
    isFetching ? setLoader(true) : setLoader(false);
  }, [data, isLoading, isFetching]);

  //   useEffect(() => {
  //     setSales(data);
  // console.log(data);
  //   }, [isSuccess, isFetching]);

  useEffect(() => {
    let saleData = [];
    let totalSaleVat = 0;
    let totalSale = 0;
    let totalVat = 0;
    data?.map((sale) => {
      saleData = [
        ...saleData,
        {
          article_code: sale.article_code,
          name: sale.name,
          tp: sale.tp,
          mrp: sale.mrp,
          qty: sale.totalQuantity,
          vat:
            parseFloat(sale.mrp) *
            parseFloat(sale.totalQuantity) *
            (parseFloat(sale.vat) / 100),
          total: (
            parseFloat(sale.totalQuantity) * parseFloat(sale.mrp)
          ).toFixed(2),
          vatTotal: (
            parseFloat(sale.totalQuantity) * parseFloat(sale.mrp) +
            parseFloat(sale.mrp) *
              parseFloat(sale.totalQuantity) *
              (parseFloat(sale.vat) / 100)
          ).toFixed(2),
        },
      ];
      // console.log(totalSaleVat);
      totalSaleVat =
        totalSaleVat +
        (parseFloat(sale.totalQuantity) * parseFloat(sale.mrp) +
          parseFloat(sale.mrp) *
            parseFloat(sale.totalQuantity) *
            (parseFloat(sale.vat) / 100));
      totalSale =
        totalSale + parseFloat(sale.totalQuantity) * parseFloat(sale.mrp);
      totalVat =
        totalVat +
        parseFloat(sale.mrp) *
          parseFloat(sale.totalQuantity) *
          (parseFloat(sale.vat) / 100);
    });
    setExportCSV(saleData);
    setTotalSalesVat(totalSaleVat);
    setTotalSales(totalSale);
    setTotalVat(totalVat);
    // console.log("dates", saleData);
  }, [data, isSuccess]);

  const handleOnchangeCategory = (e) => {
    // console.log(e);
    setScValue(e.option);
    setCat(e.option);
    setCatName(e.label);
  };

  const handleOnchangeWareHouseFrom = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
    refetch();
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TCM-POS-SALE-EXPORT-CATEGORYWISE</title>
      </Helmet>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Category Wise Article Sales "></Header>
            <div className="row mt-3">
              {/* Sort date range */}
              <div className="col-md-5">
                <div className="row g-0">
                  <div className="d-flex align-content-center gap-2 mb-2 ">
                    <div style={{ width: "150px" }}>
                      <DatePicker
                        selected={new Date(startDate)}
                        className="form-control"
                        onChange={(date) =>
                          setStartDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                    <div style={{ width: "150px" }}>
                      <DatePicker
                        selected={new Date(endDate)}
                        className="form-control"
                        onChange={(date) =>
                          setEndDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                    <div style={{ width: "200px" }}>
                      <CategorySelectByMC
                        className="form-control"
                        scValue={scValue}
                        handleOnChangeCategory={(e) =>
                          handleOnchangeCategory(e)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    {auth?.type === "admin" && (
                      <WareHouseDW
                        id="warehouse"
                        name="warehouse"
                        handleOnChange={handleOnchangeWareHouseFrom}
                        className="form-control"
                        style={{ width: "20%" }} // Consistent width for custom components
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-7 d-flex justify-content-end align-items-start ">
                <ExportNav
                  data={exportCSV}
                  title="Category Sale"
                  Routes={salesRoutes}
                />
              </div>
              <div className="col-md-12">
                <Table hover bordered striped className="mt-3">
                  <thead>
                    <tr>
                      {/* <th scope="col">#</th> */}
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th scope="col" className="text-center">
                        TP
                      </th>
                      <th scope="col" className="text-center">
                        MRP
                      </th>
                      <th scope="col" className="text-center">
                        Qty
                      </th>
                      <th scope="col" className="text-center">
                        Vat
                      </th>
                      <th scope="col" className="text-end">
                        Total
                      </th>
                      <th scope="col" className="text-end">
                        Vat Total
                      </th>

                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(sales)} */}
                    {exportCSV ? (
                      exportCSV?.length > 0 ? (
                        exportCSV.map((sale) => (
                          <tr key={sale._id}>
                            {/* <th >{i++}</th> */}

                            <td>{sale.article_code}</td>
                            <td>{sale.name}</td>
                            <td className="text-center">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.tp).toFixed(2)
                              )}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.mrp).toFixed(2)
                              )}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.qty).toFixed(2)
                              )}
                            </td>
                            <td className="text-center">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.vat).toFixed(2)
                              )}
                            </td>
                            <td className="text-end">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.total).toFixed(2)
                              )}
                            </td>
                            <td className="text-end">
                              {new Intl.NumberFormat().format(
                                parseFloat(sale.vatTotal).toFixed(2)
                              )}
                            </td>
                            {/* 
                                                <td>{sale.price}</td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>No product found</td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={7}>Please Select a Category...</td>
                      </tr>
                    )}
                    {
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {new Intl.NumberFormat().format(
                              parseFloat(totalVat).toFixed(2)
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {new Intl.NumberFormat().format(
                              parseFloat(totalSales).toFixed(2)
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {new Intl.NumberFormat().format(
                              parseFloat(totalSalesVat).toFixed(2)
                            )}
                          </b>
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {/* <Link to="/category-sales" className="btn btn-dark float-end my-2 | mx-2">Category Sales </Link> */}
      </div>
    </div>
  );
};

export default CategorySale;
