import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSalesWeeklyQuery } from "../../../../services/saleApi";
import { useWeeklyPurchasesQuery } from "../../../../services/purchasApi";
import { useWeeklyGrnsQuery } from "../../../../services/grnApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  const { data: weeklySale, isSuccess: isSuccessSale } = useSalesWeeklyQuery();
  const { data: weeklyPurchase, isSuccess: isSuccessPurchase } =
    useWeeklyPurchasesQuery();
  const { data: weeklyGRN, isSuccess: isSuccessGRN } = useWeeklyGrnsQuery();
  const [weekDates, setWeekDates] = useState([]);
  const [weekSales, setWeekSales] = useState([]);
  const [weekPurchase, setWeekPurchase] = useState([]);
  const [weekGRN, setWeekGRN] = useState([]);

  // console.log(weeklyPurchase)
  useEffect(() => {
    let g = [];
    if (weeklyGRN?.length > 0) {
      weeklyGRN?.slice().map((grn) => {
        g = [...g, grn.total];
      });
      setWeekGRN(g);
    }
    // console.log(dates)
  }, [weeklyGRN, isSuccessGRN]);

  useEffect(() => {
    let dates = [];
    if (weeklySale?.length > 0) {
      weeklySale?.slice().map((sale) => {
        dates = [...dates, sale._id];
      });
    }
    if (dates?.length > 7) {
      dates.shift();
    }
    // console.log(dates)
    setWeekDates(dates);
  }, [weeklySale, isSuccessSale]);

  useEffect(() => {
    let p = [];
    if (weeklyPurchase?.length > 0) {
      weeklyPurchase?.slice().map((purchase) => {
        p = [...p, purchase?.total];
        // console.log(p);
      });
      setWeekPurchase(p);
    }
  }, [weeklyPurchase, isSuccessPurchase]);

  useEffect(() => {
    let sales = [];
    if (weeklySale?.length > 0) {
      weeklySale?.slice().map((sale) => {
        sales = [...sales, sale?.grossTotalRound];
      });
    }
    if (sales?.length > 7) {
      sales.shift();
    }
    setWeekSales(sales);
    // console.log(sales)
  }, [weeklySale, isSuccessSale]);

  // console.log(weeklySale)
  // console.log(weekDates)
  // console.log(weekPurchase);

  const data = {
    labels: weekDates,
    datasets: [
      {
        label: "Purchase",
        data: weekPurchase,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#facd55",
      },
      {
        label: "Sales",
        data: weekSales,
        fill: true,
        borderColor: "#3fed33",
      },
      {
        label: "GRN",
        data: weekGRN,
        fill: true,
        borderColor: "#ed3833",
      },
    ],
  };

  return <Line data={data} width={600} height={150} />;
};

export default LineChart;
