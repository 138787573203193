import React, { useEffect, useLayoutEffect } from "react";
import Header from "../../Common/Header/Header";
import { Navigate, useLocation } from "react-router-dom";
import { signInUser } from "../../Utility/Auth";

const Home = () => {
  // const { auth } = useAuth();
  //@ts-ignore
  const accessToken = localStorage.getItem("accessTokens");
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  // const auth = signInUser();

  // console.log("Require Auth", auth);

  // // console.log( user)
  // useEffect(() => {
  //   if (accessToken) {
  //     // console.log(location);
  //     if (user?.type === "POS") {
  //       return <Navigate to="/pos" state={{ from: location }} replace />;
  //     } else {
  //       return <Navigate to="/dashboard" state={{ from: location }} replace />;
  //     }
  //   }
  // }, []);
  return (
    <div>
      <Header />

      <h1 className="text-center pt-5 mt-5">TCM-POS</h1>
      <p className="text-center">The Community Magasin</p>

      <p className="text-center">
        <b>Version: 2.1.2</b>
        <div className="mx-auto w-50">
          <ul>
            <li className="text-start">
              {" "}
              <b className="text-start">v - 2.1.2</b>
            </li>
            <li className="text-start"> > DashBoard updated</li>
            <li className="text-start"> >Grn shown by Date</li>
            <li className="text-start"> > Purchase shown by Date</li>
            <li className="text-start"> > RTV shown by Date</li>
            <li className="text-start"> > Damage shown by Date</li>
            <li className="text-start">
              {" "}
              >Customer address are now in new format in POS and Customer Page
            </li>
          </ul>
        </div>
      </p>
      <p className="text-center">
        <b>Version: 2.1.1</b>
        <div className="mx-auto w-50">
          <ul>
            <li className="text-start">
              {" "}
              <b className="text-start">v - 2.1.1</b>
            </li>
            <li className="text-start"> > Price Active or inactive option</li>
            <li className="text-start">
              {" "}
              > Show only active prices on the POS Screen
            </li>
            <li className="text-start"> > Grn Create Price From Backend</li>
            <li className="text-start">
              {" "}
              > Inventory Updating work from backend
            </li>
            <li className="text-start">
              {" "}
              > Promotional price add in updated products
            </li>
            <li className="text-start"> > Multihold in POS Screen</li>
          </ul>
        </div>
      </p>
      <p className="text-center">
        <b>Version: 2.0</b>
        <div className="mx-auto w-50">
          <ul>
            <li className="text-start">
              {" "}
              <b className="text-start">v - 2.0</b>
            </li>
            <li className="text-start">
              {" "}
              > Purchase Create a CSV te product import ( most import, argent
              requirement)
            </li>
            <li className="text-start">
              {" "}
              > Inventory te RTV and Damage field add korte hobe
            </li>
            <li className="text-start">
              {" "}
              > Custom price deoar option thakbe in GRN (TP update hobe na
              without GRN)
            </li>
            <li className="text-start">
              {" "}
              > Product a price table delete kora jabe na
            </li>
            <li className="text-start">
              {" "}
              > Inventory session a cursor inventory field a focus hoye thakbe
            </li>
            <li className="text-start">
              {" "}
              > Supplier er under a shob product delete kore nutun import korar
              option thakbe
            </li>
            <li className="text-start">
              {" "}
              > Purchase a product list theke product add korar somoy protibar
              product add a ekta notification add hobe
            </li>
            <li className="text-start">
              {" "}
              > Purchase page a product import and export
            </li>
            <li className="text-start">
              {" "}
              > Purchase a product search korar field thakbe
            </li>
            <li className="text-start">
              {" "}
              > Purchase ,GRN and RTV create page a design ta ektu update korte
              hobe
            </li>
            <li className="text-start">
              {" "}
              > Grand total a full round dorkar nai, 2 ta decimal field thakbe
            </li>
            <li className="text-start">
              {" "}
              > Print page gula thik nai side a ektu margine dorkar
            </li>
            <li className="text-start">
              {" "}
              > GRN and purchase a tp r value gulo dekte hobe(GRN a tp r value
              thik nai)
            </li>
            <li className="text-start"> > Inventory</li>
            <ul>
              <li className="text-start"> > session</li>
              <li className="text-start"> > init</li>
              <li className="text-start"> > export</li>
              <li className="text-start"> > import</li>
            </ul>
            <li className="text-start"> > Grn Searching </li>
            <li className="text-start"> > Import options in </li>
            <ul>
              <li className="text-start"> > grn</li>
              <li className="text-start"> > product</li>
              <li className="text-start"> > Price</li>
              <li className="text-start"> > Purchase</li>
            </ul>
            {/* <li className="text-start">  Photo upload Category,Product,User,customer</li> */}
            <li className="text-start">
              {" "}
              > Export options in
              <ul>
                <li className="text-start"> > Product</li>
                <li className="text-start"> > Category</li>
                <li className="text-start"> > Supplier</li>
                <li className="text-start"> > Sale</li>
                <li className="text-start"> > Inventory</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="text-start">
              {" "}
              <b className="text-start">v - 1.1.5</b>
            </li>
            <li className="text-start"> > One invoice returns once</li>
            <li className="text-start"> > Invoice id generation</li>
            <li className="text-start">
              {" "}
              > Add customer -- username as mobile No
            </li>
            {/* <li className="text-start">  Photo upload Category,Product,User,customer</li> */}
            <li className="text-start">
              {" "}
              > Show Permission
              <ul>
                <li className="text-start"> > Remove Product From Cart</li>
                <li className="text-start"> > Add Discount</li>
                <li className="text-start"> > Return</li>
                <li className="text-start"> > Refresh</li>
              </ul>
            </li>

            {/* <li className="text-start">  for Point Expance OTP required </li> */}
            <li className="text-start">
              {" "}
              > Return Qty must me less then invoice qty
            </li>
            <li className="text-start"> > Custumor Search and select</li>
            <li className="text-start">
              {" "}
              > Total POS processing is connected with redux
            </li>
            <li className="text-start"> > Pos search engine is upgraded</li>
            <li className="text-start"> > Receive bill upgrated</li>
          </ul>
        </div>
      </p>
      <p className="text-center">
        <b>Version: 1.1.2</b>
      </p>

      <div className="mx-auto w-50">
        <ul>
          <li className="text-start">
            {" "}
            <b className="text-start">v - 1.1.2</b>
          </li>
          <li className="text-start"> > Reset Customer after complete sale</li>
          <li className="text-start">
            {" "}
            > Remove product from pos cart require password
          </li>
          <li className="text-start">
            {" "}
            > Add customer point [old, new] to all sale
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
