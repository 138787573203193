import axios from "axios";
import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
// import { setSelcetCustomer } from "../../../features/posSlice";

// import { connect } from "react-redux";
// console.log(this.props.posSaleData);

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
// const dispatch = useDispatch();

class SelectCustomerLedger extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedOption: {
        label: this.props.SelcetCustomer
          ? this.props.SelcetCustomer.name
          : "WalkWay Customer",
        option: this.props.SelcetCustomer
          ? this.props.SelcetCustomer._id
          : "WalkWay Customer",
      },
      normalSelectOption: null,
    };
  }

  fetchData = async (inputValue, callback) => {
    // console.log(this.state);
    let cancelToken;
    // console.log(inputValue);

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    const result = await axios.get(
      `${BASE_URL}customer/search/${inputValue}`,
      {
        cancelToken: cancelToken.token,
      }
    );

    // console.log(result.data);
    let tempArray = [];

    if (result.data.length > 0) {
      // get match more than one
      result.data.forEach((element) => {
        tempArray.push({
          label: `${element.name} - [ ${element.phone} ]`,
          value: element._id,
        });
      });
    } else {
      tempArray.push({
        label: this.props.posSaleData.customerName,
        option: this.props.posSaleData.customerId,
      });
    }
    callback(tempArray);
  };

  getValue = async (data) => {
    let cancelToken;

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    const result = await axios.get(`${BASE_URL}customer/${data}`, {
      cancelToken: cancelToken.token,
    });

    // console.log("selected:", result);

    this.props.setSelcetCustomer(result.data);
  };

  onSearchChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        selectedOption,
      });
      // console.log(selectedOption);
      this.getValue(selectedOption.value);
    }
  };
  // handleChange = (normalSelectOption) => {
  //   this.setState({ normalSelectOption });
  //   console.log(this.state.normalSelectOption);
  // };

  selectRef = React.createRef();

  render() {
    return (
      <AsyncSelect
        value={this.state.selectedOption}
        loadOptions={this.fetchData}
        placeholder="Customer Select"
        key={this.fetchData}
        onChange={(e) => {
          this.onSearchChange(e);
        }}
        defaultOptions={{
          label: "WalkWay Customer",
          option: "",
        }}
        className="basic-single"
        classNamePrefix="select"
        // classNamePrefix="react-select"
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        innerRef={this.selectRef}
        ref={this.props.customerSelect}
      />
    );
  }
}

export default connect(null)(SelectCustomerLedger);
// export default SelectCustomer;
