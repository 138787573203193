import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SelectCustomerLedger from "../CustomSelect/selectCustomerLedger";
import * as Icons from "heroicons-react";
import { format } from "date-fns";
import {
  saleCardAmount,
  saleCardName,
  saleCash,
  saleMfsAmount,
  saleMfsName,
  selectAdmin,
  selectCustomerId,
  selectDueAmount,
  selectChangeAmount,
  selectInvoice,
} from "../../../features/paymentSlice";
import { useAddPaymentMutation } from "../../../services/paymentApi";
import { notify } from "../../Utility/Notify";
import { Toaster } from "react-hot-toast";
import sendMessage from "../../Utility/smsSystem";

const BillPayModal = ({ title, onShow, handleClose }) => {
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  const [selectCustomer, setSelectCustomer] = useState();
  const [invoices, setInvoices] = useState([]);

  const paymentData = useSelector((state) => state.paymentReducer);
  const dispatch = useDispatch();

  const getDueInvoice = async (customer) => {
    const dueInvoice = await axios
      .get(`${BASE_URL}sale/dueByCusromer/${customer}`)
      .then((result) => {
        setInvoices(result.data);
      });
  };

  const minDueCalculation = () => {
    let dueAmount = 0;
    if (paymentData.invoices?.length > 0) {
      paymentData?.invoices?.forEach((id) => {
        const match = invoices?.sales?.find((item) => item._id === id);
        if (match) {
          dueAmount += parseInt(match.grossTotalRound);
        }
      });
    }

    dispatch(selectDueAmount(dueAmount));
  };
  

  useEffect(() => {
    // TODO::GET DUE INVOICE BY CUSTOMER
    console.log('selectCustomer', selectCustomer);
    selectCustomer && getDueInvoice(selectCustomer?._id);
    dispatch(selectCustomerId(selectCustomer?._id));
    dispatch(selectInvoice([]));

  }, [selectCustomer]);

  useEffect(() => {
    // TODO::GET DUE INVOICE BY CUSTOMER
    minDueCalculation();
  }, [paymentData.invoices]);

  useEffect(() => {
    const changeAmount =
      paymentData.dueAmount -
      (parseInt(paymentData.paidAmount.cash.amount) +
        parseInt(paymentData.paidAmount.mfs.amount) +
        parseInt(paymentData.paidAmount.card.amount));
    dispatch(selectChangeAmount(changeAmount));
  }, [paymentData.paidAmount]);

  

  const handleInvoiceSelect = (id) => {
    // Create a copy of the invoices array
    let newInvoices = [...paymentData.invoices];

    if (newInvoices.includes(id)) {
      // If the id is already selected, remove it
      newInvoices = newInvoices.filter((invoiceId) => invoiceId !== id);
      dispatch(selectInvoice(newInvoices));
    } else {
      // If the id is not selected, add it
      newInvoices.push(id);
      dispatch(selectInvoice(newInvoices));
    }
    // Dispatch the updated invoices array
  };

  const handleCancel = () => {
    handleClose();
  };

  function getInvoiceIdsFromIds() {
    // Filter the object array to only include objects
    // where the _id property is in the idArray
    const filteredObjects = invoices.sales.filter((obj) =>
      paymentData.invoices.includes(obj._id)
    );

    // Map the filtered objects to an array of invoiceId values
    const invoiceIds = filteredObjects.map((obj) => obj.invoiceId);

    return invoiceIds;
  }

  const [createNew] = useAddPaymentMutation();

  const createPayment = async () => {
    if (window.confirm("Are you sure, Print this bill?") === true) {
      console.log(paymentData);
      const payment = await createNew(paymentData);
      console.log("new Sale", payment);
      if (payment?.data?.status === 200) {
        const resultInvoiceIds = getInvoiceIdsFromIds();
        const message = `সন্মানিত গ্রাহক, আপনার ডিউ বিল পরিশোধ সফলভাবে  সম্পন্ন হয়েছে। পরিশোধিত ইনভয়েস নাম্বার: ${resultInvoiceIds}, পরিশোধিত মূল্য: ${paymentData.dueAmount} TK |  বিস্তারিত - 01332553955`;

        sendMessage(selectCustomer?.phone, message);
        notify(`Payment Successful`, "success");
        handleClose();
      }
    }
  };

  return (
    <Modal size="lg" show={onShow} onHide={handleClose}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <b>Select Customer:</b>
                <SelectCustomerLedger setSelcetCustomer={setSelectCustomer} />
              </Col>
            </Row>
            <div className="card mt-2 p-2">
              <Row className="g-3">
                <Col xs={7}>
                  <b>Due Invoices</b>
                  <hr />
                  <table className="borderd striped table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Invoice No</th>
                        <th>Date</th>
                        <th className="text-end">Due Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectCustomer?._id ? (
                        invoices?.sales?.length > 0 ? (
                          invoices?.sales?.map((invoice) => {
                            return (
                              <tr key={invoice?._id}>
                                <td>
                                  <Form.Check
                                    inline
                                    checked={paymentData.invoices.includes(
                                      invoice._id
                                    )}
                                    name=""
                                    onChange={(e) =>
                                      handleInvoiceSelect(invoice._id)
                                    }
                                  />
                                </td>
                                <td>
                                  <b>{invoice.invoiceId}</b>
                                </td>
                                <td>
                                  {format(
                                    new Date(invoice.createdAt),
                                    "MM/dd/yyyy"
                                  )}
                                </td>
                                <td className="text-end">
                                  {invoice.grossTotalRound} TK
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              <Icons.SelectorOutline /> No Due Found
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            <Icons.SelectorOutline /> Please Select a Customer
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
                <Col xs={5}>
                  <b>Due Details</b>
                  <hr />
                  <p>
                    <b>Due Invoice:</b>{" "}
                    <span className="float-end">
                      {invoices?.sales ? invoices?.sales?.length : 0}
                    </span>
                    <br />
                    <b>Paid Invoice:</b>{" "}
                    <span className="float-end">
                      {paymentData?.invoices?.length}
                    </span>
                    <br />
                    <b>Total Due Amount:</b>{" "}
                    <span className="float-end">{invoices?.totalDue} TK</span>
                    <br />
                    <b>Paid Amount:</b>{" "}
                    <span className="float-end">
                      {paymentData?.dueAmount | 0} TK
                    </span>
                  </p>
                  <p className="card-text align-middle">
                    <div className="row">
                      <div className="col-md-6">
                        <b>Cash Received: </b>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          onChange={(e) =>
                            dispatch(
                              saleCash(e.target.value > 0 ? e.target.value : 0)
                            )
                          }
                          id="cashAmount"
                          defaultValue={0}
                          min={0}
                          value={paymentData.paidAmount.cash.amount}
                          className="form-control float-end col-7"
                        />
                      </div>
                    </div>
                  </p>
                  <p className="card-text align-middle">
                    <div className="row">
                      <div className="col-md-3">
                        <b>Card: </b>
                      </div>
                      <div className="col-md-5">
                        <Form.Select
                          name="cardType"
                          onChange={(e) =>
                            dispatch(saleCardName(e.target.value))
                          }
                          id="cardType"
                          aria-label="Default select example"
                        >
                          <option value="visa"> Visa </option>
                          <option value="masterCard"> Master Card </option>
                          <option value="MTB"> MTB </option>
                          <option value="CITY"> CITY </option>
                          <option value="DBBL"> DBBL </option>
                          <option value="EBL"> EBL </option>
                          <option value="BRAC"> BRAC </option>
                        </Form.Select>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="number"
                          defaultValue={0}
                          onChange={(e) =>
                            dispatch(
                              saleCardAmount(
                                parseInt(
                                  e.target.value > 0 ? e.target.value : 0
                                )
                              )
                            )
                          }
                          min={0}
                          value={paymentData.paidAmount.card.amount}
                          name="cardAmount"
                          id="cardAmount"
                          className="form-control float-end col-12"
                        />
                      </div>
                    </div>
                  </p>
                  <p className="card-text align-middle">
                    <div className="row">
                      <div className="col-md-3">
                        <b>MFS: </b>
                      </div>
                      <div className="col-md-5">
                        <Form.Select
                          name="mfsName"
                          onChange={(e) =>
                            dispatch(saleMfsName(e.target.value))
                          }
                          id="mfsName"
                          aria-label="Default select example"
                        >
                          <option value="bKash"> Bkash</option>
                          <option value="Nagad"> Nagad </option>
                          <option value="Upay"> Upay </option>
                          <option value="Rocket"> Rocket</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="number"
                          id="mfsAmount"
                          defaultValue={0}
                          onChange={(e) =>
                            dispatch(
                              saleMfsAmount(
                                e.target.value > 0 ? e.target.value : 0
                              )
                            )
                          }
                          value={paymentData.paidAmount.mfs.amount}
                          name="mfsAmount"
                          className="form-control float-end col-12"
                        />
                      </div>
                    </div>
                    <hr />
                    <b>Minimum Due:</b>{" "}
                    <span className="float-end">
                      {paymentData.dueAmount | 0} tk
                    </span>{" "}
                    <br />
                    <b>Change Ammount:</b>{" "}
                    <span className="float-end">
                      {paymentData.changeAmount | 0} tk
                    </span>{" "}
                    <br />
                    <b>Remaning Due:</b>
                    <span className="float-end">
                      {invoices?.totalDue - paymentData.dueAmount} tk
                    </span>
                  </p>
                  <p className="text-end">
                    <Button
                      variant="dark"
                      disabled={
                        paymentData?.invoices?.length > 0 ? false : true
                      }
                      onClick={createPayment}
                    >
                      <Icons.CurrencyBangladeshi /> Make Payment
                    </Button>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
      <Toaster position="bottom-right" />
    </Modal>
  );
};

export default BillPayModal;
