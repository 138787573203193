import { createSlice } from "@reduxjs/toolkit";

export const productPriceListSlice = createSlice({
  name: "priceList",
  initialState: [],
  reducers: {
    productPriceList: (state, action) => {
      return (state = action.payload);
    },
    addProductPrice: (state, action) => {
      const newPrice = {
        supplier: "",
        warehouse: "",
        tp: 0,
        mrp: 0,
        stock: 0,
        warehouse:"",
        status: "active",
      };
      return [...state, newPrice];
    },
    handleSupplier: (state, action) => {
      const { supplier, index } = action.payload;
      state[index].supplier = supplier;
    },
    handleWarehouse: (state, action) => {
      const { warehouse, index } = action.payload;
      state[index].warehouse = warehouse;
    },
    handleMrp: (state, action) => {
      const { mrp, index } = action.payload;
      state[index].mrp = parseFloat(mrp);
    },
    handleTp: (state, action) => {
      console.log(action.payload);
      const { tp, index } = action.payload;
      state[index].tp = parseFloat(tp); // Parse tp as a floating-point number
    },

    handleRemove: (state, action) => {
      const index = action.payload;

      console.log(index);

      if (index >= 0 && index < state.length) {
        const newState = [...state]; // Create a copy of the state array using slice
        newState.splice(index, 1); // Remove the item from the copied array

        state = [...newState]; // Return the updated array as the new state
        console.log(newState);
      }

      return state; // Return the original state if the index is invalid
    },
    handleStatus: (state, action) => { },
  },
});

export const {
  productPriceList,
  addProductPrice,
  handleSupplier,
  handleWarehouse,
  handleMrp,
  handleTp,
  handleRemove,
} = productPriceListSlice.actions;
export const productPriceListReducer = productPriceListSlice.reducer;
