import React, { useState } from "react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
import * as Icons from "heroicons-react";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PurchaseView from "../Common/Modal/PurchaseView";
import { compareAsc, format } from "date-fns";
import {
  usePurchasesQuery,
  useDeletePurchaseMutation,
  usePurchaseByDateQuery,
  useUpdatePurchaseMutation,
} from "../../services/purchasApi";
import LoadingModal from "../Common/Modal/LoadingModal";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { signInUser } from "../Utility/Auth";
import WareHouseDW from "../Common/CustomSelect/WareHouseDW";
// import { useWarehouseQuery } from "../../services/warehouseApi";

const Purchase = () => {
  const navigate = useNavigate();
  const auth = signInUser();
 const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [warehouse, setWarehouse] = useState("allWh");
  const [deletePurchas] = useDeletePurchaseMutation();
  const [updatePurchase] = useUpdatePurchaseMutation();

  // const purchases = usePurchasesQuery();
  const { data, isSuccess,refetch } = usePurchaseByDateQuery({
    startDate,
    endDate,
    warehouse
  });
  console.log("po", data);




  const [totalPO, setTotalPO] = useState(0);
  const [show, setShow] = useState(false);

  // const [whName, setWhName] = useState("TCM");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  let i = 1;
  const [purchase, setPurchase] = useState([]);
  const [purchaseView, setPurchaseView] = useState([]);

  // console.log("Purchese View", purchaseView);


  useEffect(() => {
    data?.length > 0 ? setPurchase(data) : setPurchase([]);
    let total = 0;
    if (data?.length > 0) {
      data.map((po) => {
        if (po.status !== "Canceled") {
          total = total + parseFloat(po.total);
        }
      });
      setTotalPO(total);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    isSuccess ? setLoader(false) : setLoader(true);
  }, [isSuccess]);

  // console.log(loader);

  useEffect(() => {
    refetch();
  }, [startDate, endDate ,warehouse]);

  useEffect(() => {
    if(auth?.type === "admin"){
      setWarehouse("allWh");
    }else{
      setWarehouse(auth?.warehouse);
    }
  }, []);


  const deleteHandler = async (id) => {
    const data = { _id: id, status: "Canceled"};
    try {
      const confirm = window.confirm("Are you Sure? Delete this Purchase?");
      if (confirm) {
        setLoader(true);
        const res = await updatePurchase(data);
        if (res) {
          // TODO::
          notify("Purchase Deleted Successful!","success");
          // add error hendaler for delete error
          console.log(res);
        } else {
          console.log("Delete Operation Canceled by Purchase!");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  console.log('date', new Date(), "MM-dd-yyyy")

  const handlePurchaseView = (id) => {
    // send purchese id to purchese view modal
    setPurchaseView(id);
    setShow(true);
  };
  const handlePurchaseUpdate = (purchase) => {
    if (purchase.status === "Pending") {
      console.log(purchase._id);
      navigate(`/purchase-update/${purchase._id}`);
    } else {
      notify("This Purchase Can not be Modified", "error");
    }
  };

  const handleOnchangeWareHouseFrom = (e) => {
    if(e.option !== 'no-warehouse'){
      setWarehouse(e.option)
    }else{
      setWarehouse("allWh");
    }
    console.log('handle data:', e)
    
    // console.log('')
    // refetch();
  };
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Purchase"></Header>
            <div className="row">
              <div className="col-md-6">
                {/* Sort date range */}
                <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <div>
                  {auth?.type === "admin" && (
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouseFrom}
                      className="form-control"
                      style={{ width: "30%" }} // Consistent width for custom components
                    />
                  )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Link
                  to="/purchase-create"
                  className="btn btn-dark float-end m-3"
                >
                  Create New Purchase
                </Link>
              </div>

              <div className="col-md-12"></div>
              <div className="col-md-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>PO</th>
                      <th>Date</th>
                      <th>Warehouse</th>
                      <th>Vendor</th>
                      {/* <th>PO By</th> */}
                      <th>Item No</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchase?.length > 0 ? (
                      purchase?.map((purchase) => (
                        <tr key={purchase?._id}>
                          <th>{i++}</th>
                          <td>{purchase?.poNo}</td>
                          <td>
                            {purchase?.createdAt &&
                              format(
                                new Date(purchase?.createdAt),
                                "MM/dd/yyyy"
                              )}
                          </td>
                          <td>{purchase?.warehouse?.name}</td>
                          <td>{purchase?.supplier?.company}</td>
                          {/* <td>{purchase?.po_by}</td> */}
                          <td>{purchase?.totalItem}</td>
                          <td>
                            {new Intl.NumberFormat().format(
                              purchase?.total?.toFixed(2)
                            )}
                          </td>
                          <td>{purchase?.status}</td>
                          <td>
                            <Icons.EyeOutline
                              onClick={() => handlePurchaseView(purchase?._id)}
                              className="icon-eye me-1"
                              size={20}
                            ></Icons.EyeOutline>
                            {purchase?.status === "Pending" && (
                              <Icons.PencilAltOutline
                                onClick={() => handlePurchaseUpdate(purchase)}
                                className="icon-eye me-1"
                                size={20}
                              />
                            )}

                            {/* <Link to={`/update-purchase/${purchase._id}`}>
                              <Icons.PencilAltOutline
                                className="icon-edit"
                                size={20}
                              ></Icons.PencilAltOutline>
                            </Link> */}
                            {purchase?.status === "Pending" && (
                              <Icons.TrashOutline
                                className="icon-trash"
                                onClick={() => deleteHandler(purchase?._id)}
                                size={20}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr colSpan={9}>No Purchase Found</tr>
                      // <tr>
                      //   <td colSpan={9} className="">
                      //     <p className="text-center mt-5 mb-5">
                      //       <Spinner animation="border" variant="dark" />
                      //       <br></br>
                      //       <b>Please Wait Purchase is Loading</b>
                      //     </p>
                      //   </td>
                      // </tr>
                    )}
                    {
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>Grand Total</b>
                        </td>
                        <td>
                          <b>
                            {new Intl.NumberFormat().format(
                              parseFloat(totalPO).toFixed(2)
                            )}
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <Toaster position="bottom-right" />
          </div>
        </div>
      </div>
      <PurchaseView
        show={show}
        handleClose={handleClose}
        purchase={purchaseView}
      ></PurchaseView>
    </div>
  );
};

export default Purchase;
