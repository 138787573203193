import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import "./Customer.css";
import * as Icons from "heroicons-react";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import DatePicker from "react-datepicker";
// import {
//   // useAddCustomerMutation,
//   // useCustomerQuery,
//   // useCustomerPagenationQuery,
//   // useCustomersQuery,
//   // useDeleteCustomerMutation,
//   // useCustomerCountQuery,
//   // useCustomersExportQuery,
// } from "../../services/customerApi";
import { useCustomerPurchaseHistoryQuery } from "../../services/saleApi";

import LoadingModal from "../Common/Modal/LoadingModal";
import { format } from "date-fns";
import SelectCustomerLedger from "../Common/CustomSelect/selectCustomerLedger";

// import * as Icons from "heroicons-react";

import { MaterialReactTable } from "material-react-table";
import { CSVLink } from "react-csv";
import { Box } from "@mui/material";

const CustomerLedger = () => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const [saleData, setSaleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [customerId, setCustomerId] = useState("");

  const { data, error, isLoading, isFetching, isSuccess, isError, refetch } =
    useCustomerPurchaseHistoryQuery({
      start: startDate,
      end: endDate,
      id: customerId,
    });
  useEffect(() => {
    // console.log(data);
    data ? setLoader(false) : setLoader(false);
  }, [data]);

  const [selcetCustomer, setSelcetCustomer] = useState([]);

  useEffect(() => {
    // console.log(selcetCustomer);
    setCustomerId(selcetCustomer?._id);
  }, [selcetCustomer]);

  useEffect(() => {
    console.log(customerId);
  }, [customerId]);

  useEffect(() => {
    setLoader(false);
    if (data?.sale?.length > 0) {
      setSaleData(data?.sale);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setLoader(false);
  }, [isError]);

  useEffect(() => {
    refetch();
    setLoader(true);
  }, [startDate, endDate, customerId]);

  const columns = [
    {
      accessorKey: "createdAt",
      header: "Date",
      size: 40,
    },
    {
      accessorKey: "invoiceNo",
      header: "Invoice",
    },
    {
      accessorKey: "totalItem",
      header: "Items",
      size: 30,
    },
    {
      accessorKey: "grossTotal",
      header: "grossTotal",
      size: 60,
    },
    {
      accessorKey: "total",
      header: "total",
      size: 60,
    },
    {
      accessorKey: "vat",
      header: "vat",
      size: 40,
    },
    {
      accessorKey: "oldPoint",
      header: "oldPoint",
      size: 40,
    },
    {
      accessorKey: "todayPoint",
      header: "todayPoint",
      size: 40,
    },
    {
      accessorKey: "newPoint",
      header: "newPoint",
      size: 40,
    },
    {
      accessorKey: "promoDiscount",
      header: "promoDiscount",
      size: 40,
    },
    {
      accessorKey: "billerId",
      header: "Biller",
    },
    {
      accessorKey: "totalReceived",
      header: "totalReceived",
      size: 60,
    },
    {
      accessorKey: "changeAmount",
      header: "changeAmount",
      size: 60,
    },
    {
      accessorKey: "cash",
      header: "cash",
      size: 60,
    },
    {
      accessorKey: "cardType",
      header: "cardType",
      size: 60,
    },
    {
      accessorKey: "cardAmount",
      header: "cardAmount",
      size: 60,
    },
    {
      accessorKey: "mfsType",
      header: "mfsType",
      size: 60,
    },
    {
      accessorKey: "mfsAmount",
      header: "mfsAmount",
      size: 60,
    },
    {
      accessorKey: "pointUse",
      header: "pointUse",
      size: 60,
    },
    {
      accessorKey: "totalReturn",
      header: "totalReturn",
      size: 60,
    },
    {
      accessorKey: "grosstotalReturn",
      header: "grosstotalReturn",
      size: 60,
    },
    {
      accessorKey: "itemReturn",
      header: "itemReturn",
      size: 60,
    },
    {
      accessorKey: "pointReturn",
      header: "pointReturn",
      size: 60,
    },
  ];

  const [exportHeader, setExportHeader] = useState([]);

  useEffect(() => {
    const tableHeader =
      columns && columns.length > 0
        ? columns.map((column) => ({
            label: column.header,
            key: column.accessorKey,
            status: true,
          }))
        : [];
    setExportHeader(tableHeader);
  }, [columns]);

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Customer Ledger"></Header>
            <div className="row">
              <div className="col-md-5  mt-2 mb-1">
                <form>
                  {/* Sort date range */}
                  <div className="date-picker d-flex gx-2 align-items-center">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    {/* <input
                      type="text"
                      className="form-control"
                      // onChange={(e) => handelSearchInvoice(e)}
                      placeholder="Search invoice id"
                    /> */}
                  </div>
                </form>
              </div>
              <div className="col-md-2 mt-2">
                <SelectCustomerLedger
                  selcetCustomer={selcetCustomer}
                  setSelcetCustomer={setSelcetCustomer}
                />
              </div>
              <div className="col-md-4"></div>

              <div className="col-md-12">
                <div className="card p-2">
                  <p>
                    <b>Customer Details:</b>
                    <b>Name:</b>
                    {selcetCustomer?.name} | <b>Phone:</b>
                    {selcetCustomer?.phone} |
                    {/* <b>JoinDate:</b>
                    {selcetCustomer?.createdAt} |  */}
                    <b>Point:</b>
                    {selcetCustomer?.point} |
                  </p>
                </div>
              </div>

              <div className="col-md-12">
                {saleData?.length > 0 ? (
                  <MaterialReactTable
                    columns={columns}
                    data={saleData}
                    enableRowSelection
                    positionToolbarAlertBanner="bottom"
                    initialState={{ density: "compact" }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          p: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <CSVLink
                          className="btn btn-dark"
                          data={saleData}
                          asyncOnClick={true}
                          headers={exportHeader}
                          filename={`${
                            selcetCustomer?.name
                          } Purchase History - [${today.toDateString()}].csv`}
                          disabled
                        >
                          <Icons.DownloadOutline size={22} /> Download CSV
                        </CSVLink>
                      </Box>
                    )}
                  />
                ) : (
                  <>Sale Data is Loading</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default CustomerLedger;