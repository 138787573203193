import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

const initialState = {
  products: [],
  warehouse: "62b5b575b4facb87eef3b47c",
  total: "0",
  totalItem: "0",
  note: "",
  userId: user?.id,
  print: false,
  status: "active",
};
export const adjustSlice = createSlice({
  name: "adjust",
  initialState: initialState,
  reducers: {
    selectProducts: (state, action) => {
      return {
        ...state,
        products: action.payload,
        totalItem: action.payload.length,
      };
    },
    selectWareHouse: (state, action) => {
      return { ...state, warehouse: action.payload };
    },
    selectTotal: (state, action) => {
      return { ...state, total: action.payload };
    },
    selectTotalItem: (state, action) => {
      return { ...state, totalItem: action.payload };
    },
    selectNote: (state, action) => {
      return { ...state, note: action.payload };
    },
    selectUser: (state, action) => {
      return { ...state, userId: action.payload };
    },
    selectStatus: (state, action) => {
      return { ...state, status: action.payload };
    },
    resetAdjust: () => initialState,
  },
});

export const {
  selectProducts,
  selectWareHouse,
  selectTotal,
  selectTotalItem,
  selectNote,
  selectUser,
  selectStatus,
  resetAdjust,
} = adjustSlice.actions;
export const adjustReducer = adjustSlice.reducer;
