import React, { useState, useEffect } from "react";
import * as Icons from "heroicons-react";
import { Textarea } from "react-daisyui";
import SelectPriceGrn from "../Common/CustomSelect/ScelectPriceGrn";
import SelectTpDamage from "../Common/CustomSelect/SelectTpDamage";

const TpnProducts = ({
  tpnProducts,
  i,
  //   removeQuantities,
  handleCustomQty,
  //   addQuantities,
  removeFromCart,
  handleOnChangePrice,
}) => {
  const [tempQty, setTempQty] = useState([]);

  useEffect(() => {
    let cartTempQty = [];

    tpnProducts?.map((item) => {
      cartTempQty = [...cartTempQty, { id: item.article_code, qty: item.qty }];
    });
    setTempQty(cartTempQty);
  }, [tpnProducts]);
  // console.log(tpnProducts);

  return (
    <>
      {tpnProducts?.length > 0 ? (
        tpnProducts
          ?.slice()
          ?.sort((a, b) => a.order - b.order)
          // sorting a problem hosse // pleas check
          ?.map((item) => (
            <tr key={item?.order}>
              <th>{i++}</th>
              {/* <td>{item?.ean}</td> */}
              <td>{item?.article_code}</td>
              <td>{item?.name}</td>
              {/* <td>{item?.qty}</td> */}
              <td className="col-md-2">
                <div className="input-group ">
                  {/* quantity */}
                  {/* <input
                    type="text"
                    className="form-control quantity"
                    width="40%"
                    id={item?.article_code}
                    onChange={(e) =>
                      handleCustomQty(e.target.value, item?.article_code)
                    }
                    value={item?.qty}
                    defaultValue={item?.qty}
                  /> */}
                  <input
                    type="text"
                    className="form-control quantity"
                    width="40%"
                    id={item?.article_code}
                    onKeyPress={(e) => {
                      if (
                        e.key === ' ' ||
                        (e.key === '.' && e.target.value.includes('.'))
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      // Use regular expression to remove non-numeric and non-decimal characters
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '');
                      handleCustomQty(numericValue, item?.article_code);
                    }}
                    value={item?.qty}
                    defaultValue={item?.qty}
                  />

                </div>
              </td>
              <td>
                <SelectTpDamage
                  sc={item?.id}
                  setVal={item?.tp}
                  handleOnChangeCategory={handleOnChangePrice}
                // handleCustomMrp={handleCustomMrp}
                ></SelectTpDamage>
              </td>
              <td>{parseFloat(item?.tp * item?.qty).toFixed(2)}</td>
              <td>
                <Icons.X
                  size={20}
                  onClick={() => removeFromCart(item?.id)}
                ></Icons.X>
              </td>
            </tr>
          ))
      ) : (
        <tr>
          <td colSpan={7}>
            <b className="text-center py-5 py-5">No Product Selected</b>
            {/* <p className="text-center m-2">
                            Please select product
                        </p> */}
          </td>
        </tr>
      )}
    </>
  );
};

export default TpnProducts;
