import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
// import useSaleByinventoryQuery from "../../../services/saleApi";
import * as Icons from "heroicons-react";
import { useInventoryQuery } from "../../../services/inventoryApi";
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const InventoryViewModal = ({ onShow, handleClose, code }) => {
  const [inventory, setInventory] = useState({});

  // const { data, isSuccess, isLoading, refetch } = useInventoryQuery(code);

  // useEffect(() => {
  //   data && setInventory(data);
  // }, [isSuccess]);

  const getInventory = async (code) => {
    await axios(`${BASE_URL}inventory/${code}`).then((res) => {
      // console.log(res.data);
      setInventory(res.data);
    });
  };
  useEffect(() => {
    getInventory(code);

    // console.log("view In", code);
  }, [code]);

  // console.log("view", code);
  const handleCancel = () => {
    // setInventoryDetails([]);
    handleClose();
  };

  return (
    <Modal size="lg" show={onShow} onHide={handleClose}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Icons.ArchiveOutline size={24} className="text-success me-2">
              {" "}
            </Icons.ArchiveOutline>
            {inventory?.article_code &&
              `Inventory Code: ${inventory?.article_code}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={6}>
                <p>
                  {" "}
                  <b>Name:</b> {inventory?.name}{" "}
                </p>
                <p>
                  {" "}
                  <b>Article Code:</b> {inventory?.article_code}{" "}
                </p>
                <p>
                  {" "}
                  <b>Opening Qty:</b> {inventory?.openingQty}{" "}
                </p>
                <p>
                  {" "}
                  <b>Current Qty:</b> {inventory?.currentQty?.toFixed(2)}{" "}
                </p>
                <p>
                  {" "}
                  <b>Total Qty:</b> {inventory?.totalQty?.toFixed(2)}{" "}
                </p>
                <p>
                  {" "}
                  <b>Sold Qty:</b> {inventory?.soldQty?.toFixed(2)}{" "}
                </p>
                <p>
                  {" "}
                  <b>SReturn Qty:</b>{" "}
                  {inventory?.soldReturnQty ? inventory?.soldReturnQty : 0}{" "}
                </p>
                <p>
                  {" "}
                  <b>Damage Qty:</b> {inventory?.damageQty?.toFixed(2)}{" "}
                </p>
                <p>
                  {" "}
                  <b>RTV Qty:</b> {inventory?.rtvQty}{" "}
                </p>
                <p>
                  {" "}
                  <b>tpn Qty:</b> {inventory?.tpnQty ? inventory?.tpnQty : 0}{" "}
                </p>
                <p>
                  {" "}
                  <b>Adjust Qty:</b>{" "}
                  {inventory?.adjustQty ? inventory?.adjustQty : 0}{" "}
                </p>

                <p>
                  {" "}
                  <b>Status:</b>{" "}
                  {inventory?.currentQty > 0 ? (
                    <small className="text-success">In Stock</small>
                  ) : (
                    <small className="text-danger">Out Of Stock</small>
                  )}
                </p>
              </Col>
              <Col xs={6} md={6}>
                <p>
                  <b>Partial Iventory</b>
                </p>
                {inventory?.priceTable?.length > 0 ? (
                  inventory?.priceTable?.map((stock) => (
                    <div key={stock}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Opn</th>
                            <th>Stk</th>
                            <th>Total</th>
                            <th>Sold</th>
                            <th>SReturn</th>
                            <th>Dmg</th>
                            <th>RTV</th>
                            <th>TPN</th>
                            <th>Adj</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{stock?.openingQty}</td>
                            <td>{stock?.currentQty?.toFixed(2)}</td>
                            <td>{stock?.totalQty?.toFixed(2)}</td>
                            <td>{stock?.soldQty?.toFixed(2)}</td>
                            <td>
                              {stock?.soldReturnQty
                                ? stock?.soldReturnQty?.toFixed(2)
                                : (0).toFixed(2)}
                            </td>
                            <td>{stock?.damageQty?.toFixed(2)}</td>
                            <td>{stock?.rtvQty?.toFixed(2)}</td>
                            <td>{stock?.tpnQty?.toFixed(2)}</td>
                            <td>
                              {stock?.adjustQty
                                ? stock?.adjustQty?.toFixed(2)
                                : (0).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        {stock.id !== null ? (
                          <small>
                            {console.log("stock", stock)}
                            <b>TP: </b> {stock?.id?.tp} BDT | <b>MRP: </b>{" "}
                            {stock?.id?.mrp} BDT | <b>WH: </b>{" "}
                            {stock?.id?.warehouse.name}
                            <br />
                            <b>Stock Value: </b>{" "}
                            {parseInt(
                              parseFloat(stock?.id?.tp) *
                                parseFloat(stock?.currentQty)
                            )}{" "}
                            BDT |<b> Supplier: </b>{" "}
                            {stock?.id?.supplier.company}
                          </small>
                        ) : (
                          <small>Price Information is not found</small>
                        )}
                      </p>
                      <hr />
                    </div>
                  ))
                ) : (
                  <span>No inventory found</span>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCancel}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default InventoryViewModal;
