import React, { Component, Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useAccountheadSubQuery, useMasterAccountheadsQuery } from "../../../services/accountHeadApi";

const SelectSubAccountHead = ({ data, isSuccess, error, handleOnchange }) => {

  let [accountHead, setAccountHead] = useState([]);
  // let [vendor, setVendor] = useState([]);
  console.log(data)


  useEffect(() => {
    console.log(error);
    let accountHeads = [
      { option: "no-accountHeads", label: "Select accountHeads Category" },
    ];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((accountHead) => {
          accountHeads = [
            ...accountHeads,
            {
              option: accountHead?._id,
              label: `${accountHead?.name} - ( ${accountHead?.code} )`,
              code: accountHead?.code,


            },
          ];
        });
        setAccountHead(accountHeads);
      }
    }
  }, [data, error]);

  // console.log(accountHead);
  // const OnChangeSelect = (value) => {
  //   console.log(value);
  // };

  // useEffect(() => {
  //   let vendors = [];
  //   if (accountHead.length > 0) {
  //     accountHead.map((vendor) => {
  //       vendors = [
  //         ...vendors,
  //         {
  //           option: vendor._id,
  //           label: vendor.name,
  //         },
  //       ];
  //     });
  //     setVendor(vendors);
  //   }
  // }, [accountHead]);
  // console.log(vendor)

  // console.log(vendor.filter((sel) => sel?.option?.toString() === accountHead_code?.toString()))

  return (
    <Fragment>
      <Select
        className="select"
        classNamePrefix="Select AccountHead"
        defaultValue={accountHead[0]}
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        value={accountHead[accountHead.indexOf(accountHead)]}
        isSearchable={true}
        name="accountHead_code"
        onChange={handleOnchange}
        options={accountHead}
      />

      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      ></div>
    </Fragment>
  );
};

export default SelectSubAccountHead;
