import React, { useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import * as Icons from "heroicons-react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useAdjustQuery } from "../../../services/adjustApi";
import AdjustPrint from "../../Adjust/AdjustPrint";

const AdjustViewModal = ({ onShow, handleClose, adjust }) => {
  // const [adjust, setadjust] = useState({});
  console.log(adjust);
  const adjustView = useAdjustQuery(adjust?._id);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(adjustView);
  return (
    <Modal
      show={onShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>Adjust </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdjustPrint ref={componentRef} adjust={adjustView.data}></AdjustPrint>
        {/* <PO ref={componentRef} purchase={purchaseView.data} /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button className="btn btn-dark float-end" onClick={handlePrint}>
          <Icons.PrinterOutline className="ms-3" size={18} /> Print{" "}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdjustViewModal;
