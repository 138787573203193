import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const CategorySelectByMC = ({ sc, handleOnChangeCategory, name, scValue }) => {
  let [category, setCategory] = useState([]);
  let [selectCategory, setSelectCategory] = useState([]);
  let [options, setOptions] = useState([]);
  // console.log(scValue);

  const getCategory = async (sc) => {
    let cancelToken;
    let result;

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    if (sc) {
      result = await axios.get(`${BASE_URL}category/mc/${sc}`, {
        cancelToken: cancelToken.token,
      });
    } else {
      result = await axios.get(`${BASE_URL}category`, {
        cancelToken: cancelToken.token,
      });
    }
    setCategory(result.data);
    // console.log(result.data)
  };

  // console.log(category);

  useEffect(() => {
    getCategory(sc);
    setSelectCategory(scValue);
  }, [sc, scValue]);

  //   console.log(category);
  //   const id = "5410235"

  useEffect(() => {
    let categories = [{ option: "All", label: "All Categories" }];
    if (category.length) {
      category.map((vendor) => {
        // console.log(vendor)
        // let item = vendor.products.find((v) => v.article_code === product);
        // if (item) {
        categories = [
          ...categories,
          {
            option: vendor._id,
            label: `${vendor?.name} - ${vendor.mcId}`,
          },
        ];
      });
    }
    setOptions(categories);
  }, [category]);

  // console.log(options)
  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={{ option: "All", label: "All Categories" }}
        // value={category[category.indexOf(scValue)]}
        value={
          selectCategory &&
          options[options.map((obj) => obj.option).indexOf(selectCategory)]
        }
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        onChange={handleOnChangeCategory}
        name={name}
        options={options}
      />
    </Fragment>
  );
};

export default CategorySelectByMC;
