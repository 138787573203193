import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Inventory.css";
import * as Icons from "heroicons-react";
import { Table, Button } from "react-bootstrap";
import {
  useInventoriesQuery,
  useInventoryCountQuery,
} from "../../services/inventoryApi";
import ReactPaginate from "react-paginate";
import InventoryViewModal from "../Common/Modal/InventoryViewModal";
import InventoryExport from "./InventorySession/InventoryExport";
import ExportInventory from "../Common/Modal/ExportInventory";
import LoadingModal from "../Common/Modal/LoadingModal";
import { IconName } from "react-icons/fi";
import axios from "axios";
import ExportNav from "../Common/Nav/ExportNav";
import { InventoryRoutes } from "../../data";
import { signInUser } from "../Utility/Auth";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import CsvDownloader from "../Common/CsvDownloader/CsvDownloader";
import { format } from "date-fns";
// import useInventory from '../Hooks/useInventory';
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

const Inventory = () => {
  let i = 1;
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [onShow, setOnShow] = useState(false);
  const [onExportShow, setOnExportShow] = useState(false);
  const [code, setCode] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [loader, setLoader] = useState(true);
  const auth = signInUser();

  const handleLoaderClose = () => setLoader(false);
  const handleClose = () => setOnShow(false);
  const handleExportClose = () => setOnExportShow(false);

  const { data, error, isSuccess, refetch } = useInventoriesQuery({
    pageNo,
    size,
    warehouse,
    // q,
  });

  useEffect(() => {
    refetch();
  }, [warehouse]);

  useEffect(() => {
    setWarehouse(auth?.warehouse);
  }, [auth]);

  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  // get total product count
  const pageCountQuery = useInventoryCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPageNo(parseInt(data?.selected) + 1);
    refetch();
  };

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    refetch();
  };

  const handleViewInventory = (code) => {
    setCode(code);
    setOnShow(true);
  };

  const handelExportModal = () => {
    setOnExportShow(true);
  };

  // Columns for MaterialReactTable
  const columns = [
    { accessorKey: "article_code", header: "Article Code", size: 120 },
    { accessorKey: "name", header: "Name", size: 160 },
    { accessorKey: "unit", header: "Unit", size: 140 },
    {
      accessorKey: "openingQty",
      header: "Opening Stock",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "totalQty",
      header: "Purchase Qty (GRN)",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "soldReturnQty",
      header: "Sales Return Qty",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "adjustQty",
      header: "Receive Adjustment",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "currentQty",
      header: "Available Stock",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "soldQty",
      header: "Sale Qty",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "rtvQty",
      header: "RTV Qty",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "damageQty",
      header: "Damage Qty",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "otherAdjustQty",
      header: "Issue Adjustment",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "closingStock",
      header: "Closing Stock",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "tp",
      header: "TP",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "mrp",
      header: "MRP",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
    {
      accessorKey: "stockValue",
      header: "Stock Value",
      Cell: ({ cell }) => parseFloat(cell.getValue() || 0).toFixed(2),
    },
  ];

  const preHeader = [
    ["The Community Magasin"],
    ["House-06, Road-27, Sector-07, Uttara, Dhaka-1230"],
    ["Inventory Details Report"],
    [], // Empty row for spacing
  ];

  console.log("DATA::>", data);

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Products Inventory"></Header>
            {/* <div className="row mt-3">
              <div className="col-md-5">
                <form action="javasctipt:void(0)">
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                  </div>
                </form> */}

            {/* Pagenation */}
            {/* <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={size < 100 ? 1 : Math.ceil(pageCount / size)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav> */}
            {/* </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    data={data}
                    title="Inventory"
                    Routes={InventoryRoutes}
                  />
                </span>
              </div>
            </div> */}

            {/* Table */}
            <div className="text-center">
              <MaterialReactTable
                columns={columns}
                data={data || []}
                // enableRowSelection
                enableRowVirtualization // Enables row virtualization
                positionToolbarAlertBanner="bottom"
                initialState={{ density: "compact" }}
                renderTopToolbarCustomActions={() => ( 
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      p: "0.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <CsvDownloader
                      preheader={preHeader}
                      buttonName="Download Inventory Data"
                      data={data}
                      fileName={`Export Inventory - [${today?.toDateString()}].csv`}
                    />
                  </Box>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <InventoryViewModal
        onShow={onShow}
        handleClose={handleClose}
        code={code}
      />
      <ExportInventory
        pageCountQuery={pageCountQuery?.data}
        onShow={onExportShow}
        handleClose={handleExportClose}
      />
    </div>
  );
};

export default Inventory;
