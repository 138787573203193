import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import { Button, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { compareAsc, format } from "date-fns";
import {
  useAdjustByDateQuery,
  useAdjustsQuery,
  useDeleteAdjustMutation,
} from "../../services/adjustApi";
import * as Icons from "heroicons-react";
import { notify } from "../Utility/Notify";
import AdjustViewModal from "../Common/Modal/AdjustViewModal";
import axios from "axios";
import { signInUser } from "../Utility/Auth";

const Adjust = () => {
  let i = 1;
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  const [adjust, setAdjust] = useState([]);

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);

  const auth = signInUser();

  const { data, isLoading, isSuccess, isFetching, refetch } =
    useAdjustByDateQuery({
      startDate,
      endDate,
    });
  console.log("adjust", data);

  const [deleteAdjust] = useDeleteAdjustMutation();
  // const data = []
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const adjustDetailsHandler = async (id) => {
    console.log("adjust id", id);
    const adjust = await axios.get(`${BASE_URL}adjust/${id}`);
    // console.log(adjust.data);
    setAdjust(adjust.data);
    setOnShow(true);
  };

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you Sure? Delete this Adjust?");
      if (confirm) {
        // setLoader(true);
        const res = await deleteAdjust(id);
        console.log(res);
        if (res.data) {
          notify("Adjust Delete successful", "success");
        } else {
          notify("Delete Operation Canceled!", "error");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      // setLoader(false);
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Adjust"></Header>
            <div className="row">
              <div>
                <div>
                  <Button
                    className="btn btn-dark mb-2 float-end mt-2 me-2"
                    onClick={() => {
                      navigate("/create-adjust");
                    }}
                  >
                    Create Adjust
                  </Button>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-6">
                  {/* Sort date range */}
                  <div className="date-picker d-flex mt-2 mb-2 align-items-center gap-2">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>
                </div>
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Adjust ID</th>
                      <th scope="col">Adjust Date</th>
                      <th scope="col">Prepared By</th>
                      <th scope="col">Total items</th>
                      <th scope="col">Reason</th>
                      <th scope="col">warehouse</th>
                      <th scope="col">Total</th>
                      <th scope="col">status</th>
                      <th scope="col">ActionBtn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((adjust) => (
                        <tr key={adjust._id}>
                          <th scope="row">{i++}</th>
                          <td>{adjust?.adjustNo}</td>
                          <td>
                            {adjust?.createdAt &&
                              format(new Date(adjust.createdAt), "MM/dd/yyyy")}
                          </td>
                          <td>{adjust?.userId?.name}</td>
                          <td>{adjust?.products?.length}</td>
                          <td>{adjust.note}</td>
                          <td>{adjust?.warehouse?.name}</td>
                          <td>{adjust?.total?.toFixed(2)}</td>
                          <td>{adjust.status}</td>
                          <td>
                            <Icons.EyeOutline
                              className="icon-eye"
                              onClick={() => adjustDetailsHandler(adjust._id)}
                              size={20}
                            ></Icons.EyeOutline>
                            {adjust.status !== "Canceled" &&
                              auth?.type === "admin" && (
                                <Icons.TrashOutline
                                  className="icon-trash"
                                  onClick={() => deleteHandler(adjust._id)}
                                  size={20}
                                />
                              )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr colSpan={9}>No adjust Found</tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdjustViewModal
        onShow={onShow}
        handleClose={handleClose}
        adjust={adjust}
      ></AdjustViewModal>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Adjust;
