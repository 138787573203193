import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icons from "heroicons-react";
import { Dropdown, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";

const ExportNav = ({ Routes, data, title }) => {
  let active = window.location.pathname;
  const [tableHeader, setTableHeader] = useState([]);
  const location = useLocation();
  // console.log(location.pathname);

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  // console.log("Routes", Routes);

  useEffect(() => {
    if (location.pathname === "/sales") {
      const tableHeader = [
        // { label: "_id", key: "_id" },
        { label: "InvoiceId", key: "invoiceId" },
        { label: "Total Item", key: "totalItem" },
        { label: "MRP Total", key: "mrpSale" },
        { label: "Discount", key: "discount" },
        { label: "Promo Discount", key: "promo_discount" },
        { label: "Vat", key: "vat" },
        { label: "Return MPR Total", key: "returnTotal" },
        { label: "Gross Total", key: "grossSale" },
        { label: "Roundup Amount", key: "roundUpAmount" },
        { label: "Gross Total Rounded", key: "roundedGrossTotal" },
        { label: "Net Sale", key: "netSale" },
        { label: "Customer Name", key: "customerName" },
        { label: "Customer Number", key: "customerPhone" },
        { label: "Point", key: "point" },
        { label: "Cash", key: "cash" },
        { label: "Bkash", key: "bkash" },
        { label: "Nagad", key: "nagad" },
        { label: "Rocket", key: "rocket" },
        { label: "Visa", key: "visa" },
        { label: "Master Card", key: "masterCard" },
        { label: "Upay", key: "upay" },
        { label: "AMEX", key: "AMEX" },
        { label: "BRAC", key: "BRAC" },
        { label: "CITY", key: "CITY" },
        { label: "DBBL", key: "DBBL" },
        { label: "EBL", key: "EBL" },
        { label: "MTB", key: "MTB" },
        { label: "Total Receivable", key: "totalReceivable" },
        { label: "Total Received", key: "totalReceived" },
        { label: "Change Amount", key: "changeAmount" },
        { label: "Vat Sale", key: "vatSale" },
        { label: "NonVat Sale", key: "nonVatSale" },
        { label: "TP Total", key: "cogs" },
        { label: "Return TP Total", key: "returnTp" },
        { label: "Customer Name", key: "customerName" },
        { label: "Biller Name", key: "billerName" },
        { label: "CreatedAt", key: "createdAt" },
        { label: "Status", key: "status" },
      ];
      setTableHeader(tableHeader);
    } else {
      const tableHeader =
        data && data.length > 0
          ? Object.keys(data[0]).map((key) => ({
              label: key,
              key: key,
              status: true,
            }))
          : [];
      setTableHeader(tableHeader);
    }
  }, [Routes, data]);

  // console.log(data);

  return (
    <nav className="nav ">
      {Routes.map((link) => {
        return (
          <Link
            class={`nav-link ${active === link.url && "active"}`}
            to={link.url}
          >
            <Icons.TableOutline /> {link.label}
          </Link>
        );
      })}

      <div className="nav-Link">
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-basic">
            <Icons.DownloadOutline /> Export
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {data?.length > 0 ? (
              <CSVLink
                className="dropdown-item"
                data={data}
                asyncOnClick={true}
                headers={tableHeader}
                filename={`Export ${title} - [${today.toDateString()}].csv`}
                disabled
              >
                <Icons.DocumentTextOutline size={22} /> CSV
              </CSVLink>
            ) : (
              <div className="dropdown-item">
                <Icons.DocumentTextOutline size={22} /> Loading Data..
              </div>
            )}

            <Dropdown.Item href="#/action-3">
              <Icons.PrinterOutline /> Print
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default ExportNav;
