import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Barcode from "react-barcode";
import Logo from "../../../../../logo.png";
import { signInUser } from "../../../../Utility/Auth";
import { useWarehouseQuery } from "../../../../../services/warehouseApi";

const GrnHeader = ({ grn, format, title }) => {
  console.log(grn);
  return (
    <>
      <div className="row pt-3 pb-3 mb-5 border-bottom">
        <div className="col-7">
          <img src={Logo} height="40" alt="not Image" />
        </div>

        <div className="col-5">
          <p className="p-0 m-0 text-end">
            <b>Hotline: </b> 01332553955
          </p>
          <p className="p-0 m-0 text-end">
            <small>{grn?.warehouse?.name}</small>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-6 print-header-text">
          <p>
            <b>To</b>
            <br />
            {grn?.supplier?.company} <br />
            {grn?.supplier?.email} <br />
            {grn?.supplier?.phone} <br />
            {grn?.supplier?.address} <br />
          </p>

          <p>
            <b>Form</b> <br />
            The Community Magasin <br />
            communitymagasin@gmail.com <br />
            +880 1747-563337
            <br />
            Uttara, Dhaka - 1230 <br />
          </p>
        </div>
        <div className="col-6 text-end">
          {!grn?.print ? <h4>[ORIGINAL]</h4> : <h4>[DUPLICATE]</h4>}

          <h4>{title}</h4>
          <span className="ps-6 d-block">
            <p>
              Rtv No: {grn?.rtvNo}
              <br />
              RTV Date:{" "}
              {grn?.createdAt &&
                format(new Date(grn?.createdAt), "yyyy-MM-dd")}{" "}
              <br />
              RTV Time:{" "}
              {grn?.createdAt && format(new Date(grn?.createdAt), "h:m:s aaa")}
              <br />
            </p>
            <p className="text-end bar-code">
              <Barcode value={grn?._id} height="60" width="2" fontSize="10" />
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default GrnHeader;
