import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
// import { skipToken } from "@reduxjs/toolkit/dist/query";
import "./Products.css";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
// import ProductImportModal from "../Common/Modal/ProductImportModal";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  useProductCountQuery,
  useDeleteProductMutation,
  useProductPagenationQuery,
} from "../../services/productApi";
import ReactPaginate from "react-paginate";
import ProductBarCodeModal from "../Common/Modal/ProductBarCodeModal";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { useProductInfoQuery } from "../../services/productApi";
import { FaBarcode } from "react-icons/fa";
import LoadingModal from "../Common/Modal/LoadingModal";
import productPhoto from "../../product.jpg";
import { signInUser } from "../Utility/Auth";

const Products = (props) => {
  const [show, setShow] = useState(false);
  const [showP, setPShow] = useState(false);

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePClose = () => setPShow(false);
  const handlePShow = () => setPShow(true);

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");

  const [barcodeData, setBarCodeData] = useState({});
  const [productPrice, setProductPrice] = useState({});
  const [productData, setProductData] = useState({});

  // Price table pop up
  const [selectedProduct, setSelectedProduct] = useState("");

  const auth = signInUser();

  // get totel product count
  const pageCountQuery = useProductCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useProductPagenationQuery({
      page,
      size,
      q,
    });

  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);
  // console.log(page, size, q, "Products:", data);

  const [deleteProduct] = useDeleteProductMutation();

  const deleteHandler = async (id) => {
    const confirm = window.confirm("Are you Sure? Delete this Product?");
    if (confirm) {
      const res = await deleteProduct(id);
      if (res) {
        // TODO::
        // add error hendaler for delete error
        console.log(res);
      } else {
        console.log("Delete Operation Canceled by Category!");
        return;
      }
    }
  };
  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };
  // console.log(pageNo);
  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleBarCode = (code, mrp, name) => {
    setBarCodeData({
      code: code,
      mrp: mrp,
      name: name,
    });
    handleShow();
    console.log(code, name, mrp);
  };

  const handelPriceUpdateModal = (id) => {
    // console.log(productInfo.data)
    setSelectedProduct(id);
    // console.log(productInfo)
    // console.log(id);
    handlePShow();
    // data.refetch()
  };

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="All Products"></Header>
            <div className="row mt-3">
              <div className="col-md-6">
                <form action="javascript:void(0);">
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>
                {/* Pagenation */}

                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
              </div>
              <div className="col-md-6">
                <span className="float-end">
                  {/* <button className='btn btn-dark me-2' onClick={handleShow}><Icons.UploadOutline size={22}></Icons.UploadOutline> Import Product</button> */}
                  <Link
                    className="btn btn-dark mb-2 float-end"
                    to="/product/add"
                  >
                    <Icons.PlusOutline size={22}></Icons.PlusOutline> Add
                    Product
                  </Link>
                  {/* <Link
                    className="btn btn-dark mb-2 float-end me-2"
                    to="/price/import"
                  >
                    <Icons.PlusOutline size={22}></Icons.PlusOutline> Import
                    Price
                  </Link> */}
                  <Link
                    className="btn btn-dark mb-2 float-end me-2"
                    to="/product/import"
                  >
                    <Icons.PlusOutline size={22}></Icons.PlusOutline> Import
                    Product
                  </Link>
                  {/* <Link
                    className="btn btn-dark mb-2 float-end me-2"
                    to="/product/ledger"
                  >
                    <Icons.ChartBarOutline size={22}></Icons.ChartBarOutline>
                    Product Ledger
                  </Link> */}
                </span>
              </div>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>BC</th>
                  <th>Photo</th>
                  <th>EAN</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((product) => (
                    <tr key={product._id}>
                      <td>
                        <FaBarcode
                          onClick={() =>
                            handleBarCode(
                              product.article_code,
                              product?.priceList !== null &&
                                product?.priceList?.length > 0
                                ? product.priceList[0].mrp
                                : 0,
                              product.name
                            )
                          }
                          size={25}
                        />
                      </td>
                      {console.log(product?.photo)}
                      <td>
                        <img
                          src={`${process.env.REACT_APP_PHOTO_URL}${product?.photo}`}
                          height={50}
                          width={50}
                          onError={(e) => {
                            e.target.src = productPhoto;
                          }}
                        />
                      </td>
                      <td>{product.ean}</td>
                      <td>{product.name}</td>
                      <td>{product.article_code}</td>
                      <td>{product?.category?.name}</td>
                      {/* <td>{product.mcId}</td> */}
                      <td>
                        <p>
                          <Icons.CurrencyBangladeshiOutline
                            className="icon-mrp"
                            onClick={() => handelPriceUpdateModal(product._id)}
                            size={20}
                          ></Icons.CurrencyBangladeshiOutline>
                          {/* <Link to={`/product/${product._id}`} target="_blank">
                            <Icons.EyeOutline
                              className="icon-eye"
                              size={20}
                            ></Icons.EyeOutline>
                          </Link> */}

                          <Link
                            to={`/product/update/${product._id}`}
                            target="_blank"
                          >
                            <Icons.PencilAltOutline
                              className="icon-edit"
                              size={20}
                            ></Icons.PencilAltOutline>
                          </Link>
                          {auth?.type === "admin" && (
                            <Icons.TrashOutline
                              className="icon-trash"
                              size={20}
                              onClick={() => deleteHandler(product._id)}
                            />
                          )}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  // <tr>
                  //   <td colSpan={6}>
                  //     {isLoading && <Skeleton count={5} />}
                  //     {isFetching && <h2>...isFetching</h2>}
                  //     {error && <h2>Something went wrong</h2>}
                  //   </td>
                  // </tr>
                  // <tr>
                  //   <td colSpan={6}>
                  //     {isLoading && <Skeleton count={100} />}
                  //     {/* {isFetching && <h2>...isFetching</h2>}
                  //     {error && <h2>Something went wrong</h2>} */}
                  //   </td>
                  // </tr>
                  <tr colSpan={9}>No Product Found</tr>
                )}
              </tbody>
            </Table>
            {/* pagination */}
            {/* <ProductDataTable></ProductDataTable> */}
          </div>
        </div>
      </div>
      {/* <ProductImportModal
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                handleImportButton={handleImportButton}  
                setCsvData={setCsvData}
            ></ProductImportModal> */}
      <ProductBarCodeModal
        show={show}
        handleClose={handleClose}
        barcodeData={barcodeData}
      ></ProductBarCodeModal>
      {selectedProduct && (
        <ProductPriceModal
          show={showP}
          handleClose={handlePClose}
          animation={false}
          productId={selectedProduct}
        ></ProductPriceModal>
      )}
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Products;
