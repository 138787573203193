import React from 'react';

const NotFound = () => {
    return (
        <div  className="text-center">
            <h1>404</h1>
            <p>Not Found</p>
        </div>
    );
};

export default NotFound;