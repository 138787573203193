import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ProductPriceModal from "../Common/Modal/ProductPriceModal";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModal from "../Common/Modal/LoadingModal";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { useRtvSummaryQuery } from "../../services/rtvApi";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import ExportNav from "../Common/Nav/ExportNav";
import { InventoryRoutes, RtvRoutes } from "../../data";
import { useProductMovementQuery } from "../../services/productApi";

function InventorySummary() {
  // Date
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [exportData, setExportData] = useState([]);

  const [loader, setLoader] = useState(true);

  const { data, isSuccess, refetch } = useProductMovementQuery({
    startDate,
    endDate,
  });

  console.log(data);

  useEffect(() => {
    setLoader(false);
  }, [isSuccess]);

  // const [articleHeader, setArticleHeader] = useState([
  //   { label: "article_code", key: "article_code", status: true },
  //   { label: "name", key: "name", status: true },
  //   { label: "currentQty", key: "currentQty", status: true },
  //   { label: "avgTP", key: "avgTP", status: true },
  //   { label: "avgMRP", key: "avgMRP", status: true },
  //   { label: "supplier", key: "supplier", status: true },
  //   { label: "stockValue", key: "stockValue", status: true },
  //   { label: "status", key: "status", status: true },
  // ]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const handleLoaderClose = () => setLoader(false);
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Inventory Summary Export"></Header>
            <div className="row mt-3 mb-2">
              <div className="col-md-5">
                {/* Sort date range */}
                <div className="date-picker d-flex mb-2 align-items-center">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    // onChange={(e) => handelSearchInvoice(e)}
                    placeholder="Search Article Code"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <span className="float-end">
                  <ExportNav
                    Routes={InventoryRoutes}
                    data={exportData}
                    title="Inventory Summary Export"
                  />
                </span>
              </div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th scope="col">Article Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Opening Quantity</th>
                  <th scope="col">Current Quantity</th>
                  <th scope="col">Total Quantity</th>
                  <th scope="col">Total Sold</th>
                  <th scope="col">Total SoldReturn</th>
                  <th scope="col">Total Damage</th>
                  <th scope="col">Total RTV</th>
                  <th scope="col">Total TPN</th>
                  <th scope="col">Total Adjust</th>
                  <th scope="col">Unit</th>
                  {/* <th scope="col">Status</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((pro) => (
                    <tr key={pro._id}>
                      <td>{pro.article_code}</td>
                      <td>{pro.name}</td>
                      <td>{pro?.grnQty}</td>
                      <td>{pro?.grnQty?.toFixed(2)}</td>
                      <td>{pro?.grnQty?.toFixed(2)}</td>
                      <td>{pro.soldQty?.toFixed(2)}</td>
                      <td>{pro.soldReturnQty?.toFixed(2)}</td>
                      <td>{pro.damageQty?.toFixed(2)}</td>
                      <td>{pro.rtvQty?.toFixed(2)}</td>
                      <td>{pro.tpnQty?.toFixed(2)}</td>
                      <td>{pro.adjustQty?.toFixed(2)}</td>
                      <td>{pro.unit}</td>
                    </tr>
                  ))
                ) : (
                  <tr colSpan={7}>No Product Found</tr>
                )}
                {/* <tr>
                  <td className="text-end" colSpan={6}>
                    <b>TOTAL STOCK VALUE:</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {typeof data?.stockValue === "number"
                        ? new Intl.NumberFormat().format(data?.stockValue)
                        : 0}
                    </b>
                  </td>
                </tr> */}
              </tbody>
            </Table>
            {/* pagination */}
            {/* <ProductDataTable></ProductDataTable> */}
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" />
    </div>
  );
}

export default InventorySummary;
