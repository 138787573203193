import React from "react";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import logo from "../../../logo.png";
import "./SideBar.css";
import AdminSideBar from "./AdminSideBar";
import EcomSideBar from "./EcomSideBar";
import ManagerSideBar from "./ManagerSideBar";
import SupervisorSideBar from "./SupervisorSideBar";
import AccountSideBar from "./AccountSideBar";
import PosSideBar from "./PosSideBar";

const SideBar = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {user.type === "admin" && <AdminSideBar />}
      {user.type === "ecom" && <EcomSideBar />}
      {user.type === "manager" && <ManagerSideBar />}
      {user.type === "supervisor" && <SupervisorSideBar />}
      {user.type === "accounts" && <AccountSideBar />}
      {user.type === "POS" && <PosSideBar />}
    </>
  );
};

export default SideBar;
