import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Barcode from "react-barcode";
import Logo from "../../../../../logo.png";

const GrnHeaderTpn = ({ grn, format, title }) => {
  // const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  // const [supplier, setSupplier] = useState({});
  // const getSupplier = async () => {
  //   await axios
  //     .get(`${BASE_URL}supplier/${grn?.supplier}`)
  //     .then((response) => setSupplier(response.data));
  // };
  // useEffect(() => {
  //   getSupplier();
  // }, [grn]);
  console.log(grn);
  return (
    <>
      {/* <h1>hi</h1> */}
      <div className="row pt-3 pb-3 mb-5 border-bottom">
        <div className="col-7">
          <img src={Logo} height="40" alt="not Image" />
        </div>

        <div className="col-5">
          <p className="p-0 m-0 text-end">
            <b>Hotline: </b> 01332553955
          </p>
          <p className="p-0 m-0 text-end">
            <small>H#6, R#27, Sector 7, Uttara, Dhaka - 1230</small>{" "}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-6 print-header-text">
          <p>
            <b>To</b>
            <br />
            {grn?.tpnNo?.warehouseFrom?.company} <br />
            {grn?.tpnNo?.warehouseFrom?.name} <br />
            {grn?.tpnNo?.warehouseFrom?.phone} <br />
            {grn?.tpnNo?.warehouseFrom?.address} <br />
          </p>

          <p>
            <b>Form</b> <br />
            {grn?.tpnNo?.warehouseTo?.company} <br />
            {grn?.tpnNo?.warehouseTo?.name} <br />
            {grn?.tpnNo?.warehouseTo?.phone} <br />
            {grn?.tpnNo?.warehouseTo?.address} <br />
          </p>
        </div>
        <div className="col-6 text-end">
          {!grn?.print ? <h4>[ORIGINAL]</h4> : <h4>[DUPLICATE]</h4>}

          <h4>{title}</h4>
          <span className="ps-6 d-block">
            <p>
              GRN No: {grn?.grnNo}
              <br />
              TPN No: {grn?.tpnNo?.tpnNo}
              <br />
              TPN Date:{" "}
              {grn?.createdAt &&
                format(new Date(grn?.createdAt), "yyyy-MM-dd")}{" "}
              <br />
              TPN Time:{" "}
              {grn?.createdAt && format(new Date(grn?.createdAt), "h:m:s aaa")}
              <br />
            </p>
            <p className="text-end bar-code">
              <Barcode value={grn?._id} height="60" width="2" fontSize="10" />
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default GrnHeaderTpn;
