import React from "react";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as Icons from "heroicons-react";
import {
  useAccountheadsQuery,
  useDeleteAccountHeadMutation,
} from "../../services/accountHeadApi";
import { notify } from "../Utility/Notify";

const AccountHeads = () => {
  let i = 1;
  const { data, error, isLoading, isFetching, isSuccess } =
    useAccountheadsQuery();
  const [deleteAccountsHead] = useDeleteAccountHeadMutation();
  // console.log(data);
  const handleDataLimit = () => {};
  const handleSearch = () => {};
  const deleteHandler = async (e) => {
    // console.log(e);
    const confirm = window.confirm("Are you Sure? Delete this Category?");
    if (confirm) {
      if (e == "643e3ff315c557ac29f9d7e7" || e == "643e3fd515c557ac29f9d7e2") {
        notify("Can Not delete This Account Head", "error");
      } else {
        await deleteAccountsHead(e)
          .then((res) => {
            notify("Account Head is Deleted", "success");
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            notify("Account Head Delete Canceled", "success");
          });
      }
    } else {
      notify("Account Head Delete Canceled", "error");
    }
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <Header title="All Accounts Head"></Header>
              </div>
              <div className="col-md-6 mt-2 mb-2">
                <form>
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      onClick={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>
              </div>
              {/* <div className="col-md-9  mt-2 mb-2">Filters</div> */}
              <div className="col-md-6  mt-2 mb-2">
                <Link to="/addAccountHead" className="btn btn-dark float-end">
                  <Icons.Plus className="icon-edit" size={20}></Icons.Plus>
                  Add AccountHead
                </Link>
              </div>
            </div>

            <Table hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Code</th>
                  <th scope="col">Sub AccountHead</th>
                  <th scope="col">AccountHead</th>
                  {/* <th scope="col">Group</th> */}
                  <th scope="col">AccountHead Code</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions </th>
                </tr>
              </thead>
              <tbody>
                {/* {isLoading && <h2>...Loading</h2>}
                {isFetching && <h2>...isFetching</h2>}
                {error && <h2>Something went wrong</h2>} */}
                {data?.length > 0 ? (
                  data?.map((acHead) => (
                    <tr key={acHead?._id}>
                      <th scope="row">{i++}</th>
                      <td>{acHead?.code}</td>
                      <td>{acHead?.name}</td>
                      <td>
                        {acHead?.maId
                          ? acHead?.maId?.name
                          : "Master Account Head"}
                      </td>
                      <td>{acHead?.maId ? acHead?.maId?.code : ""}</td>
                      <td>{acHead?.status}</td>
                      <td>
                        <Link to={`/accountheads/update/${acHead._id}`}>
                          <Icons.PencilAltOutline
                            className="icon-edit"
                            size={20}
                          ></Icons.PencilAltOutline>
                        </Link>
                        <Icons.TrashOutline
                          className="icon-trash"
                          size={20}
                          // onClick={() => deleteHandler(acHead._id)}
                        ></Icons.TrashOutline>
                      </td>
                    </tr>
                  ))
                ) : (
                  // <tr>
                  //   <td colSpan={6}>
                  //     {isLoading && <Skeleton count={100} />}
                  //     {/* {isFetching && <h2>...isFetching</h2>}
                  //     {error && <h2>Something went wrong</h2>} */}
                  //   </td>
                  // </tr>
                  <tr colSpan={9}>No AccountHead Found</tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHeads;
