import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import "./../Inventory.css";
import { Table, Button } from "react-bootstrap";
import { useInventoryMovementQuery } from "../../../services/inventoryApi";
import DatePicker from "react-datepicker";

import LoadingModal from "../../Common/Modal/LoadingModal";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { ArrowDown } from "heroicons-react";
import { FaPlay } from "react-icons/fa";
import ProcessMovement from "./parts/ProcessMovement";

function ProductMovement() {
  let i = 1;

  const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
  const sortIcon = <ArrowDown />;

  const paginationComponentOptions = {
    rowsPerPageText: "Show Number of Row",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All Rows",
  };
  // const [startDate, setStartDate] = useState(
  //   format(new Date("10-15-2023"), "MM-dd-yyyy")
  // );
  // const [endDate, setEndDate] = useState(
  //   format(new Date("10-15-2023"), "MM-dd-yyyy")
  // );

  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [inventory, setInventory] = useState([]);

  //   const { data, error, isLoading, isFetching, isSuccess, refetch } =
  //     useInventoryMovementQuery(
  //       {
  //         startDate,
  //         endDate,
  //       },
  //       {
  //         staleTime: 5000000, // 120 seconds
  //       }
  //     );

  //   console.log(startDate, endDate);
  //   console.log(error);
  //   console.log("data:", data);

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);
  const handleLoader = () => setLoader(true);

  //   useEffect(() => {
  //     refetch();
  //     // handleLoader();
  //     setInventory([]);
  //   }, [startDate, endDate]);

  //   useEffect(() => {
  //     handleLoaderClose();

  //     data?.data?.length > 0 && setInventory(data?.data);
  //     console.log("Time:", data?.time);
  //   }, [isSuccess, data]);

  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Inventory Movement"></Header>
            <div className="row mt-3">
              <div className="col-md-7">
                <form action="javasctipt:void(0)">
                  <div className="date-picker d-flex mb-2 align-items-center">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    {/* <button className="btn btn-dark d-flex align-items-center">
                      Generate <FaPlay />
                    </button> */}
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <span className="float-end">
                  {/* <p>
                    Result Show from{" "}
                    <b>
                      {data?.start &&
                        format(new Date(data?.start), "dd MMM yyyy")}
                    </b>{" "}
                    to{" "}
                    <b>
                      {data?.end && format(new Date(data?.end), "dd MMM yyyy")}
                    </b>
                  </p> */}
                  {/* <ExportNav
                        data={data}
                        title="Inventory"
                        Routes={InventoryRoutes}
                      /> */}
                </span>
              </div>
            </div>
            <div
              className="wrapper  p-3 justify-content-center  h-75  d-flex "
              style={{ height: 200 }}
            >
              <ProcessMovement start={startDate} end={endDate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductMovement;
