import React, { useState } from "react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import CsvImporterProduct from "../Common/CsvImporter/CsvImporterProduct";
import { useAddProductMutation } from "../../services/productApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import * as XLSX from 'xlsx';

const ImportProduct = () => {
  const [csvData, setCsvData] = useState([]);
  const [addProduct] = useAddProductMutation();

  const handleImportProduct = () => {
    const uniqueProducts = [];
    const duplicateProducts = [];

    csvData.forEach((item) => {
      addProduct(item)
        .unwrap() // Unwraps the promise for easier error handling
        .then((res) => {
          const check = apiUniqueErrHandle(res);
          if (!check.status) {
            duplicateProducts.push(item); // Add to duplicates if not unique
            for (const key in check.option) {
              notify(`${key}: ${check.option[key]} is not unique`, "error");
            }
          } else {
            notify(`Product ${item.name} Create Successful!`, "success");
            uniqueProducts.push(item); // Add to uniqueProducts if unique
          }
        })
        .catch((err) => {
          notify(`Product ${item.name} creation failed!`, "error");
        });
    });

    // Export duplicates to Excel if any are found
    if (duplicateProducts.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(duplicateProducts);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Duplicate Products");
      XLSX.writeFile(workbook, "Duplicate_Products.xlsx");
      notify("Duplicate products exported to Excel.", "info");
    }

    setCsvData([]); // Clear the csvData after processing
  };

  let i = 1;
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Import Product"></Header>
            <div className="row">
              <div className="col-md-4 offset-md-4">
                <div className="pt-5 pb-5">
                  <CsvImporterProduct
                    setCsvData={setCsvData}
                    handleImportButton={handleImportProduct}
                  ></CsvImporterProduct>
                </div>
              </div>
              <div className="col-md-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ean</th>
                      <th>name</th>
                      <th>article_code</th>
                      <th>category</th>
                      <th>vat</th>
                      <th>unit</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {csvData ? (
                      csvData.map((item) => (
                        <tr key={i++}>
                          <th>{i}</th>
                          <td>{item.ean}</td>
                          <td>{item.name}</td>
                          <td>{item.article_code}</td>
                          <td>{item?.category}</td>
                          <td>{item?.vat}</td>
                          <td>{item?.unit}</td>
                          <td>{item?.status}</td>
                        </tr>
                      ))
                    ) : (
                      <div className="spinner-border text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Toaster position="bottom-right" />
        </div>
      </div>
    </div>
  );
};

export default ImportProduct;
