import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import * as Icons from "heroicons-react";
import { useTpnQuery, useUpdateTpnMutation } from "../../../services/tpnApi";
import TpnDetailsPrint from "../../TPN/TpnDetailsPrint";

// import { ComponentToPrint } from './ComponentToPrint';

const TpnView = ({ show, handleClose, tpn }) => {
  const tpnView = useTpnQuery(tpn);
  const componentRef = useRef();

  const [updateTpn] = useUpdateTpnMutation();

  //   console.log(tpnView.data);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printRecipt = async () => {
    handlePrint();
    if (!tpnView?.data?.print) {
      const updatePrint = await updateTpn({
        _id: tpnView?.data?._id,
        print: true,
      });
      if (updatePrint) {
        console.log("print Orignal:", updatePrint);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>TPN Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {console.log(tpnView?.data)}
        <TpnDetailsPrint ref={componentRef} tpn={tpnView?.data} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button className="btn btn-dark float-end" onClick={printRecipt}>
          <Icons.PrinterOutline className="ms-3" size={18} /> Print{" "}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TpnView;
