import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BillerSelcet from "../CustomSelect/BillerSelect";
import { useSaleByBillerQuery } from "../../../services/saleApi";
import { format } from "date-fns";
import {
  selectBiller,
  selectPosData,
  resetPayment,
  selectVisa,
  selectMasterCard,
  selectBrac,
  selectDbbl,
  selectAmex,
  selectCity,
  selectMtbl,
  selectEbl,
  // selectUcb,
  selectBkash,
  selectRocket,
  selectNagad,
  selectUpay,
  selectOneThousand,
  selectFiveHundred,
  selectTwoHundred,
  selectOneHundred,
  selectFifty,
  selectTwenty,
  selectTen,
  selectFive,
  selectTwo,
  selectOne,
  selectCollectionCash,
  selectCollectionTotal,
  selectCashOut,
  selectOfficeBill,
  selectOpeningCash,
  selectCashInHand,
  selectReceivedCash,
  selectReceivedSum,
  selectCollectionSum,
  selectDifference,
  resetDayBook,
} from "../../../features/posCollectionSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddDayBookMutation,
  useUpdateDayBookMutation,
} from "../../../services/posCollectionApi";

const POSClosingModal = ({ onShow, handleClose }) => {
  const date = format(new Date(), "MM-dd-yyyy");
  const [biller, setBiller] = useState("");
  const [saleData, setSaleData] = useState();
  const { data, error, isSuccess, refetch } = useSaleByBillerQuery({
    startDate: date,
    endDate: date,
    biller: biller,
  });

  const dispatch = useDispatch();
  const posClosingData = useSelector((state) => state.posCollectionReducer);

  console.log("Sale data", data);

  const handleSelectBiller = async (value) => {
    dispatch(selectBiller(value?.option));
    setBiller(value?.option);
  };

  useEffect(() => {
    setSaleData([]);
    // setSaleData();
    dispatch(resetDayBook());
    dispatch(selectBiller(biller));

    refetch();
  }, [biller]);

  // collectionTotalRound
  useEffect(() => {
    const collactionTotal =
      parseFloat(posClosingData.collectionCash) +
      parseFloat(posClosingData.collectionVisa) +
      parseFloat(posClosingData.collectionMasterCard) +
      parseFloat(posClosingData.collectionBrac) +
      parseFloat(posClosingData.collectionDbbl) +
      parseFloat(posClosingData.collectionAmex) +
      parseFloat(posClosingData.collectionCity) +
      parseFloat(posClosingData.collectionMtbl) +
      parseFloat(posClosingData.collectionEbl) +
      // parseFloat(posClosingData.collectionUcb) +
      parseFloat(posClosingData.collectionBkash) +
      parseFloat(posClosingData.collectionRocket) +
      parseFloat(posClosingData.collectionNagad) +
      parseFloat(posClosingData.collectionUpay);

    dispatch(selectCollectionTotal(collactionTotal));
  }, [
    posClosingData.collectionCash,
    posClosingData.collectionVisa,
    posClosingData.collectionMasterCard,
    posClosingData.collectionBrac,
    posClosingData.collectionDbbl,
    posClosingData.collectionAmex,
    posClosingData.collectionCity,
    posClosingData.collectionMtbl,
    posClosingData.collectionEbl,
    // posClosingData.collectionUcb,
    posClosingData.collectionBkash,
    posClosingData.collectionRocket,
    posClosingData.collectionNagad,
    posClosingData.collectionUpay,
  ]);

  // cash in hand
  useEffect(() => {
    const collectionTotal =
      posClosingData.oneTotal +
      posClosingData.twoTotal +
      posClosingData.fiveTotal +
      posClosingData.tenTotal +
      posClosingData.twentyTotal +
      posClosingData.fiftyTotal +
      posClosingData.oneHundredTotal +
      posClosingData.twoHundredTotal +
      posClosingData.fiveHundredTotal +
      posClosingData.oneThousandTotal;

    dispatch(selectCashInHand(collectionTotal));
  }, [
    posClosingData.oneTotal,
    posClosingData.twoTotal,
    posClosingData.fiveTotal,
    posClosingData.tenTotal,
    posClosingData.twentyTotal,
    posClosingData.fiftyTotal,
    posClosingData.oneHundredTotal,
    posClosingData.twoHundredTotal,
    posClosingData.fiveHundredTotal,
    posClosingData.oneThousandTotal,
  ]);

  //Available Cash
  useEffect(() => {
    const availableCash =
      parseFloat(posClosingData.cashInHand) +
      parseFloat(posClosingData.cashOut) +
      parseFloat(posClosingData.officeBill);
    const totalReceivedCash =
      parseFloat(posClosingData.cashInHand) +
      parseFloat(posClosingData.openingCash) -
      parseFloat(posClosingData.cashOut) -
      parseFloat(posClosingData.officeBill);
    dispatch(selectCollectionCash(availableCash));
    dispatch(selectReceivedCash(totalReceivedCash));
  }, [
    posClosingData.cashInHand,
    posClosingData.cashOut,
    posClosingData.officeBill,
    posClosingData.openingCash,
  ]);

  //Diffrence
  useEffect(() => {
    const diff =
      parseFloat(posClosingData.collectionTotalRound) -
      parseFloat(posClosingData.totalRound);
    dispatch(selectDifference(diff));
  }, [posClosingData.collectionTotalRound, posClosingData.totalRound]);
  // Set system data
  useEffect(() => {
    // console.log(data);
    setSaleData(data);
    if (data) {
      dispatch(
        selectPosData({
          salesCash: (data?.sales?.cash - data?.sales?.changeAmount) | 0,
          salesVisa: data?.sales?.visa | 0,
          salesMasterCard: data?.sales?.masterCard | 0,
          salesBrac: data?.sales?.BRAC | 0,
          salesDbbl: data?.sales?.DBBL | 0,
          salesAmex: data?.sales?.AMEX | 0,
          salesCity: data?.sales?.CITY | 0,
          salesMtbl: data?.sales?.MTB | 0,
          salesEbl: data?.sales?.EBL | 0,
          // salesUcb: data?.sales?.UCB | 0,
          salesBkash: data?.sales?.bkash | 0,
          salesRocket: data?.sales?.rocket | 0,
          salesNagad: data?.sales?.nagad | 0,
          salesUpay: data?.sales?.upay | 0,
          salesPoint: data?.sales?.point | 0,
          salesTotalRound: data?.sales?.roundedGrossTotal | 0,
          dueCash:
            (data?.collection?.cash - data?.collection?.changeAmount) | 0,
          dueVisa: data?.collection?.visa | 0,
          dueMasterCard: data?.collection?.masterCard | 0,
          dueBrac: data?.collection?.BRAC | 0,
          dueDbbl: data?.collection?.DBBL | 0,
          dueAmex: data?.collection?.AMEX | 0,
          dueCity: data?.collection?.CITY | 0,
          dueMtbl: data?.collection?.MTB | 0,
          dueEbl: data?.collection?.EBL | 0,
          // dueUcb: data?.collection?.UCB | 0,
          dueBkash: data?.collection?.bkash | 0,
          dueRocket: data?.collection?.rocket | 0,
          dueNagad: data?.collection?.nagad | 0,
          dueUpay: data?.collection?.upay | 0,
          dueTotalRound: data?.collection?.dueAmount | 0,
          // Collection Total
        })
      );
    }
  }, [isSuccess, data]);

  const [createDayBook] = useAddDayBookMutation();
  const [updateDayBook] = useUpdateDayBookMutation();

  const handleSaveDayBook = async () => {
    // Submit DayBook Data
    try {
      const dayClosing = await createDayBook(posClosingData);
      console.log(dayClosing);
    } catch (err) {
      console.log(err);
    }
    console.log("posClosingData:", posClosingData);
  };

  console.log("biller", biller, data, saleData);

  return (
    <Modal
      size="xl"
      show={onShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <label className="me-2 text-bold">Select Biller: </label>
          <BillerSelcet
            handleOnchange={handleSelectBiller}
            billerSelect={biller}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-8">
            <b className="py-2">Sales OverView: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Reguler Collection</th>
                  <th scope="col">Dues Collection</th>
                  <th scope="col">Total Collection</th>
                  <th scope="col">Total Recieved</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Cash sales</th>
                  <td>
                    {(saleData?.sales?.cash - saleData?.sales?.changeAmount) |
                      0}
                  </td>
                  <td>
                    {(saleData?.collection?.cash -
                      saleData?.collection?.changeAmount) |
                      0}
                  </td>
                  <td>
                    {(saleData?.sales?.cash +
                      saleData?.collection?.cash -
                      saleData?.sales?.changeAmount) |
                      0}
                  </td>
                  <td className="m-0 p-0">{posClosingData.collectionCash}</td>
                </tr>
                <tr>
                  <th>Visa Card</th>
                  <td>{saleData?.sales?.visa | 0}</td>
                  <td>{saleData?.collection?.visa | 0}</td>
                  <td>
                    {(saleData?.sales?.visa + saleData?.collection?.visa) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectVisa(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Master Card</th>
                  <td>{saleData?.sales?.masterCarrd | 0}</td>
                  <td>{saleData?.collection?.masterCarrd | 0}</td>
                  <td>
                    {(saleData?.sales?.masterCarrd +
                      saleData?.collection?.masterCarrd) |
                      0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectMasterCard(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>BRAC</th>
                  <td>{saleData?.sales?.BRAC | 0}</td>
                  <td>{saleData?.collection?.BRAC | 0}</td>
                  <td>
                    {(saleData?.sales?.BRAC + saleData?.collection?.BRAC) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectBrac(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>DBBL</th>
                  <td>{saleData?.sales?.DBBL | 0}</td>
                  <td>{saleData?.collection?.DBBL | 0}</td>
                  <td>
                    {(saleData?.sales?.DBBL + saleData?.collection?.DBBL) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectDbbl(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Amex</th>
                  <td>{saleData?.sales?.AMEX | 0}</td>
                  <td>{saleData?.collection?.AMEX | 0}</td>
                  <td>
                    {(saleData?.sales?.AMEX + saleData?.collection?.AMEX) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectAmex(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>CITY</th>
                  <td>{saleData?.sales?.CITY | 0}</td>
                  <td>{saleData?.collection?.CITY | 0}</td>
                  <td>
                    {(saleData?.sales?.CITY + saleData?.collection?.CITY) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectCity(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>MTBL</th>
                  <td>{saleData?.sales?.MTB | 0}</td>
                  <td>{saleData?.collection?.MTB | 0}</td>
                  <td>
                    {(saleData?.sales?.MTB + saleData?.collection?.MTB) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectMtbl(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>EBL</th>
                  <td>{saleData?.sales?.EBL | 0}</td>
                  <td>{saleData?.collection?.EBL | 0}</td>
                  <td>
                    {(saleData?.sales?.EBL + saleData?.collection?.EBL) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectEbl(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th>UCB</th>
                  <td>{saleData?.sales?.UCB | 0}</td>
                  <td>{saleData?.collection?.UCB | 0}</td>
                  <td>
                    {(saleData?.sales?.UCB + saleData?.collection?.UCB) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectUcb(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr> */}
                <tr>
                  <th>bkash</th>
                  <td>{saleData?.sales?.bkash | 0}</td>
                  <td>{saleData?.collection?.bkash | 0}</td>
                  <td>
                    {(saleData?.sales?.bkash + saleData?.collection?.bkash) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectBkash(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Rocket</th>
                  <td>{saleData?.sales?.rocket | 0}</td>
                  <td>{saleData?.collection?.rocket | 0}</td>
                  <td>
                    {(saleData?.sales?.rocket + saleData?.collection?.rocket) |
                      0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectRocket(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Nagad</th>
                  <td>{saleData?.sales?.nagad | 0}</td>
                  <td>{saleData?.collection?.nagad | 0}</td>
                  <td>
                    {(saleData?.sales?.nagad + saleData?.collection?.nagad) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectNagad(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Upay</th>
                  <td>{saleData?.sales?.upay | 0}</td>
                  <td>{saleData?.collection?.upay | 0}</td>
                  <td>
                    {(saleData?.sales?.upay + saleData?.collection?.upay) | 0}
                  </td>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectUpay(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-end">Total:</th>
                  <td>{saleData?.sales?.roundedGrossTotal | 0}</td>
                  <td>{saleData?.collection?.dueAmount | 0}</td>
                  <td>
                    {(saleData?.sales?.roundedGrossTotal +
                      saleData?.collection?.dueAmount -
                      saleData?.sales?.roundUpAmount) |
                      0}
                  </td>
                  <td>{posClosingData.collectionTotalRound | 0}</td>
                </tr>
                <tr>
                  <th colSpan="4">
                    <div className="d-flex justify-content-between">
                      <div>Todays Credit Sales: {saleData?.sales?.dueSale}</div>
                      <div>Loyalty Point: {saleData?.sales?.point}</div>
                      <div>Difference:</div>
                    </div>
                  </th>
                  {/* <td>{saleData?.sales?.dueSale | 0}</td>
                  <th>Loyalty Point</th>
                  <td>{saleData?.sales?.point | 0}</td> */}
                  <td>{posClosingData.difference}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-4">
            <b className="py-2">Bank / Card: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Visa</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Master Card</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>BRAC</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>DBBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Amex</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>CITY</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>MTBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>EBL</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>UCB</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>bkash</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Rocket</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Nagad</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Upay</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Loyalty/Cupon/Others</th>
                  <td></td>
                </tr>
                <tr>
                  <th className="text-end">Total Sales: </th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="col-4">
            <b className="py-2">Cash Denomination: </b>
            <table className="table table-responsive table-bordered table-striped">
              <thead>
                <tr>
                  <th className="text-center">Particulars</th>
                  <th>Unit</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-center">1000</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOneThousand(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.oneThousandTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">500</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectFiveHundred(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.fiveHundredTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">200</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectTwoHundred(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.twoHundredTotal}</td>
                  <td></td>
                </tr>
                <tr>
                  <th className="text-center">100</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOneHundred(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.oneHundredTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">50</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectFifty(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.fiftyTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">20</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTwenty(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.twentyTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">10</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTen(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.tenTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">5</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectFive(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.fiveTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">2</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectTwo(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.twoTotal}</td>
                </tr>
                <tr>
                  <th className="text-center">1</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectOne(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                  <td>{posClosingData.oneTotal}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Cash in Hand</th>
                  <td>{posClosingData.cashInHand}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Cash out</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) => dispatch(selectCashOut(e.target.value))}
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>Office Bill</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOfficeBill(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>Opening Cash</th>
                  <td className="m-0 p-0">
                    <input
                      type="number"
                      onChange={(e) =>
                        dispatch(selectOpeningCash(e.target.value))
                      }
                      className="form-control form-control-sm p-0 px-2"
                    />
                  </td>
                </tr>

                {/* <tr>
                  <th colSpan={2}>Due Collection</th>
                  <td></td>
                </tr> */}
                <tr>
                  <th colSpan={2}>Available Cash</th>
                  <td>{posClosingData.totalReceivedCash}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="dark" onClick={handleSaveDayBook}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default POSClosingModal;
