import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import * as Icons from "heroicons-react";
import Barcode from "react-barcode";
import toWord from "../../Utility/toWord";
import Logo from "../../../logo.png";
import { format } from "date-fns";

const SupplierLedgerPrint = ({ show, handleClose, supplier }) => {
  // console.log("Supplier from Ledger Print", supplier);

  //user Data
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  // console.log(loggedInUser)

  let balance = 0;
  let debit = 0;
  let credit = 0;

  const componentRef = useRef();
  const [recordsList, setRecordsList] = useState([]);
  let i = 1;
  // console.log(recordsList);

  useEffect(() => {
    if (recordsList?.records?.length > 0) {
      const records = recordsList?.records ? recordsList?.records : [];
      if (records.length > 0) {
        setRecordsList(records?.slice().sort((a, b) => a.order - b.order));
      }
    }
  }, [recordsList]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(supplier);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>Supplier Ledger </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={componentRef}>
        <div className="px-4 ms-5 ">
          <div className="row pt-4 pb-1 mb-1  border-bottom">
            <div className="col-7">
              <img src={Logo} height="40" alt="not Image" />
            </div>

            <div className="col-5">
              <p className="p-0 m-0 text-end">
                <b>Hotline: </b> 01332553955
              </p>
              <p className="p-0 m-0 text-end">
                <small>H#6, R#27, Sector 7, Uttara, Dhaka - 1230</small>{" "}
              </p>
            </div>
          </div>
          <div className="1h-1 ">
            <h4 className="text-center text-uppercase fs-5  fw-bold lh-1 m-0 p-0">
              {supplier?._id && `Suppliers Ledger Statement `}
            </h4>
            <p className="1h-1 text-center small mb-1 p-0 ">
              <small className="1h-1  ">
                {supplier?._id &&
                  supplier?.records?.length > 0 &&
                  `For the period from ${format(
                    new Date(supplier?.records[0]?.createdAt),
                    "MM-dd-yyyy"
                  )} to ${format(
                    new Date(
                      supplier?.records[
                        supplier?.records?.length - 1
                      ]?.createdAt
                    ),
                    "MM-dd-yyyy"
                  )}`}
              </small>
            </p>
          </div>
          <div className="row small 1h-1 ">
            <div className="col">
              <small>
                <b>Company: </b> <br />
                {supplier?.company}
              </small>
            </div>
            <div className="col">
              <small>
                <b>Name: </b> <br />
                {supplier?.name}
              </small>
            </div>
            <div className="col">
              <small>
                <b>Email: </b> <br />
                {supplier?.email}
              </small>
            </div>
            <div className="col">
              <small>
                <b>Phone: </b> <br />
                {supplier?.phone}
              </small>
            </div>
            <div className="col">
              <small>
                <b>Adress: </b>
                <br />
                {supplier?.address}
              </small>
            </div>
          </div>

          <div className="row pt-1 pb-3 small">
            <div className="col-12">
              <table className="table table-striped table-bordered border-dark small lh-sm ">
                <thead>
                  <tr>
                    <th scope="col" className="px-1">
                      #
                    </th>
                    <th scope="col" className="px-1">
                      Document ID
                    </th>
                    <th scope="col" className="px-1">
                      Date
                    </th>
                    <th scope="col" className="px-1">
                      Type
                    </th>
                    <th scope="col" className="px-1">
                      Debit
                    </th>
                    <th scope="col" className="px-1">
                      Credit
                    </th>
                    <th scope="col" className="px-1">
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {}
                  {supplier?.records?.length > 0 ? (
                    supplier?.records.map((record) => {
                      if (record?.type === "GRN") {
                        debit = (
                          parseFloat(debit) + parseFloat(record?.amount)
                        ).toFixed(2);
                      } else {
                        credit = (
                          parseFloat(credit) + parseFloat(record?.amount)
                        ).toFixed(2);
                      }
                      return (
                        <tr key={record?._id}>
                          {/* {setCredit(onCredit + record?.amount)} */}
                          <th className="px-1">{i++}</th>
                          <td className="px-1">{record?.doc_id}</td>
                          <td className="px-1">
                            {format(new Date(record?.createdAt), "MM-dd-yyyy")}
                          </td>
                          <td className="px-1">{record?.type}</td>

                          <td className="text-start px-1">
                            {record?.type === "Accounts" ||
                            record?.type === "RTV"
                              ? parseFloat(record?.amount)?.toFixed(2)
                              : ""}
                          </td>
                          <td className="text-start px-1">
                            {record?.type === "GRN" &&
                              parseFloat(record?.amount)?.toFixed(2)}
                          </td>

                          <td className="text-start px-1">
                            {record?.type === "GRN"
                              ? (balance = (
                                  parseFloat(balance) -
                                  parseFloat(record?.amount)
                                ).toFixed(2))
                              : (balance = (
                                  parseFloat(balance) +
                                  parseFloat(record?.amount)
                                ).toFixed(2))}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Records found</td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <th colSpan={3}>
                      <p className="text-end pe-4 m-0">
                        <b>Total: </b>
                      </p>
                    </th>
                    <th className="ps-1">{debit}</th>
                    <th className="ps-1">{credit}</th>
                    <th className="ps-1">{balance}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* 3 signature field  */}
          <div class="row small mt-5 justify-content-between pt-5 ">
            <div class="col-3 text-center">
              <p class="border-top border-dark pt-1">
                <b>
                  Generate By <br /> {loggedInUser?.name}
                </b>{" "}
                <br />
                As on: <b> {format(new Date(), "MM-dd-yyyy")} </b>
              </p>
            </div>
            <div class="col-3 text-center">
              <p class="border-top border-dark pt-1">
                <b>Verified By</b>
              </p>
            </div>
            <div class="col-3 text-center">
              <p class="border-top border-dark pt-1">
                <b>Authorize By</b> <br />
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button className="btn btn-dark float-end" onClick={handlePrint}>
          <Icons.PrinterOutline className="ms-3" size={18} /> Print{" "}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupplierLedgerPrint;
