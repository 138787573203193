import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

const initialState = {
  invoices: [],
  customerId: "",
  billerId: user?.id,
  authorizedUser: "",
  status: "complete",
  paidAmount: {
    cash: {
      phone: "",
      amount: 0,
    },
    mfs: {
      name: "bkash",
      type: "",
      phone: "",
      amount: 0,
    },
    card: {
      name: "MTB",
      type: "",
      cardNo: "",
      amount: 0,
    },
  },
  dueAmount: 0,
  changeAmount: 0,
};
export const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {
    selectInvoice: (state, action) => {
      return {
        ...state,
        invoices: action.payload,
      };
    },
    selectCustomerId: (state, action) => {
      return {
        ...state,
        customerId: action.payload,
      };
    },
    selectAdmin: (state, action) => {
      return {
        ...state,
        authorizedUser: action.payload,
      };
    },
    saleMfsAmount: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          mfs: { ...state.paidAmount.mfs, amount: action.payload },
        },
      };
    },
    saleCardAmount: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          card: { ...state.paidAmount.card, amount: action.payload },
        },
      };
    },
    saleMfsName: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          mfs: { ...state.paidAmount.name, amount: action.payload },
        },
      };
    },
    saleCardName: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          card: { ...state.paidAmount.card, name: action.payload },
        },
      };
    },
    saleCash: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          cash: { ...state.paidAmount.cash, amount: action.payload },
        },
      };
    },
    selectDueAmount: (state, action) => {
      return {
        ...state,
        dueAmount: action.payload,
      };
    },
    selectChangeAmount: (state, action) => {
      return {
        ...state,
        changeAmount: action.payload,
      };
    },
    resetPayment: () => initialState,
  },
});

export const {
  selectDueAmount,
  selectChangeAmount,
  saleMfsName,
  saleCardName,
  saleCash,
  saleMfsAmount,
  selectInvoice,
  saleCardAmount,
  selectCustomerId,
  selectAdmin,
  resetPayment,
} = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
